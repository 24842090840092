import CryptoJS from 'crypto-js'

// eslint-disable-next-line @typescript-eslint/ban-types
function debounce<T extends Function>(cb: T, wait = 20) {
    let h: any = 0
    const callable = (...args: any) => {
        clearTimeout(h)
        h = setTimeout(() => cb(...args), wait)
    }
    return <T>(<any>callable)
}

const formatCurrency = (amount: number, currency: string = 'CAD'): string => {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency,
    })
}

const fromBinary = (encoded: string): string => {
    const binary = atob(encoded)
    const bytes = new Uint8Array(binary.length)
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return String.fromCharCode(...new Uint16Array(bytes.buffer))
}

const toMD5 = (str: string): string => {
    return CryptoJS.MD5(str).toString(CryptoJS.enc.Hex)
}

export const utilsService = {
    debounce,
    formatCurrency,
    fromBinary,
    toMD5,
}
