import { createContext, useContext } from 'react'

import { useAuthContext } from '../../../Authentication/hooks/useAuthContext'
import { DeveloperHookState, useDeveloper } from './_useDeveloper'

interface ContextState {
    developer: DeveloperHookState
}

const DeveloperContext = createContext<ContextState>(null!)

// Provider
export function DeveloperContextProvider({ children }: any) {
    const { user } = useAuthContext().user
    const developerHookState = useDeveloper(user)

    return (
        <DeveloperContext.Provider
            value={{
                developer: developerHookState,
            }}
        >
            {children}
        </DeveloperContext.Provider>
    )
}

// Consumer
export function useDeveloperContext() {
    return useContext(DeveloperContext)
}
