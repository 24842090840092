import { TrashIcon } from '@heroicons/react/24/solid'

import { DeleteVisit } from '../../../../UI/DeleteVisit'
import { IconButton } from '../../../../UI/IconButton'
import { Loader } from '../../../../UI/Loader'
import { ButtonLabel, ButtonWrapper } from '../styles'

interface Props {
    visitId: string
}

export const DeleteButton = ({ visitId }: Props) => {
    return (
        <ButtonWrapper>
            <DeleteVisit
                visitId={visitId}
                childNode={(isLoading) => (
                    <IconButton
                        iconNode={
                            isLoading ? (
                                <Loader className="h-5 w-5" />
                            ) : (
                                <TrashIcon className="h-5 w-5 text-gray-500" />
                            )
                        }
                        onClick={() => {}}
                    />
                )}
            />
            <ButtonLabel>Delete</ButtonLabel>
        </ButtonWrapper>
    )
}
