// Application colours
const Colors = {
    TEXT: '#111827',
    TEXT_SECONDARY: '#757575',
    HINT: '#9e9e9e',
    BORDER_COLOR: '#e8e8e8',
    PRIMARY: '#406CCF',
    RED: '#c7474a',
    GREEN: '#82c64e',
    YELLOW: '#ffc836',
    ORANGE: '#ff9c2e',
    NAV: '#1e2837',
}

export default Colors
