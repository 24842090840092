import { Link } from 'react-router-dom'

import { useRoute } from '../../../hooks/useRoute'
import { Card } from '../Card/Card'
import { TitleWithLogo } from '../Card/TitleWithLogo'
import { LayoutStyles } from '../styles'
import { Form } from './Form'

export const SignUp = () => {
    const { signInRoute } = useRoute()

    return (
        <LayoutStyles>
            <Card
                titleNode={<TitleWithLogo titleNode="Sign up" />}
                subTitleNode={
                    <p>
                        <span>Already have an account? </span>
                        <Link
                            className="leading-6 text-primary hover:text-primary"
                            to={signInRoute}
                        >
                            Sign In
                        </Link>
                    </p>
                }
                bodyNode={<Form />}
            />
        </LayoutStyles>
    )
}
