import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import styled from 'styled-components'

import Colors from '../../../colors'
import { MOMENT_DATETIME_SECONDS_FORMAT } from '../../../constants'
import { Invoice } from '../../../services/models/Subscription.model'
import { utilsService } from '../../../services/utils.service'
import { useBillingContext } from '../../Billing/hooks/useBillingContext'
import { IconButton } from '../../UI/IconButton'
import { SectionWrapper } from '../styles'

const Styles = styled.div`
    .chip {
        display: inline-block;
        padding: 4px 6px;
        border-radius: 5px;

        &.paid {
            color: ${Colors.GREEN};
            background-color: ${Colors.GREEN}1C;
        }
    }

    table {
        white-space: nowrap;
    }
`

export const Invoices = () => {
    const { isLoading, invoices } = useBillingContext().invoices

    if (isLoading) {
        return (
            <SectionWrapper title="Invoices">
                <div>Loading...</div>
            </SectionWrapper>
        )
    }

    if (!invoices?.length) {
        return (
            <SectionWrapper title="Invoices">
                <div className="text-gray-500">No invoices exist</div>
            </SectionWrapper>
        )
    }

    return (
        <SectionWrapper title="Invoices">
            <Styles className="overflow-x-auto">
                <div className="inline-block min-w-full">
                    <table className="table-auto min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th className="p-2" align="left">
                                    &nbsp;
                                </th>
                                <th className="p-2" align="left">
                                    Amount
                                </th>
                                <th className="p-2" align="left">
                                    Invoice ID
                                </th>
                                <th className="p-2" align="left">
                                    Date
                                </th>
                                <th className="p-2" align="left">
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {invoices.map((invoice: Invoice) => (
                                <tr key={invoice.id}>
                                    <td className="p-2">
                                        <div
                                            className={`chip text-sm font-medium capitalize ${invoice.status}`}
                                        >
                                            {invoice.status}
                                        </div>
                                    </td>
                                    <td className="p-2">
                                        {utilsService.formatCurrency(
                                            invoice.total,
                                            invoice.currency
                                        )}
                                    </td>
                                    <td className="p-2">{invoice.id}</td>
                                    <td className="p-2">
                                        {moment(invoice.createdAt).format(
                                            MOMENT_DATETIME_SECONDS_FORMAT
                                        )}
                                    </td>
                                    <td className="p-2">
                                        <IconButton
                                            iconNode={
                                                <ArrowDownTrayIcon className="h-5 w-5" />
                                            }
                                            onClick={() =>
                                                window.open(
                                                    invoice.url,
                                                    '_blank'
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Styles>
        </SectionWrapper>
    )
}
