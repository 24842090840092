import { useMemo } from 'react'
import styled from 'styled-components'

import Colors from '../../colors'
import { User } from '../../services/models/User.model'

interface Props {
    user: User
}

const Styles = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    img {
        width: 36px;
        height: 36px;
        padding: 6px;
        border: 1px solid ${Colors.BORDER_COLOR};
        border-radius: 6px;
    }
`

export const GoogleUser = ({ user }: Props) => {
    const isGoogleUser = useMemo(
        () => (user ? User.isGoogleUser(user) : false),
        [user]
    )

    if (!isGoogleUser) {
        return null
    }

    return (
        <Styles>
            <img src="/assets/images/google.svg" alt="Google" />
            <div>
                <div className="font-medium">Google authentication</div>
                <div className="text-gray-500">{user.email}</div>
            </div>
        </Styles>
    )
}
