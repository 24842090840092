import 'react-toastify/dist/ReactToastify.css'

import { Slide, ToastContainer } from 'react-toastify'

export const Toast = () => (
    <ToastContainer
        position="bottom-right"
        limit={3}
        hideProgressBar
        transition={Slide}
    />
)
