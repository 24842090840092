import { InformationCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import { useRoute } from '../../hooks/useRoute'
import { Plan } from '../../services/models/Subscription.model'
import { useBillingContext } from '../Billing/hooks/useBillingContext'
import { Button } from './Button'

interface Props {
    className?: string
}

const Styles = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        min-height: unset;
        padding: 6px 8px;
    }
`

export const VisitsUsageBanner = ({ className }: Props) => {
    const { navBilling } = useRoute()
    const { currentPlan, isLoading } = useBillingContext().subscription
    const { billingUsages } = useBillingContext().billingUsages

    const isFreePlan = useMemo(
        () => (currentPlan ? Plan.isFreePlan(currentPlan) : false),
        [currentPlan]
    )

    const usageRemaining = useMemo((): number => {
        if (!isFreePlan) {
            return 0
        } else if (billingUsages) {
            let amount = billingUsages.maxVisits - billingUsages.visitsCreated
            if (amount < 0) {
                amount = 0
            }
            return amount
        }
        return 0
    }, [isFreePlan, billingUsages])

    if (isLoading || !isFreePlan || !billingUsages) {
        return null
    }

    return (
        <Styles
            className={classNames(
                'bg-primary-50 text-primary border-b',
                className
            )}
        >
            <InformationCircleIcon className="h-6 w-6 mr-2" />
            <div className="font-semibold mr-1">
                {usageRemaining} Free Visits
            </div>
            <div>Remaining</div>
            <Button className="ml-4" label="Upgrade" onClick={navBilling} />
        </Styles>
    )
}
