import { useEffect, useState } from 'react'

import {
    ConfirmationModal,
    ConfirmationModalProps,
} from './components/UI/ConfirmationModal'
import {
    MicrophonesModal,
    MicrophonesModalProps,
} from './components/UI/MicrophonesModal'
import { Toast } from './components/UI/Toast'
import { eventBus, EventBusData } from './services/event-bus/eventBus.service'
import {
    OpenConfirmationModal,
    OpenMicrophonesModal,
} from './services/event-bus/events'

export const Portal = () => {
    const [confirmationModalProps, setConfirmationModalProps] =
        useState<ConfirmationModalProps | null>(null)
    const [microphonesModalProps, setMicrophonesModalProps] =
        useState<MicrophonesModalProps | null>(null)

    useEffect(() => {
        const subscription = eventBus
            .getObservable()
            .subscribe((event: EventBusData) => {
                switch (event.action) {
                    case OpenConfirmationModal.action: {
                        const payload = event.payload as ConfirmationModalProps
                        setConfirmationModalProps({
                            ...payload,
                            isOpen: true,
                            onClose: () => {
                                setConfirmationModalProps(null)
                            },
                        })
                        break
                    }

                    case OpenMicrophonesModal.action: {
                        const payload = event.payload as MicrophonesModalProps
                        setMicrophonesModalProps({
                            ...payload,
                            isOpen: true,
                            onClose: () => {
                                setMicrophonesModalProps(null)
                            },
                        })
                        break
                    }
                }
            })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <>
            <Toast />

            {confirmationModalProps && (
                <ConfirmationModal {...confirmationModalProps} />
            )}
            {microphonesModalProps && (
                <MicrophonesModal {...microphonesModalProps} />
            )}
        </>
    )
}
