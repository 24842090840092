import { DividerLine } from '../../../UI/DividerLine'
import { SectionWrapper } from '../../styles'
import { UpdatePasswordForm } from './Form'

export const UpdatePassword = () => {
    return (
        <>
            <DividerLine className="my-8" />
            <SectionWrapper title="Update password">
                <UpdatePasswordForm />
            </SectionWrapper>
        </>
    )
}
