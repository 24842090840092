import { Card } from '../Card/Card'
import { LayoutStyles } from '../styles'
import { Form } from './Form'

export const ForgotPassword = () => {
    return (
        <LayoutStyles>
            <Card
                titleNode="Forgot Password"
                subTitleNode="Enter the email address associated with your account and we'll send you a link to reset your password"
                bodyNode={<Form />}
            />
        </LayoutStyles>
    )
}
