import { CurrentPlan } from './CurrentPlan'
import { Invoices } from './Invoices'

export const Billing = () => {
    return (
        <>
            <CurrentPlan />
            <Invoices />
        </>
    )
}
