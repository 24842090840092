import classNames from 'classnames'
import styled from 'styled-components'

interface Props {
    className?: string
    progress: number
}

const Styles = styled.div`
    overflow: hidden;
`

export const ProgressBar = ({ className, progress }: Props) => {
    return (
        <Styles
            className={classNames('h-2 rounded-md bg-primary-50', className)}
        >
            <div
                className="h-full bar bg-primary"
                style={{ width: `${progress}%` }}
            ></div>
        </Styles>
    )
}
