import { UserIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import styled from 'styled-components'

import { useAuthContext } from '../../Authentication/hooks/useAuthContext'

interface Props {
    className?: string
}

const Styles = styled.img``

export const Avatar = ({ className }: Props) => {
    const { user } = useAuthContext().user

    if (!user?.profilePictureUrl) {
        return (
            <UserIcon
                className={classNames(
                    className,
                    'h-10 w-10 text-gray-200 p-1.5 bg-gray-700 rounded-full'
                )}
                aria-hidden="true"
            />
        )
    }

    return (
        <Styles
            className={classNames(
                className,
                'h-10 w-10 rounded-full bg-gray-700'
            )}
            src={user.profilePictureUrl}
            alt="User Profile"
        />
    )
}
