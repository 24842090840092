import React from 'react'

import { Card } from '../Card/Card'
import { LayoutStyles } from '../styles'
import { Form } from './Form'

export const ResetPassword = () => {
    return (
        <LayoutStyles>
            <Card
                titleNode="Reset Password"
                subTitleNode="Enter a new password below to reset your password"
                bodyNode={<Form />}
            />
        </LayoutStyles>
    )
}
