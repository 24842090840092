import { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { useRoute } from '../../../hooks/useRoute'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { Button } from '../../UI/Button'
import { Input } from '../../UI/Input'
import { useForm } from './useForm'

export const Form = () => {
    const { signInRoute } = useRoute()
    const { form, isLoading, updateField, validateField, forgotPassword } =
        useForm()

    const onSubmit = useCallback(() => forgotPassword(), [forgotPassword])
    useSubmitOnEnter(onSubmit)

    return (
        <>
            <Input
                name="email"
                initialValue={form.email.value as string}
                onChange={(value: string) => updateField('email', value)}
                onBlur={() => validateField('email')}
                labelNode="Email"
                error={form.email.error}
            />

            <Button
                className="w-full mt-5"
                label="Submit"
                onClick={onSubmit}
                isLoading={isLoading}
            />

            <p className="mt-5 text-center text-sm text-gray-500">
                <span>Remembered your password? </span>
                <Link
                    className="leading-6 text-primary hover:text-primary"
                    to={signInRoute}
                >
                    Sign In
                </Link>
            </p>
        </>
    )
}
