import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useHttp } from '../../hooks/useHttp'
import { cannyio } from '../../lib/cannyio'
import { servicesService } from '../../services/http/services.service'
import { Loader } from './Loader'

const Styles = styled.div``

const boardToken = '1b09fdd9-eb58-b317-14fe-858cf079c1a1'

// Initialize Canny.io
cannyio.init()

export const CannyioEmbed = () => {
    const { isLoading, getSsoTokenReq } = useHttpReq()
    const hasFetchedRef = useRef<boolean>(false)
    const hasInitRef = useRef<boolean>(false)
    const [ssoToken, setSsoToken] = useState<string | null>(null)

    useEffect(() => {
        if (hasFetchedRef.current) {
            return
        }
        getSsoTokenReq().then(setSsoToken).catch(console.error)
        hasFetchedRef.current = true
    }, [getSsoTokenReq])

    useEffect(() => {
        if (!ssoToken || hasInitRef.current) {
            return
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Canny('render', {
            boardToken,
            basePath: '/settings/request-feature',
            ssoToken,
            theme: 'light',
        })
        hasInitRef.current = true
    }, [ssoToken])

    if (isLoading) {
        return (
            <Styles>
                <Loader className="m-3" />
            </Styles>
        )
    }

    return (
        <Styles>
            <div data-canny />
        </Styles>
    )
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getSsoTokenReq = useCallback(
        () => sendRequest(servicesService.getCannyIoSsoToken),
        [sendRequest]
    )

    return {
        isLoading,
        getSsoTokenReq,
    }
}
