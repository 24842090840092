import { useCallback, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'

import { useHttp } from '../../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../../lib/field'
import { userService } from '../../../../services/http/user.service'
import { validationService } from '../../../../services/validation.service'
import { useAuthContext } from '../../../Authentication/hooks/useAuthContext'

class FormFields {
    [key: string]: TextField | undefined
    email!: TextField
    verificationCode!: TextField
}

type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    email: initialTextField,
    verificationCode: initialTextField,
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: { fieldKey: FormFieldsKeys; payload: Field }
): FormFields => {
    return {
        ...form,
        [fieldKey]: payload as TextField,
    }
}

export interface FormHookState {
    form: FormFields
    isLoading: boolean
    isVerificationStage: boolean
    emailToVerify?: string
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    updateEmail(): void
    resendCode(): void
    cancelUpdateEmail(): void
    verifyEmail(): void
}

export const useForm = (): FormHookState => {
    const [form, dispatch] = useReducer(reducer, initialFormFields)
    const { updateEmailReq, cancelUpdateEmailReq, verifyEmailReq, isLoading } =
        useHttpReq()
    const [isVerificationStage, setIsVerificationStage] = useState(false)
    const { user, setUser } = useAuthContext().user

    // Determine if the user is in the verification stage
    useEffect(() => {
        if (!user) return
        // Check if emailToVerify is defined and different from email
        const inVerificationStage =
            user.emailToVerify && user.emailToVerify !== user.email
        setIsVerificationStage(!!inVerificationStage)
    }, [user])

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            const field = form[fieldKey]
            if (!field) return

            dispatch({
                fieldKey,
                payload: {
                    value: field.value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const field = form[fieldKey]
            if (!field || field.value === undefined) {
                return false
            }

            const value = field.value
            if (!value) {
                updateFieldError(fieldKey, 'This field is required')
                return false
            }

            if (fieldKey === 'email' && !validationService.isEmail(value)) {
                updateFieldError(fieldKey, 'Invalid email format')
                return false
            }

            if (fieldKey === 'email') {
                if (form.email.value === user?.email) {
                    updateFieldError(
                        fieldKey,
                        'This email is already associated with your account.'
                    )
                    return false
                }
            }

            if (fieldKey === 'verificationCode' && value.length !== 6) {
                // Assuming code is 6 characters
                updateFieldError(
                    fieldKey,
                    'Verification code should be 6 characters long'
                )
                return false
            }

            return true
        },
        [form, updateFieldError, user]
    )

    const updateEmail = useCallback(async () => {
        if (!validateField('email')) {
            return
        }

        try {
            await updateEmailReq(form.email.value as string)
            // Update the user object with emailToVerify
            if (user) {
                setUser({
                    ...user,
                    emailToVerify: form.email.value?.toString(),
                })
            }

            toast.success(
                'Email updated successfully. Please verify your new email.'
            )
        } catch (error: any) {
            const errorMessage =
                error.response?.data?.message || 'Failed to update email'
            updateFieldError('email', errorMessage)
        }
    }, [
        validateField,
        form.email.value,
        updateFieldError,
        updateEmailReq,
        user,
        setUser,
    ])

    const cancelUpdateEmail = useCallback(async () => {
        try {
            await cancelUpdateEmailReq()
            // Update the user object with emailToVerify
            if (user) {
                setUser({
                    ...user,
                    emailToVerify: undefined,
                })
            }
            toast.success('Verification email sent successfully.')
        } catch (error: any) {
            const errorMessage =
                error.response?.data?.message || 'Failed to cancel email update'
            updateFieldError('verificationCode', errorMessage)
        }
    }, [cancelUpdateEmailReq, updateFieldError, user, setUser])

    const resendCode = useCallback(async () => {
        try {
            await updateEmailReq(user?.emailToVerify as string)
            toast.success('Verification code sent resent successfully.')
        } catch (error: any) {
            const errorMessage =
                error.response?.data?.message || 'Failed to resend code'
            updateFieldError('verificationCode', errorMessage)
        }
    }, [updateFieldError, updateEmailReq, user])

    const verifyEmail = useCallback(async () => {
        if (!validateField('verificationCode')) {
            return
        }

        try {
            await verifyEmailReq(form.verificationCode.value as string)
            // Update the user object with emailToVerify
            if (user && user.emailToVerify) {
                setUser({
                    ...user,
                    email: user.emailToVerify,
                    emailToVerify: undefined,
                })
            }
            toast.success('Email verified successfully.')
        } catch (error: any) {
            const errorMessage =
                error.response?.data?.message || 'Failed to verify email'
            updateFieldError('verificationCode', errorMessage)
        }
    }, [
        validateField,
        form.verificationCode.value,
        updateFieldError,
        verifyEmailReq,
        user,
        setUser,
    ])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        updateEmail,
        cancelUpdateEmail,
        resendCode,
        verifyEmail,
        isVerificationStage,
        emailToVerify: user?.emailToVerify,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const updateEmailReq = useCallback(
        (newEmail: string): Promise<void> =>
            sendRequest(userService.updateEmail.bind(null, newEmail)),
        [sendRequest]
    )

    const verifyEmailReq = useCallback(
        (code: string): Promise<void> =>
            sendRequest(userService.verifyEmail.bind(null, code)),
        [sendRequest]
    )

    const cancelUpdateEmailReq = useCallback(
        (): Promise<void> =>
            sendRequest(userService.cancelUpdateEmail.bind(null)),
        [sendRequest]
    )

    return {
        isLoading,
        updateEmailReq,
        verifyEmailReq,
        cancelUpdateEmailReq,
    }
}
