import { useCallback, useEffect, useReducer } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../lib/field'
import { storage } from '../../../lib/storage'
import { authService } from '../../../services/http/auth.service'
import { AppPlanId } from '../../../services/models/Subscription.model'
import { User } from '../../../services/models/User.model'
import { validationService } from '../../../services/validation.service'

class FormFields {
    email!: TextField
    password!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    email: initialTextField,
    password: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    switch (fieldKey) {
        case 'email':
        case 'password': {
            return {
                ...form,
                [fieldKey]: payload as TextField,
            }
        }

        default: {
            return form
        }
    }
}

interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    signIn(successFuncRefs: any): void
}

export const useForm = (email?: string): FormHookState => {
    const { signInReq, isLoading } = useHttpReq()
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'email': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (!validationService.isEmail(value as string)) {
                        updateFieldError(
                            fieldKey,
                            'Must be a valid email address'
                        )
                        return false
                    }
                    return true
                }

                case 'password': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    }
                    return true
                }

                default: {
                    return false
                }
            }
        },
        [form, updateFieldError]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('email'))
        validations.push(validateField('password'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const signIn = useCallback(
        (successFuncRefs: any) => {
            if (!validateForm()) {
                return
            }

            signInReq(
                form.email.value as string,
                form.password.value as string,
                successFuncRefs
            ).catch((error: any) => {
                if (error.code === 'NotAuthorizedException') {
                    updateFieldError('password', 'Incorrect email or password')
                } else if (error.code === 'UserNotConfirmedException') {
                    updateFieldError(
                        'email',
                        'Please confirm your email address before signing in'
                    )
                } else if (error.code === 'LimitExceededException') {
                    updateFieldError(
                        'password',
                        'Too many incorrect signin attempts. Please try again later'
                    )
                } else {
                    updateFieldError('password', 'Unable to sign in')
                }
            })
        },
        [form, validateForm, signInReq, updateFieldError]
    )

    useEffect(() => {
        if (!email) {
            return
        }
        updateField('email', email)
    }, [email, updateField])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        signIn,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const signInReq = useCallback(
        async (
            email: string,
            password: string,
            successFuncRefs: any
        ): Promise<User> => {
            return sendRequest(
                authService.signIn.bind({}, email, password)
            ).then((user: User) => {
                // If user has chosen a plan, then navigate to billing checkout page
                const appPlanId = storage.getPlan()
                if (
                    appPlanId &&
                    ((appPlanId as AppPlanId) === 'starter' ||
                        (appPlanId as AppPlanId) === 'standard')
                ) {
                    storage.clearPlan()
                    successFuncRefs().navBillingCheckout(appPlanId as AppPlanId)
                }

                // Else navigate to main page
                else {
                    successFuncRefs().navMain()
                }
                successFuncRefs().setUser(user)
                return user
            })
        },
        [sendRequest]
    )

    return {
        isLoading,
        signInReq,
    }
}
