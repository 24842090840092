import classNames from 'classnames'
import { useMemo } from 'react'
import styled from 'styled-components'

import Colors from '../../colors'

const Styles = styled.div`
    .rating-bar {
        flex: 1px;
        height: 2px;
        margin-right: 8px;
        border-radius: 5px;

        &.active {
            background-color: ${Colors.GREEN};
        }

        &:last-child {
            margin-right: 0;
        }
    }
`

export const PasswordStrengthRating = ({
    className,
    rating,
}: {
    className?: string
    rating: PasswordStrength
}) => {
    const rating1 = useMemo(
        (): boolean => rating !== 'fail-min-length',
        [rating]
    )
    const rating2 = useMemo(
        (): boolean =>
            rating !== 'fail-min-length' && rating !== 'no-uppercase',
        [rating]
    )
    const rating3 = useMemo(
        (): boolean =>
            rating !== 'fail-min-length' &&
            rating !== 'no-uppercase' &&
            rating !== 'no-number',
        [rating]
    )

    return (
        <Styles className={classNames('flex', className)}>
            <div
                className={`rating-bar bg-gray-200 ${rating1 ? 'active' : ''}`}
            ></div>
            <div
                className={`rating-bar bg-gray-200 ${rating2 ? 'active' : ''}`}
            ></div>
            <div
                className={`rating-bar bg-gray-200 ${rating3 ? 'active' : ''}`}
            ></div>
        </Styles>
    )
}

export type PasswordStrength =
    | 'fail-min-length'
    | 'no-uppercase'
    | 'no-number'
    | 'secure'
