import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, useEffect, useRef } from 'react'
import styled from 'styled-components'

import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import { ChangeTemplate } from './ChangeTemplate'
import { Notepad } from './Notepad'
import { UserCommand } from './UserCommand'

interface Props {
    className?: string
}

const Styles = styled.div`
    display: flex;
    align-items: center;
`

export const ActionBar = ({ className }: Props) => {
    const { visitNotes, isProcessing } = useVisitNotesContext().visitNotes
    const anchorElRef = useRef<any>(null)

    // Scroll to bottom of page
    useEffect(() => {
        if (isProcessing) {
            return
        }
        scrollToEl(anchorElRef.current)
    }, [isProcessing, visitNotes])

    return (
        <>
            <Styles className={classNames(className)}>
                <Tabs onChange={() => scrollToEl(anchorElRef.current, true)} />
            </Styles>
            <div ref={anchorElRef} />
        </>
    )
}

const tabClasses =
    'flex font-medium text-gray-500 hover:text-gray-600 text-sm px-2 py-1 mr-2 rounded-xl hover:bg-gray-200'
const tabSelectedClasses = 'text-primary hover:text-primary bg-primary-50'

const Tabs = ({
    className,
    onChange,
}: {
    className?: string
    templateId?: string
    onChange(): void
}) => {
    return (
        <div className={classNames(className, 'flex flex-col flex-1')}>
            <Tab.Group onChange={onChange}>
                <Tab.List className="flex">
                    <Tab>
                        {({ selected }) => (
                            <div
                                className={classNames(
                                    tabClasses,
                                    selected && tabSelectedClasses
                                )}
                            >
                                Command
                            </div>
                        )}
                    </Tab>
                    <Tab>
                        {({ selected }) => (
                            <div
                                className={classNames(
                                    tabClasses,
                                    selected && tabSelectedClasses
                                )}
                            >
                                Notepad
                            </div>
                        )}
                    </Tab>
                    <Tab>
                        {({ selected }) => (
                            <div
                                className={classNames(
                                    tabClasses,
                                    selected && tabSelectedClasses
                                )}
                            >
                                Template
                            </div>
                        )}
                    </Tab>
                </Tab.List>

                <Tab.Panels className="mt-2">
                    <Tab.Panel as={Fragment}>
                        <UserCommand />
                    </Tab.Panel>
                    <Tab.Panel as={Fragment}>
                        <Notepad />
                    </Tab.Panel>
                    <Tab.Panel as={Fragment}>
                        <ChangeTemplate />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}

let scrollTimeoutRef: any
const scrollToEl = (el: HTMLElement | null, animate?: boolean) => {
    if (!el) {
        return
    }

    const scroll = () => {
        if (animate) {
            el?.scrollIntoView({ behavior: 'smooth' })
        } else {
            el?.scrollIntoView()
        }
    }

    if (scrollTimeoutRef) {
        clearInterval(scrollTimeoutRef)
    }
    scrollTimeoutRef = setTimeout(scroll, 50)
}
