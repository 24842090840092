import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { VisitNote } from '../../services/models/VisitNote.model'

const Styles = styled.div`
    textarea {
        white-space: pre-line;
        resize: none;
    }
`

interface Props {
    className?: string
    visitNote: VisitNote
}

export const NoteEditor = ({ className, visitNote }: Props) => {
    const { updateVisitNoteContent } = useVisitNotesContext().visitNotes
    const [content, setContent] = useState<string>(visitNote.content || '')
    useEffect(() => setContent(visitNote.content || ''), [visitNote.content])

    const onUpdate = useCallback(
        (_content: string) => {
            updateVisitNoteContent(visitNote._id, _content).catch(
                (error: any) => {
                    console.error(error)
                    toast.error('Error updating note')
                }
            )
        },
        [visitNote._id, updateVisitNoteContent]
    )

    return (
        <Styles className={classNames(className)}>
            <TextareaAutosize
                name="visit-note"
                className="w-full"
                value={content}
                onChange={(event) => setContent(event.target.value)}
                onBlur={(event) => onUpdate(event.target.value)}
                readOnly={VisitNote.isProcessing(visitNote)}
            />
        </Styles>
    )
}
