import { storage } from './storage'

// Check for session token in cookies
const resolveSession = () => {
    const getCookie = (name: string): string | undefined => {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts?.pop()?.split(';')?.shift() || undefined
        }
        return undefined
    }

    try {
        const cookie = getCookie('fl_emr_token')
        if (!cookie) {
            return
        }
        const token = JSON.parse(cookie)
        storage.storeEmrToken(token)
        storage.clearAccessToken()
    } catch (error) {
        //
    }
}

export const emr = {
    resolveSession,
}
