import { useCallback, useReducer } from 'react'
import { toast } from 'react-toastify'

import { PASSWORD_MIN_LENGTH } from '../../../../constants'
import { useHttp } from '../../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../../lib/field'
import { userService } from '../../../../services/http/user.service'
import { validationService } from '../../../../services/validation.service'
import { PasswordStrength } from '../../../UI/PasswordStrengthRating'

class FormFields {
    [key: string]: TextField | undefined
    newPassword!: TextField
    confirmNewPassword!: TextField
}

type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    newPassword: initialTextField,
    confirmNewPassword: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    return {
        ...form,
        [fieldKey]: payload as TextField,
    }
}

export interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    checkPasswordStrength(password: string): PasswordStrength
    createPassword(): Promise<void>
}

export const useForm = (
    userId: string | undefined,
    email: string | undefined
): FormHookState => {
    const { createPasswordReq, isLoading } = useHttpReq()
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            const field = form[fieldKey]
            if (!field) return // Safeguard to ensure field is defined

            dispatch({
                fieldKey,
                payload: {
                    value: field.value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const checkPasswordStrength = useCallback(
        (password: string): PasswordStrength => {
            const hasLength = validationService.isMinLength(
                password,
                PASSWORD_MIN_LENGTH
            )
            const hasUpperLetter = validationService.containsUpperCase(password)
            const hasNumber = validationService.containsNumber(password)

            if (!hasLength) {
                return 'fail-min-length'
            } else if (!hasUpperLetter) {
                return 'no-uppercase'
            } else if (!hasNumber) {
                return 'no-number'
            }
            return 'secure'
        },
        []
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const field = form[fieldKey]

            // Ensure the field is defined before accessing its value
            if (!field || field.value === undefined) {
                return false
            }

            const value = field.value

            if (
                fieldKey === 'newPassword' ||
                fieldKey === 'confirmNewPassword'
            ) {
                if (!value) {
                    updateFieldError(fieldKey, 'Password is required')
                    return false
                }

                const passwordStrength = checkPasswordStrength(value)

                // Check if passwords match
                if (form.newPassword.value !== form.confirmNewPassword.value) {
                    updateFieldError(
                        'confirmNewPassword',
                        'Passwords do not match'
                    )
                    return false
                }

                // Detailed password strength validation
                switch (passwordStrength) {
                    case 'fail-min-length':
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 8 characters'
                        )
                        return false
                    case 'no-uppercase':
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 uppercase letter'
                        )
                        return false
                    case 'no-number':
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 number'
                        )
                        return false
                    case 'secure':
                        return true
                    default:
                        updateFieldError(
                            fieldKey,
                            'Password does not meet requirements'
                        )
                        return false
                }
            }

            return true
        },
        [form, updateFieldError, checkPasswordStrength]
    )

    const createPassword = useCallback(async () => {
        // Validate all fields
        const isNewPasswordValid = validateField('newPassword')
        const isConfirmNewPasswordValid = validateField('confirmNewPassword')

        if (
            !isNewPasswordValid ||
            !isConfirmNewPasswordValid ||
            !userId ||
            !email
        ) {
            return
        }

        try {
            await createPasswordReq(
                userId,
                email,
                form.newPassword.value as string
            )
            form.newPassword.value = ''
            form.confirmNewPassword.value = ''
            toast.success('Password created successfully', {
                onClose: () => window.location.reload(),
            })
        } catch (error: any) {
            let errorMessage =
                error.response?.data?.message || 'Failed to create password'
            if (error?.code === 'UsernameExistsException') {
                errorMessage =
                    'User with this email already exists. Please contact support.'
            }
            updateFieldError('newPassword', errorMessage)
        }
    }, [
        userId,
        email,
        validateField,
        form,
        createPasswordReq,
        updateFieldError,
    ])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        checkPasswordStrength,
        createPassword,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const createPasswordReq = useCallback(
        (userId: string, email: string, newPassword: string): Promise<void> =>
            sendRequest(
                userService.createPassword.bind({}, userId, email, newPassword)
            ),
        [sendRequest]
    )

    return {
        isLoading,
        createPasswordReq,
    }
}
