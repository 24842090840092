import { useCallback, useEffect, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useRoute } from '../../../hooks/useRoute'
import { userguiding } from '../../../lib/userguiding'
import { userService } from '../../../services/http/user.service'
import { User } from '../../../services/models/User.model'
import { resolveSession } from '../../UI/GoogleAuthButton'

export interface UserHookState {
    user: User | null
    isAuthenticated: boolean
    isLoading: boolean
    hasLoaded: boolean
    setUser(user: User | null): void
}

export const useUser = (): UserHookState => {
    const { isProtectedRoute } = useRoute()
    const { isMobile } = useMediaQuery()
    const { isLoading, hasLoaded, getRef } = useHttpReq()
    const [user, setUser] = useState<User | null>(null)
    const hasFetchedRef = useRef<boolean>(false)

    const isAuthenticated = !!user

    // Fetch current user on load if route is protected
    useEffect(() => {
        if (hasFetchedRef.current || !isProtectedRoute) {
            return
        }

        // Resolve session from Google Auth
        resolveSession()

        getRef()
            .then((_user) => {
                setUser(_user)

                // Initialize userGuiding
                if (_user) {
                    userguiding.init(_user._id!, _user.email, isMobile)
                }
            })
            .catch((error) => console.error(error))
        hasFetchedRef.current = true
    }, [getRef, isProtectedRoute, isMobile])

    return {
        user,
        isAuthenticated,
        isLoading,
        hasLoaded,
        setUser,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading, hasLoaded } = useHttp()

    const getRef = useCallback(
        (): Promise<User | null> => sendRequest(userService.getUser),
        [sendRequest]
    )

    return {
        isLoading,
        hasLoaded,
        getRef,
    }
}
