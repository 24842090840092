import classNames from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useHttp } from '../../hooks/useHttp'
import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { notesService } from '../../services/http/notes.service'
import { VisitNote } from '../../services/models/VisitNote.model'

interface Props {
    className?: string
    visitId: string
    visitNoteId?: string
    print?: boolean
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const DownloadNote = ({
    className,
    visitId,
    visitNoteId,
    print,
    childNode,
}: Props) => {
    const { getReq, downloadReq, isLoading } = useHttpReq()
    const { getVisitNoteById } = useVisitNotesContext().visitNotes

    const visitNote = useMemo(
        () => (visitNoteId ? getVisitNoteById(visitNoteId) : undefined),
        [getVisitNoteById, visitNoteId]
    )

    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()

            let id
            if (visitNote?._id) {
                id = visitNote?._id
            } else {
                const visitsNotes = await getReq(visitId)
                if (visitsNotes?.length) {
                    id = visitsNotes[visitsNotes.length - 1]._id
                }
            }

            if (!id) {
                return
            }

            try {
                const blob = await downloadReq(id)
                const url = window.URL.createObjectURL(blob)

                if (print) {
                    window.open(url, '_blank')
                } else {
                    const anchorEl = document.createElement('a')
                    anchorEl.href = url
                    anchorEl.download = `visit_${id}.pdf`
                    document.body.appendChild(anchorEl)
                    anchorEl.click()
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url)
                        document.body.removeChild(anchorEl)
                    }, 100)
                }
                toast.success(`${print ? 'Printed' : 'Downloaded'} note`)
            } catch (error) {
                toast.error(`Failed to ${print ? 'print' : 'download'} note`)
            }
        },

        [visitId, visitNote?._id, print, getReq, downloadReq]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isLoading)}
        </Styles>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const getReq = useCallback(
        async (visitId: string): Promise<VisitNote[]> =>
            sendRequest(notesService.getNotes.bind({}, visitId)),
        [sendRequest]
    )

    const downloadReq = useCallback(
        async (id: string): Promise<File> =>
            sendRequest(notesService.downloadNote.bind({}, id)),
        [sendRequest]
    )

    return {
        isLoading,
        getReq,
        downloadReq,
    }
}
