import { AxiosResponse } from 'axios'

import { Template } from '../models/Template.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getTemplates = (): Promise<Template[]> => {
    const url = `${baseUrl}/templates/`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                const templates = response.data?.templates as Template[]
                resolve(templates)
            })
            .catch((error) => reject(error))
    })
}

export const templatesService = {
    getTemplates,
}
