import { XCircleIcon } from '@heroicons/react/24/outline'
import { ArrowLongRightIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import {
    Language,
    LANGUAGES_LABEL,
    MOMENT_TIMESECONDS_FORMAT,
} from '../../constants'
import {
    Transcript,
    TranscriptPartial,
} from '../../services/models/Visit.model'
import { DateLabel } from '../UI/DateLabel'
import { DividerLine } from '../UI/DividerLine'
import { SpeechBubble } from '../UI/SpeechBubble'
import { VirtualList } from '../UI/VirtualList'

interface Props {
    className?: string
    showFooter?: boolean
    transcripts: (Transcript | TranscriptPartial)[]
}

const Styles = styled.div`
    .virtuoso-list {
        & > div {
            top: 32px !important;
            width: calc(100% - 80px) !important;
        }
    }
`

export const TranscriptsList = ({
    className,
    showFooter,
    transcripts,
}: Props) => {
    const virtualListRef = useRef<any>(null)

    const transcriptLanguage = useMemo(
        (): Language | undefined =>
            transcripts?.find((i) => !!i?.language)?.language as
                | Language
                | undefined,
        [transcripts]
    )

    const languageTarget = useMemo(
        (): Language | undefined =>
            transcripts?.find((i) => !!i.translation?.language)?.translation
                ?.language as Language | undefined,
        [transcripts]
    )

    // Scroll list to bottom on new transcript
    useEffect(() => {
        virtualListRef.current?.scrollToIndex({
            index: transcripts.length - 1,
            align: 'end',
            behavior: 'smooth',
        })
    }, [transcripts])

    if (!transcripts.length) {
        return (
            <Styles
                className={classNames(
                    className,
                    'text-gray-400 pt-16 pb-3 px-3 sm:py-8 sm:px-10'
                )}
            >
                <div className="flex">
                    <XCircleIcon className="h-6 w-6 text-gray-300 mr-2" />
                    <span>No transcripts available</span>
                </div>
            </Styles>
        )
    }

    return (
        <Styles className={classNames(className)}>
            {transcriptLanguage && languageTarget && (
                <div className="py-3 mx-3 sm:mx-10 border-b flex items-center text-sm font-medium">
                    <div>
                        {LANGUAGES_LABEL[transcriptLanguage] ??
                            transcriptLanguage}
                    </div>
                    <ArrowLongRightIcon className="h-5 w-5 mx-3" />
                    <div className="text-primary">
                        {LANGUAGES_LABEL[languageTarget] ?? languageTarget}
                    </div>
                </div>
            )}
            <VirtualList
                ref={virtualListRef}
                className="virtuoso-list pt-16 pb-3 px-3 sm:py-8 sm:px-10"
                data={transcripts}
                overscan={200}
                itemNode={(transcript: Transcript, index: number) => {
                    return (
                        <div
                            key={index}
                            className={classNames(
                                'flex flex-col items-start pb-10'
                                // 'animate-fade-down animate-duration-250ms animate-ease-out' // Animation
                            )}
                        >
                            <DateLabel
                                className="block mb-1 text-sm text-gray-500"
                                date={transcript.timestamp}
                                format={MOMENT_TIMESECONDS_FORMAT}
                            />
                            <SpeechBubble
                                className={classNames(
                                    'bg-gray-200',
                                    (transcript as TranscriptPartial)
                                        .isPartial && 'animate-pulse'
                                )}
                                message={transcript.content}
                                translated={transcript.translation?.content}
                            />
                        </div>
                    )
                }}
                footerNode={
                    showFooter && (
                        <>
                            <DividerLine>End of conversation</DividerLine>
                            <div className="h-10" />
                        </>
                    )
                }
            />
        </Styles>
    )
}
