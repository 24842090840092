import classNames from 'classnames'
import styled from 'styled-components'

interface Props {
    className?: string
    message: string
    translated?: string
}

const Styles = styled.div`
    box-sizing: content-box;
    position: relative;

    &:before {
        content: '';
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-right-color: inherit;
        border-top-color: inherit;
        left: -7px;
        top: 6px;
    }
`

export const SpeechBubble = ({ className, message, translated }: Props) => {
    return (
        <Styles className={classNames('rounded-md inline-block', className)}>
            <div className="px-2 py-1">{message}</div>
            {translated && (
                <div className="px-2 py-1 border-t border-gray-300 text-primary">
                    {translated}
                </div>
            )}
        </Styles>
    )
}
