import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useRoute } from '../../../hooks/useRoute'
import { storage } from '../../../lib/storage'
import { Card } from '../Card/Card'
import { TitleWithLogo } from '../Card/TitleWithLogo'
import { LayoutStyles } from '../styles'
import { Form } from './Form'

export const SignIn = () => {
    const { signUpRoute } = useRoute()

    useEffect(() => {
        storage.clearAccessToken()
        storage.clearEmrToken()
    }, [])

    return (
        <LayoutStyles>
            <Card
                titleNode={<TitleWithLogo titleNode={<div>Log in</div>} />}
                subTitleNode={
                    <p>
                        <span>Don&apos;t have an account? </span>
                        <Link
                            className="leading-6 text-primary hover:text-primary"
                            to={signUpRoute}
                        >
                            Sign up
                        </Link>
                    </p>
                }
                bodyNode={<Form />}
            />
        </LayoutStyles>
    )
}
