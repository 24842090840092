import classNames from 'classnames'
import moment from 'moment'
import { useMemo } from 'react'

interface Props {
    className?: string
    seconds: number
}

const hourFormat = 'HH:mm:ss'
const minuteFormat = 'mm:ss'

export const TimeLabel = ({ className, seconds }: Props) => {
    const label = useMemo(
        () =>
            moment
                .utc(seconds * 1000)
                .format(seconds >= 3600 ? hourFormat : minuteFormat),
        [seconds]
    )

    return <div className={classNames(className, 'inline-block')}>{label}</div>
}
