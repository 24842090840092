import { useMemo } from 'react'
import styled from 'styled-components'

import { useAppContext } from '../../hooks/useAppContext'
import { ListBox } from './ListBox'
import { Loader } from './Loader'

interface Props {
    templateId?: string
    onChange(templateId: string): void
}

const Styles = styled.div`
    max-height: 200px;

    .listbox-options {
        max-height: 200px;
    }
`

export const TemplatesListBox = ({ templateId, onChange }: Props) => {
    const { templates, areTemplatesLoading } = useAppContext().templates

    const options = useMemo(() => {
        return templates.map((template) => {
            return {
                value: template._id,
                displayNode: template.name,
            }
        })
    }, [templates])

    const optionSelected = useMemo(
        () => options?.find((i) => i.value === templateId),
        [templateId, options]
    )

    if (areTemplatesLoading) {
        return <Loader />
    }

    return (
        <Styles>
            <ListBox
                optionSelected={optionSelected}
                options={options}
                onChange={(option) => onChange(option.value)}
                listBoxOptionsClassname="listbox-options"
            />
        </Styles>
    )
}
