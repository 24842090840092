export const userguiding = (() => {
    const init = (userId: string, email: string, isMobile: boolean) => {
        // eslint-disable-next-line no-extra-semi
        ;(function (g: any, u: any, i: any, d: any, e: any, s: any) {
            g[e] = g[e] || []
            const f = u.getElementsByTagName(i)[0]
            const k = u.createElement(i)
            k.async = true
            k.src =
                'https://static.userguiding.com/media/user-guiding-' +
                s +
                '-embedded.js'
            f.parentNode.insertBefore(k, f)
            if (g[d]) return
            const ug: any = (g[d] = { q: [] })
            ug.c = function (n: any) {
                return function () {
                    // eslint-disable-next-line prefer-rest-params
                    ug.q.push([n, arguments])
                }
            }
            const m = [
                'previewGuide',
                'finishPreview',
                'track',
                'identify',
                'hideChecklist',
                'launchChecklist',
            ]
            for (let j = 0; j < m.length; j += 1) {
                ug[m[j]] = ug.c(m[j])
            }
        })(
            window,
            document,
            'script',
            'userGuiding',
            'userGuidingLayer',
            'AEF80008DHYID'
        )

        // Identify user
        identifyUser(userId, email, isMobile)
    }

    const identifyUser = (userId: string, email: string, isMobile: boolean) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.userGuiding.identify(userId, {
            email,
            name: email,
            device: isMobile ? 'mobile' : 'desktop',
            createdAt: new Date().getTime(),
        })
    }

    return {
        init,
    }
})()
