import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { ReactNode } from 'react'

import { FieldError } from '../../lib/field'
import { Input } from './Input'

interface Props {
    className?: string
    name: string
    value: string
    error?: FieldError
    labelNode?: ReactNode
    placeholder?: string
    onChange(value: string): void
    onBlur?(): void
}

export const SearchField = ({
    className,
    name,
    value,
    error,
    labelNode,
    placeholder,
    onChange,
    onBlur,
}: Props) => {
    return (
        <Input
            className={className}
            name={name}
            initialValue={value}
            onChangeDebounced={onChange}
            onBlur={onBlur}
            labelNode={labelNode}
            placeholder={placeholder}
            iconNode={<MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />}
            error={error}
            rightNode={
                value ? (
                    <XCircleIcon
                        className="h-6 h-6 ml-1 text-gray-300 cursor-pointer"
                        onClick={() => onChange('')}
                    />
                ) : undefined
            }
        />
    )
}
