import { Outlet } from 'react-router-dom'

import { AppBar } from '../UI/AppBar/AppBar'

export const Main = () => {
    return (
        <div className="fixed w-screen h-full flex flex-col">
            <AppBar />
            <div className="flex-1 overflow-y-auto overflow-x-hidden">
                <Outlet />
            </div>
        </div>
    )
}
