import classNames from 'classnames'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import Colors from '../../colors'
import { Loader } from './Loader'

export type ButtonIntent =
    | 'primary'
    | 'primary-fade'
    | 'secondary'
    | 'hint'
    | 'success'
    | 'warning'
    | 'danger'
    | 'text'
    | 'text-primary'
    | 'text-primary-on-white'
    | 'text-secondary'
    | 'text-secondary-fade'
    | 'text-hint'
    | 'text-white'
    | 'text-danger'

interface Props {
    className?: string
    label: string
    intent?: ButtonIntent
    leftIconNode?: ReactNode
    disabled?: boolean
    isLoading?: boolean
    uppercase?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const Styles = styled.button`
    min-width: 44px;
    min-height: 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;

    ${(props) =>
        props.theme.uppercase &&
        css`
            text-transform: uppercase;
        `};

    &.primary {
        background: ${Colors.PRIMARY};
        color: white;
    }

    &.primary-fade {
        background: ${Colors.PRIMARY}1C;
        color: ${Colors.PRIMARY};
    }

    &.text-primary {
        color: ${Colors.PRIMARY} !important;
        background: transparent;
    }

    &.text-primary-on-white {
        color: ${Colors.PRIMARY} !important;
        background: white;
    }

    &.secondary {
        color: ${Colors.TEXT_SECONDARY};

        &:hover {
            background: #faeded;
        }
    }

    &.text {
        color: ${Colors.TEXT};
        background: transparent;
    }

    &.text-secondary {
        color: ${Colors.TEXT_SECONDARY};
        background: transparent;
    }

    &.text-secondary-fade {
        color: ${Colors.TEXT_SECONDARY};
    }

    &.text-hint {
        color: ${Colors.HINT};
        background: transparent;
    }

    &.text-white {
        color: white;
        background: transparent;
    }

    &.text-danger {
        color: ${Colors.RED};
        background: transparent;
    }

    &.hint {
        color: ${Colors.HINT};

        &:hover {
            background: #efecec;
        }
    }

    &.success {
        color: white;
        background: ${Colors.GREEN};
    }

    &.danger {
        color: ${Colors.RED};
        background: ${Colors.RED}0F;
    }

    &.warning {
        color: ${Colors.ORANGE};
        background: ${Colors.ORANGE}0F;
    }

    &[disabled] {
        opacity: 0.3;
    }
`

export const Button = ({
    className,
    label,
    intent,
    leftIconNode,
    uppercase,
    isLoading,
    disabled,
    onClick,
}: Props) => {
    return (
        <Styles
            className={classNames(
                'py-1.5 px-3 shadow-sm',
                intent &&
                    intent !== 'primary' &&
                    intent !== 'primary-fade' &&
                    'ring-inset ring-1 ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary',
                intent || 'primary',
                !!leftIconNode && !isLoading && !!label && 'pl-10',
                className
            )}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                onClick && onClick(event)
            }
            disabled={disabled || isLoading}
            theme={{ uppercase: uppercase ?? false }}
        >
            {isLoading ? (
                <Loader className="mx-auto" />
            ) : (
                <>
                    {leftIconNode && (
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            {leftIconNode}
                        </div>
                    )}
                    <span className="flex-1 font-medium text-sm">{label}</span>
                </>
            )}
        </Styles>
    )
}
