import { Tab } from '@headlessui/react'
import {
    Bars3Icon,
    ChatBubbleLeftRightIcon,
    ChevronLeftIcon,
    TrashIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { useMediaQuery } from '../../hooks/useMediaQuery'
import { Transcript } from '../../services/models/Visit.model'
import { Button } from '../UI/Button'
import { DeleteVisit } from '../UI/DeleteVisit'
import { Loader } from '../UI/Loader'
import { useVisitsContext } from '../Visits/hooks/useVisitsContext'
import { VisitInfo, VisitInfoMenu, VisitTitle } from './components/VisitInfo'
import { VisitNotes } from './components/VisitNotes/VisitNotes'
import { TranscriptsList } from './TranscriptsList'

const Styles = styled.div`
    .notepad-input {
        height: 100%;

        .input-wrapper textarea {
            resize: none;
            box-shadow: none;
        }
    }
`

export const Visit = () => {
    const { id } = useParams()
    const { isMobile } = useMediaQuery()
    const { showListView } = useVisitsContext().view
    const { areVisitsLoading: isLoading, getVisit } = useVisitsContext().visits
    const visitFromState = useLocation().state?.visit
    const visit = useMemo(
        () => (id ? getVisit(id) : visitFromState),
        [id, getVisit, visitFromState]
    )

    const deleteVisitNode = useMemo(
        () =>
            visit?._id ? (
                <DeleteVisit
                    visitId={visit._id}
                    childNode={(isLoading) => (
                        <Button
                            intent="text"
                            isLoading={isLoading}
                            label=""
                            leftIconNode={
                                <TrashIcon className="h-5 w-5 text-gray-400" />
                            }
                        />
                    )}
                />
            ) : null,
        [visit?._id]
    )

    if (isLoading) {
        return (
            <Styles className="bg-white h-full flex justify-center items-center">
                <LoadingState />
            </Styles>
        )
    }

    if (!visit) {
        return <ErrorState />
    }

    return (
        <Styles className="bg-white h-full flex flex-col">
            {isMobile ? (
                <div className="pt-2 px-2 flex items-center">
                    <Button
                        intent="text"
                        label=""
                        leftIconNode={
                            <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
                        }
                        onClick={showListView}
                    />
                    <VisitTitle
                        className={classNames(
                            'truncate ml-2',
                            visit.state === 'processing' && 'animate-pulse'
                        )}
                        title={visit.title}
                    />
                    <div className="flex">
                        <VisitInfoMenu className="mr-1" visit={visit} />
                        {deleteVisitNode}
                    </div>
                </div>
            ) : (
                <div className="pt-4 pb-3 px-3 sm:pb-4 sm:px-6">
                    <div className="flex items-start justify-space">
                        <VisitTitle
                            className={classNames(
                                'flex-1 mr-5',
                                visit.state === 'processing' && 'animate-pulse'
                            )}
                            title={visit.title}
                        />
                        {deleteVisitNode}
                    </div>
                    <VisitInfo className="mt-3 sm:mt-1" visit={visit} />
                </div>
            )}
            <NoteTabs transcripts={visit.transcripts} />
        </Styles>
    )
}

const LoadingState = () => {
    return (
        <div className="flex items-center justify-center text-gray-500 my-4">
            <Loader className="mr-3" />
            <span>Loading Visit...</span>
        </div>
    )
}

const ErrorState = () => null

const tabClasses =
    'flex items-center text-gray-500 hover:text-gray-600 border-b-2 hover:border-gray-300 p-4 text-sm'
const tabSelectedClasses =
    'text-primary hover:text-primary border-primary hover:border-primary'
const tabIconClasses = 'h-5 w-5 mr-2 text-gray-500'
const tabIconSelectedClasses = 'text-primary'

const NoteTabs = ({
    className,
    transcripts,
}: {
    className?: string
    transcripts: Transcript[]
}) => {
    return (
        <div
            className={classNames(
                className,
                'flex flex-col flex-1 overflow-y-scroll'
            )}
        >
            <Tab.Group>
                <Tab.List className="flex sm:justify-start px-3 sm:px-10 border-b-2 border-gray-100 sticky top-0 bg-white z-10">
                    <Tab>
                        {({ selected }) => (
                            <div
                                className={classNames(
                                    tabClasses,
                                    selected && tabSelectedClasses
                                )}
                            >
                                <Bars3Icon
                                    className={classNames(
                                        tabIconClasses,
                                        selected && tabIconSelectedClasses
                                    )}
                                />
                                Summary
                            </div>
                        )}
                    </Tab>
                    <Tab>
                        {({ selected }) => (
                            <div
                                className={classNames(
                                    tabClasses,
                                    selected && tabSelectedClasses
                                )}
                            >
                                <ChatBubbleLeftRightIcon
                                    className={classNames(
                                        tabIconClasses,
                                        selected && tabIconSelectedClasses
                                    )}
                                />
                                Transcript
                            </div>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels className="flex-1">
                    <Tab.Panel as={Fragment}>
                        <VisitNotes />
                    </Tab.Panel>
                    <Tab.Panel as={Fragment}>
                        <TranscriptsList
                            className="h-full bg-gray-50"
                            transcripts={transcripts}
                            showFooter
                        />
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}
