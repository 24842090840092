import classNames from 'classnames'
import { ReactNode } from 'react'

interface LayoutStylesProps {
    children: ReactNode
}

export const LayoutStyles = ({ children }: LayoutStylesProps) => {
    return (
        <div className="h-full bg-white">
            <div className="mx-auto max-w-6xl px-2 sm:px-6 py-2 sm:py-6 lg:px-8">
                {children}
            </div>
        </div>
    )
}

export const OutletWrapper = ({ children }: { children: ReactNode }) => {
    return <div className="ml-0 sm:ml-16 sm:pl-1">{children}</div>
}

export const SectionWrapper = ({
    className,
    title,
    subTitle,
    children,
}: {
    className?: string
    title: string
    subTitle?: string
    children: ReactNode
}) => {
    return (
        <div className={classNames(className)}>
            <div className="font-medium text-lg">{title}</div>
            {subTitle && (
                <div className="text-sm text-gray-500">{subTitle}</div>
            )}
            <div className="mt-3">{children}</div>
        </div>
    )
}
