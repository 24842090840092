import classNames from 'classnames'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { MOMENT_DATE_FORMAT } from '../../../constants'
import { useRoute } from '../../../hooks/useRoute'
import { Plan } from '../../../services/models/Subscription.model'
import { utilsService } from '../../../services/utils.service'
import { useBillingContext } from '../../Billing/hooks/useBillingContext'
import { Button } from '../../UI/Button'
import { DividerLine } from '../../UI/DividerLine'
import { Logo } from '../../UI/Logo'
import { ProgressBar } from '../../UI/ProgressBar'
import { SectionWrapper } from '../styles'
import { AllPlans } from './AllPlans'

const dateFormat = MOMENT_DATE_FORMAT

export const CurrentPlan = () => {
    const { billingUpdateRoute } = useRoute()
    const {
        subscription,
        currentPlan,
        isSubscribed,
        currentPrice,
        toggleSubscription,
    } = useBillingContext().subscription
    const { billingUsages } = useBillingContext().billingUsages

    const isFreePlan = useMemo(
        () => (currentPlan ? Plan.isFreePlan(currentPlan) : false),
        [currentPlan]
    )
    const isStandardPlan = useMemo(
        () => (currentPlan ? Plan.isStandardPlan(currentPlan) : false),
        [currentPlan]
    )
    const isEnterprisePlan = useMemo(
        () => (currentPlan ? Plan.isEnterprisePlan(currentPlan) : false),
        [currentPlan]
    )

    const isCancellationScheduled = useMemo(
        () => !!subscription?.cancelAt,
        [subscription?.cancelAt]
    )

    const nextPeriodAt = useMemo(
        () =>
            subscription?.currentPeriodEnd
                ? moment(subscription.currentPeriodEnd).format(dateFormat)
                : undefined,
        [subscription?.currentPeriodEnd]
    )

    const cancelAt = useMemo(
        () =>
            subscription?.cancelAt
                ? moment(subscription.cancelAt).format(dateFormat)
                : undefined,
        [subscription?.cancelAt]
    )

    const usageProgress = useMemo((): number => {
        if (isStandardPlan || isEnterprisePlan) {
            return 0
        } else if (billingUsages) {
            return (billingUsages.visitsCreated / billingUsages.maxVisits) * 100
        }
        return 0
    }, [isStandardPlan, isEnterprisePlan, billingUsages])

    const onToggleSubscription = useCallback(
        (cancelAtPeriodEnd: boolean) => {
            if (!subscription?.id) {
                return
            }
            toggleSubscription(subscription.id, cancelAtPeriodEnd)
                .then(() => {
                    toast.success(
                        cancelAtPeriodEnd
                            ? `Subscription scheduled to cancel on ${nextPeriodAt}`
                            : 'Subscription resumed!'
                    )
                })
                .catch((error) => {
                    console.error(error)
                    toast.error(
                        error?.message || cancelAtPeriodEnd
                            ? 'Unable to cancel subscription. Please try again later.'
                            : 'Unable to resume subscription. Please try again later.'
                    )
                })
        },
        [subscription?.id, nextPeriodAt, toggleSubscription]
    )

    if (!isSubscribed || !currentPlan) {
        return null
    }

    return (
        <>
            <SectionWrapper title="Current plan">
                <div className="flex flex-wrap">
                    <div
                        className={classNames({
                            'w-full sm:w-1/2 p-2': isFreePlan,
                            'w-full sm:w-1/3 p-2': !isFreePlan,
                        })}
                    >
                        <div className="h-full rounded-md border py-3 px-4">
                            <div className="flex mb-2">
                                <Logo
                                    className="mr-2"
                                    height={24}
                                    type="logo"
                                />
                                <div className="font-medium">
                                    {currentPlan.name}
                                </div>
                            </div>
                            <div>
                                {utilsService.formatCurrency(
                                    currentPrice?.amount ?? 0
                                )}
                            </div>
                        </div>
                    </div>
                    {billingUsages && (
                        <div
                            className={classNames({
                                'w-full sm:w-1/2 p-2': isFreePlan,
                                'w-full sm:w-1/3 p-2': !isFreePlan,
                            })}
                        >
                            <div className="h-full rounded-md border py-3 px-4">
                                <div className="mb-2 font-medium">Visits</div>
                                <ProgressBar
                                    className="mb-1"
                                    progress={usageProgress}
                                />
                                <div className="text-gray-500">
                                    {billingUsages.visitsCreated}/
                                    {billingUsages.maxVisits > 1000
                                        ? '∞'
                                        : billingUsages.maxVisits}{' '}
                                    visits created
                                </div>
                            </div>
                        </div>
                    )}
                    {!isFreePlan && (
                        <div
                            className={classNames({
                                'w-full sm:w-1/2 p-2': isFreePlan,
                                'w-full sm:w-1/3 p-2': !isFreePlan,
                            })}
                        >
                            <div className="h-full rounded-md border py-3 px-4">
                                {isCancellationScheduled ? (
                                    <>
                                        <div className="mb-2 font-medium">
                                            Scheduled to cancel on
                                        </div>
                                        <div>{cancelAt}</div>
                                    </>
                                ) : (
                                    <>
                                        <div className="mb-2 font-medium">
                                            Next invoice
                                        </div>
                                        <div>{nextPeriodAt}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </SectionWrapper>

            {isFreePlan ? (
                <SectionWrapper className="mt-8" title="All Plans">
                    <AllPlans />
                </SectionWrapper>
            ) : (
                <div className="flex mt-3 ml-2">
                    {!isCancellationScheduled ? (
                        <>
                            <Link to={billingUpdateRoute}>
                                <Button intent="text" label="Change plan" />
                            </Link>
                            <Button
                                className="ml-2"
                                intent="text-danger"
                                label="Cancel subscription"
                                onClick={() => onToggleSubscription(true)}
                            />
                        </>
                    ) : (
                        <Button
                            label="Resume subscription"
                            onClick={() => onToggleSubscription(false)}
                        />
                    )}
                </div>
            )}

            <DividerLine className="mt-6 mb-10" />
        </>
    )
}
