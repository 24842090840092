import { useCallback, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'

import { notepadsService } from '../services/http/notepads.service'
import { Notepad } from '../services/models/Notepad.model'
import { useHttp } from './useHttp'

export interface NotepadHookState {
    notepad: Notepad | null
    createNotepad(
        userId: string,
        visitId: string,
        content: string
    ): Promise<Notepad | null>
    updateNotepad(id: string, updates: Partial<Notepad>): Promise<void>
    handleNotepadUpdate(
        userId: string,
        visitId: string,
        content: string
    ): Promise<void>
}

enum ACTION {
    SET,
}

interface Action {
    type: ACTION
    payload: string | Notepad | Notepad[] | null
}

interface State {
    notepad: Notepad | null
}

const initialState: State = {
    notepad: null,
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.SET: {
            const notepad = payload as Notepad
            return { ...state, notepad }
        }

        default:
            return state
    }
}

export const useNotepad = (visitId: string | undefined): NotepadHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { notepad } = state
    const { getNotepadReq, createNotepadReq, updateNotepadReq } = useHttpReq()

    const getNotepad = useCallback(async () => {
        if (!visitId) {
            return Promise.resolve(null)
        }
        return getNotepadReq(visitId)
            .then((_notepad) => {
                dispatch({ type: ACTION.SET, payload: _notepad })
                return _notepad
            })
            .catch((error) => {
                console.error(error)
                return null
            })
    }, [visitId, getNotepadReq])

    const createNotepad = useCallback(
        async (userId: string, visitId: string, content: string) => {
            if (!content) {
                return null
            }
            return createNotepadReq(userId, visitId, content)
                .then((_notepad) => {
                    dispatch({ type: ACTION.SET, payload: _notepad })
                    return _notepad
                })
                .catch((error) => {
                    console.error(error)
                    toast.error('Error creating notepad')
                    return null
                })
        },
        [createNotepadReq]
    )

    const updateNotepad = useCallback(
        async (id: string, updates: Partial<Notepad>) => {
            return updateNotepadReq(id, updates)
                .then(() => {
                    if (notepad) {
                        const updatedNotepad: Notepad = {
                            ...notepad,
                            ...updates,
                        }
                        dispatch({ type: ACTION.SET, payload: updatedNotepad })
                    }
                })
                .catch((error) => {
                    console.error(error)
                    toast.error('Error updating notepad')
                })
        },
        [notepad, updateNotepadReq]
    )

    const handleNotepadUpdate = useCallback(
        async (userId: string, visitId: string, content: string) => {
            if (notepad?._id) {
                await updateNotepad(notepad._id, { content })
            } else {
                await createNotepad(userId, visitId, content)
            }
        },
        [notepad, createNotepad, updateNotepad]
    )

    useEffect(() => {
        getNotepad()
    }, [getNotepad])

    return {
        notepad,
        createNotepad,
        updateNotepad,
        handleNotepadUpdate,
    }
}

const useHttpReq = () => {
    const { sendRequest } = useHttp()

    const getNotepadReq = useCallback(
        async (visitId: string): Promise<Notepad | null> =>
            sendRequest(notepadsService.getNotepad.bind({}, visitId)),
        [sendRequest]
    )

    const createNotepadReq = useCallback(
        async (
            userId: string,
            visitId: string,
            content: string
        ): Promise<Notepad | null> =>
            sendRequest(
                notepadsService.createNotepad.bind({}, userId, visitId, content)
            ),
        [sendRequest]
    )

    const updateNotepadReq = useCallback(
        async (id: string, updates: Partial<Notepad>): Promise<void> =>
            sendRequest(notepadsService.updateNotepad.bind({}, id, updates)),
        [sendRequest]
    )

    return {
        getNotepadReq,
        createNotepadReq,
        updateNotepadReq,
    }
}
