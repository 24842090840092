import { useCallback } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useRoute } from '../../../hooks/useRoute'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { Button } from '../../UI/Button'
import { Input } from '../../UI/Input'
import { PasswordField } from './PasswordField'
import { useForm } from './useForm'

export const Form = () => {
    const { code } = useParams()
    const { signInRoute, navSignIn } = useRoute()
    const formHookState = useForm(code)
    const { form, isLoading, updateField, validateField, resetPassword } =
        formHookState

    const onSubmit = useCallback(
        () =>
            resetPassword(() => {
                return {
                    navSignIn,
                }
            }),
        [resetPassword, navSignIn]
    )
    useSubmitOnEnter(onSubmit)

    return (
        <>
            <Input
                name="email"
                initialValue={form.email.value as string}
                onChange={(value: string) => updateField('email', value)}
                onBlur={() => validateField('email')}
                labelNode="Email"
                error={form.email.error}
            />
            <PasswordField className="mt-5" formHookState={formHookState} />

            <Button
                className="w-full mt-6"
                label="Submit"
                onClick={onSubmit}
                isLoading={isLoading}
            />

            <p className="mt-5 text-center text-sm text-gray-500">
                <span>Remembered your password? </span>
                <Link
                    className="font-medium leading-6 text-primary hover:text-primary"
                    to={signInRoute}
                >
                    Sign In
                </Link>
            </p>
        </>
    )
}
