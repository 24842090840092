import classNames from 'classnames'
import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
    className?: string
    textClassName?: string
    children?: ReactNode
}

const Styles = styled.div``

export const DividerLine = ({ className, textClassName, children }: Props) => {
    return (
        <Styles className={classNames([className, 'relative'])}>
            <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
            >
                <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
                <span
                    className={classNames(
                        'px-2 text-sm text-gray-400',
                        textClassName ?? 'bg-white'
                    )}
                >
                    {children ?? null}
                </span>
            </div>
        </Styles>
    )
}
