import { useCallback, useReducer } from 'react'

import { PASSWORD_MIN_LENGTH } from '../../../constants'
import { useHttp } from '../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../lib/field'
import { authService } from '../../../services/http/auth.service'
import { validationService } from '../../../services/validation.service'
import { PasswordStrength } from '../../UI/PasswordStrengthRating'

class FormFields {
    email!: TextField
    password!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    email: initialTextField,
    password: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    switch (fieldKey) {
        case 'email':
        case 'password': {
            return {
                ...form,
                [fieldKey]: payload as TextField,
            }
        }

        default: {
            return form
        }
    }
}

export interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    checkPasswordStrength(password: string): PasswordStrength
    signUp(successFuncRefs: any): void
}

export const useForm = (): FormHookState => {
    const { signUpReq, isLoading } = useHttpReq()
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const checkPasswordStrength = useCallback(
        (password: string): PasswordStrength => {
            const hasLength = validationService.isMinLength(
                password,
                PASSWORD_MIN_LENGTH
            )
            const hasUpperLetter = validationService.containsUpperCase(password)
            const hasNumber = validationService.containsNumber(password)

            if (!hasLength) {
                return 'fail-min-length'
            } else if (!hasUpperLetter) {
                return 'no-uppercase'
            } else if (!hasNumber) {
                return 'no-number'
            }
            return 'secure'
        },
        []
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'email': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (!validationService.isEmail(value as string)) {
                        updateFieldError(
                            fieldKey,
                            'Must be a valid email address'
                        )
                        return false
                    }
                    return true
                }

                case 'password': {
                    const passwordStrength = checkPasswordStrength(
                        value as string
                    )

                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (passwordStrength === 'fail-min-length') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 8 characters'
                        )
                        return false
                    } else if (passwordStrength === 'no-uppercase') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 uppercase letter'
                        )
                        return false
                    } else if (passwordStrength === 'no-number') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 number'
                        )
                        return false
                    }
                    return passwordStrength === 'secure'
                }

                default: {
                    return false
                }
            }
        },
        [form, updateFieldError, checkPasswordStrength]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('email'))
        validations.push(validateField('password'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const signUp = useCallback(
        (funcRefs: any) => {
            if (!validateForm()) {
                return
            }

            signUpReq(form.email.value as string, form.password.value as string)
                .then(() => {
                    funcRefs().navSignUpCompletion('', {
                        email: form.email.value,
                    })
                })
                .catch((error: any) => {
                    if (error.code === 'UsernameExistsException') {
                        updateFieldError('email', 'Email address already taken')
                    } else if (error.code === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
                        updateFieldError(
                            'password',
                            'Too many incorrect signup attempts. Please try again later'
                        )
                    } else {
                        updateFieldError(
                            'password',
                            'Unable to sign up. Please try again later'
                        )
                    }
                })
        },
        [
            form.email.value,
            form.password.value,
            validateForm,
            signUpReq,
            updateFieldError,
        ]
    )

    return {
        form,
        isLoading,
        updateField,
        validateField,
        checkPasswordStrength,
        signUp,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const signUpReq = useCallback(
        (email: string, password: string): Promise<void> =>
            sendRequest(authService.signUp.bind({}, email, password)),
        [sendRequest]
    )

    return {
        isLoading,
        signUpReq,
    }
}
