import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useState } from 'react'
import styled from 'styled-components'

import { Input } from '../../UI/Input'
import { PasswordStrengthRating } from '../../UI/PasswordStrengthRating'
import { FormHookState } from './useForm'

interface Props {
    className?: string
    formHookState: FormHookState
}

const Styles = styled.div`
    .show-password-trigger {
        position: relative;
        z-index: 1;
    }
`

export const PasswordField = ({ className, formHookState }: Props) => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { form, updateField, validateField, checkPasswordStrength } =
        formHookState

    return (
        <Styles>
            <Input
                name="password"
                className={classNames(className)}
                type={showPassword ? 'text' : 'password'}
                initialValue={form.password.value as string}
                onChange={(value: string) => updateField('password', value)}
                onBlur={() => validateField('password')}
                labelNode="New Password"
                rightNode={
                    showPassword ? (
                        <EyeIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(false)}
                        />
                    ) : (
                        <EyeSlashIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(true)}
                        />
                    )
                }
                error={form.password.error}
            />
            <PasswordStrengthRating
                className="mt-2"
                rating={checkPasswordStrength(form.password.value as string)}
            />
        </Styles>
    )
}
