import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios'

import { storage } from '../../lib/storage'
import { authService } from './auth.service'

type AxiosRequestConfigWithRetry = AxiosRequestConfig & {
    _retry?: boolean
}

const authAxios: AxiosInstance = axios.create()

// Request interceptor to add the auth token to requests
authAxios.interceptors.request.use(
    (config) => {
        const token = storage.getAccessToken()
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`
        }

        const emrToken = storage.getEmrToken()
        if (emrToken) {
            config.headers['x-fl-emr-token'] = emrToken
        }
        emrToken
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// Response interceptor to handle token expiration and refresh logic
authAxios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    async (error: AxiosError) => {
        const originalRequest = error.config as AxiosRequestConfigWithRetry
        if (!originalRequest) {
            return Promise.reject(error)
        }

        // Check if the response indicates token expiration and there's no retry flag on the request
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            // Attempt to refresh the token
            try {
                await authService.refresh().catch(() => {
                    window.location.href = '/auth/signin'
                    return Promise.reject('Session expired')
                })
                return authAxios(originalRequest)

                // If failed to refresh token, then redirect out the user
            } catch (error) {
                window.location.href = '/auth/signin'
            }
        }

        return Promise.reject(error)
    }
)

export default authAxios

export type ErrorCode = 'LIMIT_REACHED'
