import classNames from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useHttp } from '../../hooks/useHttp'
import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { notesService } from '../../services/http/notes.service'
import { User } from '../../services/models/User.model'
import { VisitNote } from '../../services/models/VisitNote.model'
import { useAuthContext } from '../Authentication/hooks/useAuthContext'

interface Props {
    className?: string
    visitId: string
    visitNoteId?: string
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div``

export const PasteNoteIntoEMR = ({
    className,
    visitId,
    visitNoteId,
    childNode,
}: Props) => {
    const { user } = useAuthContext().user
    const { getVisitNotesReq, isLoading } = useHttpReq()
    const { getVisitNoteById } = useVisitNotesContext().visitNotes

    const visitNote = useMemo(
        () => (visitNoteId ? getVisitNoteById(visitNoteId) : undefined),
        [getVisitNoteById, visitNoteId]
    )

    const isEmrUser = useMemo(
        () => (user ? User.isEmrUser(user) : false),
        [user]
    )

    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()

            let text
            if (visitNote?.content) {
                text = visitNote?.content
            } else {
                const visitsNotes = await getVisitNotesReq(visitId)
                if (visitsNotes?.length) {
                    text = visitsNotes[visitsNotes.length - 1].content
                }
            }

            if (!text) {
                return
            }

            sendNoteToEMR(text)
        },
        [visitId, visitNote?.content, getVisitNotesReq]
    )

    if (!isEmrUser) {
        return null
    }

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isLoading)}
        </Styles>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const getVisitNotesReq = useCallback(
        async (visitId: string): Promise<VisitNote[]> =>
            sendRequest(notesService.getNotes.bind({}, visitId)),
        [sendRequest]
    )

    return {
        isLoading,
        getVisitNotesReq,
    }
}

const sendNoteToEMR = (note: string) => {
    let parentOrigin = ''
    try {
        parentOrigin = new URL(document.referrer)?.origin || ''
    } catch (error) {
        console.error(error)
    }

    try {
        window.opener.postMessage({ action: 'add-note', note }, parentOrigin)
        toast.success('Pasted into EMR')
    } catch (error) {
        console.error(error)
        toast.error('Unable to paste into EMR')
    }
}
