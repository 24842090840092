import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementOptions } from '@stripe/stripe-js'
import { forwardRef, useImperativeHandle } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

interface Props {
    className?: string
}

const Styles = styled.div`
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 12px 16px;
`

const options: StripeCardElementOptions = {
    style: {
        base: {
            color: '#32325d',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
    },
}

// eslint-disable-next-line react/display-name
export const PaymentMethodForm = forwardRef(
    ({ className }: Props, ref: any) => {
        const stripe = useStripe()
        const elements = useElements()

        const onSubmit = async (): Promise<string | null> => {
            if (!stripe || !elements) {
                return Promise.resolve(null)
            }

            const card = elements.getElement(CardElement)
            if (!card) {
                return Promise.resolve(null)
            }

            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card,
            })

            if (paymentMethod?.id) {
                return Promise.resolve(paymentMethod.id)
            } else if (error?.message) {
                if (error?.message) {
                    toast.error(error.message)
                }
                return Promise.reject(error)
            }

            return Promise.resolve(null)
        }

        // Pass references to parent component
        useImperativeHandle(ref, () => ({
            onSubmit() {
                return onSubmit()
            },
        }))

        return (
            <Styles className={className}>
                <CardElement options={options} />
            </Styles>
        )
    }
)
