import classNames from 'classnames'
import { ReactNode, useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useHttp } from '../../hooks/useHttp'
import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { notesService } from '../../services/http/notes.service'
import { VisitNote } from '../../services/models/VisitNote.model'

interface Props {
    className?: string
    visitId: string
    visitNoteId?: string
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const CopyNote = ({
    className,
    visitId,
    visitNoteId,
    childNode,
}: Props) => {
    const { getVisitNotesReq, isLoading } = useHttpReq()
    const { getVisitNoteById } = useVisitNotesContext().visitNotes

    const visitNote = useMemo(
        () => (visitNoteId ? getVisitNoteById(visitNoteId) : undefined),
        [getVisitNoteById, visitNoteId]
    )

    const onClick = useCallback(
        async (event: any) => {
            event.stopPropagation()

            let text
            if (visitNote?.content) {
                text = visitNote?.content
            } else {
                const visitsNotes = await getVisitNotesReq(visitId)
                if (visitsNotes?.length) {
                    text = visitsNotes[visitsNotes.length - 1].content
                }
            }

            if (!text) {
                return
            }

            navigator.clipboard.writeText(text)
            toast.success('Copied to clipboard')
        },
        [visitId, visitNote?.content, getVisitNotesReq]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isLoading)}
        </Styles>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const getVisitNotesReq = useCallback(
        async (visitId: string): Promise<VisitNote[]> =>
            sendRequest(notesService.getNotes.bind({}, visitId)),
        [sendRequest]
    )

    return {
        isLoading,
        getVisitNotesReq,
    }
}
