import { createContext, useContext } from 'react'

import { UserHookState, useUser } from './_useUser'

interface ContextState {
    user: UserHookState
}

const LocalStateContext = createContext<ContextState>(null!)

// Provider
export function AuthContextProvider({ children }: any) {
    const userHookState = useUser()

    return (
        <LocalStateContext.Provider
            value={{
                user: userHookState,
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useAuthContext() {
    return useContext(LocalStateContext)
}
