import { useEffect, useState } from 'react'

import { timer } from '../../../../../lib/timer'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'

export interface TimerHookState {
    duration: number
}

export const useTimer = (
    visitId: string,
    initialDuration: number,
    isPaused: boolean = true
): TimerHookState => {
    const [duration, setDuration] = useState<number>(initialDuration)
    const { updateDuration } = useVisitsContext().visits

    useEffect(() => {
        // If recording is paused, pause the timer and update visit duration in memory
        if (isPaused) {
            const _duration = timer.getSeconds()
            timer.pause(_duration || initialDuration)

            updateDuration(visitId, _duration)
            return
        }

        // If recording is not paused, get the timer continue ticking
        timer.tick(timer.getSeconds() || initialDuration)
    }, [visitId, initialDuration, isPaused, updateDuration])

    useEffect(() => {
        // Subscriber to timer observable to update duration
        const subscription = timer.getObservable().subscribe((seconds) => {
            setDuration(seconds)
        })

        // Clean up on destroy
        return () => {
            // Update visit duration in memory
            const _duration = timer.getSeconds()
            updateDuration(visitId, _duration, true)

            // Unsubscribe from timer observable
            subscription.unsubscribe()

            // Reset the timer back to 0
            timer.reset()
        }
    }, [visitId, updateDuration])

    return {
        duration,
    }
}
