import classNames from 'classnames'
import moment from 'moment'
import { useMemo } from 'react'

import { MOMENT_DATETIME_FORMAT } from '../../constants'

interface Props {
    className?: string
    date: Date
    format?: string
}

export const DateLabel = ({ className, date, format }: Props) => {
    const label = useMemo(
        () => moment(date).format(format || MOMENT_DATETIME_FORMAT),
        [date, format]
    )

    return <div className={classNames('inline-block', className)}>{label}</div>
}
