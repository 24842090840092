import classNames from 'classnames'
import styled from 'styled-components'

import { TimeLabel } from '../../../../UI/TimeLabel'
import { useLocalContext } from '../hooks/useLocalContext'
import { PulsingLabel } from './PulsingLabel'
import { VolumeIndicator } from './VolumeIndicator'

interface Props {
    showMinimalDuration: boolean
}

const Styles = styled.div`
    position: relative;
`

export const DurationDisplay = ({ showMinimalDuration }: Props) => {
    const { isPaused } = useLocalContext().recorder

    return (
        <>
            <Styles className="flex-1 flex flex-col justify-center items-center w-full">
                <PulsingLabel
                    className="z-10"
                    stopPulse={isPaused}
                    label={!isPaused ? 'Recording' : 'Paused'}
                />
                <Duration className="text-3xl mt-2 z-10" />
                <VolumeIndicator />
            </Styles>
            {showMinimalDuration && (
                <div className="flex items-center absolute top-14 sm:top-8 left-3 sm:left-10">
                    <PulsingLabel
                        className="mr-2"
                        stopPulse={isPaused}
                        label={!isPaused ? 'Recording' : 'Paused'}
                        minimal
                    />
                    <Duration className="text-md" />
                </div>
            )}
        </>
    )
}

export const Duration = ({ className }: { className?: string }) => {
    const { duration } = useLocalContext().timer

    return (
        <TimeLabel
            className={classNames('font-medium text-center', className)}
            seconds={duration}
        />
    )
}
