import { useEffect } from 'react'

export const useSubmitOnEnter = (submitFuncRef: () => any) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                event.preventDefault()
                submitFuncRef()
            }
        }
        document.addEventListener('keydown', listener)

        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [submitFuncRef])
}
