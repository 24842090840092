import styled from 'styled-components'

import { useDeveloperContext } from './hooks/useDeveloperContext'

const Styles = styled.div`
    .label-node {
        width: 20%;
        max-width: 125px;
    }
`

export const Developer = () => {
    const {
        isAwsTranscriber,
        isWhisperTranscriber,
        downloadAudioFile,
        logAudioChunks,
        setAwsTranscriber,
        setWhisperTranscriber,
        setDownloadAudioFile,
        setLogAudioChunks,
    } = useDeveloperContext().developer

    return (
        <Styles>
            <div className="flex items-center rounded bg-gray-100 py-3 px-4 w-full">
                <div className="mr-6 font-medium text-lg label-node">
                    Transcriber
                </div>
                <div
                    key="aws-transcriber-type"
                    className="flex items-center mr-6"
                    onClick={() => {
                        setAwsTranscriber()
                        setDownloadAudioFile(false)
                    }}
                >
                    <input
                        id="aws-transcriber-type"
                        name="notification-method"
                        type="radio"
                        defaultChecked={isAwsTranscriber}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                        htmlFor="aws-transcriber-type"
                        className="ml-2 block font-medium leading-6 text-gray-900"
                    >
                        AWS Transcriber
                    </label>
                </div>
                <div
                    key="whisper-transcriber-type"
                    className="flex items-center"
                    onClick={setWhisperTranscriber}
                >
                    <input
                        id="whisper-transcriber-type"
                        name="notification-method"
                        type="radio"
                        defaultChecked={isWhisperTranscriber}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                        htmlFor="whisper-transcriber-type"
                        className="ml-2 block font-medium leading-6 text-gray-900"
                    >
                        Whisper
                    </label>
                </div>
            </div>

            {isWhisperTranscriber && (
                <div className="flex items-center rounded bg-gray-100 py-3 px-4 mt-4">
                    <div className="mr-6 font-medium text-lg label-node">
                        Audio
                    </div>
                    <div className="flex items-center flex-wrap">
                        <div
                            key="download-audio-file"
                            className="flex items-center mr-4"
                        >
                            <input
                                id="download-audio-file"
                                type="checkbox"
                                onChange={() =>
                                    setDownloadAudioFile(!downloadAudioFile)
                                }
                                defaultChecked={downloadAudioFile}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label
                                htmlFor="download-audio-file"
                                className="ml-2 block font-medium leading-6 text-gray-900"
                            >
                                Download audio file when the recording stops
                            </label>
                        </div>
                        <div
                            key="download-audio-file"
                            className="flex items-center"
                        >
                            <input
                                id="log-audio-chunks"
                                type="checkbox"
                                onChange={() =>
                                    setLogAudioChunks(!logAudioChunks)
                                }
                                defaultChecked={logAudioChunks}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label
                                htmlFor="log-audio-chunks"
                                className="ml-2 block font-medium leading-6 text-gray-900"
                            >
                                Log audio chunks in Developer&apos;s Console
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </Styles>
    )
}
