export class User {
    _id?: string
    username?: string
    emrUsername?: string
    email!: string
    emailToVerify?: string
    profilePictureUrl?: string
    token?: string
    refreshToken?: string
    stripeCustomerId!: string
    createdAt!: string

    isWhisperTester?: boolean // TODO: to be deleted after whisper transcriber is enabled for all users
    isTranslationTester?: boolean // TODO: to be deleted after whisper transcriber is enabled for all users

    static isGoogleUser(user: User): boolean {
        return user.username?.startsWith('google_') ?? false
    }

    static isEmrUser(user: User): boolean {
        return !!user.emrUsername
    }
}
