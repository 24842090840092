import { Menu, Transition } from '@headlessui/react'
import {
    ArrowDownTrayIcon,
    ClipboardIcon,
    PencilIcon,
    PrinterIcon,
    TrashIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment, ReactNode, useCallback } from 'react'

import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import { VisitStateType } from '../../../../../services/models/Visit.model'
import { CopyNote } from '../../../../UI/CopyNote'
import { DeleteVisit } from '../../../../UI/DeleteVisit'
import { DownloadNote } from '../../../../UI/DownloadNote'
import { Loader } from '../../../../UI/Loader'

interface Props {
    className?: string
    visitId: string
    visitState: VisitStateType
    templateId?: string
    children: ReactNode
}

const userCommands: string[] = [
    'Create a patient handout from this visit',
    'Write a referral letter',
]

export const SuggestedUserCommandsMenu = ({
    className,
    visitId,
    visitState,
    templateId,
    children,
}: Props) => {
    return (
        <Menu as="div" className={classNames('', className)}>
            <div>{children}</div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="menu-wrapper absolute right-0 bottom-16 w-80 z-20 origin-bottom-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {visitState === 'completed' && (
                        <>
                            {userCommands.map((userCommand, index) => (
                                <Menu.Item key={index}>
                                    {({ active }) => (
                                        <a
                                            className={classNames(
                                                active ? 'bg-gray-100' : '',
                                                'block text-gray-700 cursor-pointer'
                                            )}
                                        >
                                            <UserCommandAction
                                                templateId={templateId}
                                                userCommand={userCommand}
                                                childNode={(isLoading) =>
                                                    isLoading ? (
                                                        <Loader />
                                                    ) : (
                                                        <div className="flex items-center">
                                                            <PencilIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                            <span>
                                                                {userCommand}
                                                            </span>
                                                        </div>
                                                    )
                                                }
                                            />
                                        </a>
                                    )}
                                </Menu.Item>
                            ))}
                            <hr className="my-3" />
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <CopyNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <ClipboardIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>
                                                            Copy summary
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        ></CopyNote>
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <DownloadNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <PrinterIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>Print</span>
                                                    </div>
                                                )
                                            }
                                            print
                                        />
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <DownloadNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <ArrowDownTrayIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>
                                                            Export to PDF
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        />
                                    </a>
                                )}
                            </Menu.Item>
                        </>
                    )}
                    <Menu.Item>
                        {({ active }) => (
                            <a
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block text-gray-700 cursor-pointer'
                                )}
                            >
                                <DeleteVisit
                                    className="px-4 py-2 w-full"
                                    visitId={visitId}
                                    childNode={(isLoading) =>
                                        isLoading ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex items-center">
                                                <TrashIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                <span>Delete visit</span>
                                            </div>
                                        )
                                    }
                                />
                            </a>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const UserCommandAction = ({
    templateId,
    userCommand,
    childNode,
}: {
    templateId?: string
    userCommand: string
    childNode: (isLoading: boolean) => ReactNode
}) => {
    const { generateVisitNote, isProcessing } =
        useVisitNotesContext().visitNotes

    const onClick = useCallback(() => {
        generateVisitNote('user-command', templateId, userCommand)
    }, [templateId, userCommand, generateVisitNote])

    return (
        <div className="px-4 py-2 w-full" onClick={onClick}>
            {childNode(isProcessing)}
        </div>
    )
}
