import { createContext, useContext } from 'react'
import { useParams } from 'react-router'

import { NotepadHookState, useNotepad } from './_useNotepad'

interface ContextState {
    notepad: NotepadHookState
}

const LocalStateContext = createContext<ContextState | null>(null)

// Provider
export function NotepadContextProvider({ children }: any) {
    const { id: visitId } = useParams()
    const notepadHookState = useNotepad(visitId)

    return (
        <LocalStateContext.Provider value={{ notepad: notepadHookState }}>
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useNotepadContext() {
    const context = useContext(LocalStateContext)
    if (context === null) {
        throw new Error(
            'useNotepadContext must be used within a NotepadContextProvider'
        )
    }
    return context
}

export default useNotepadContext
