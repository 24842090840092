import { Menu, Transition } from '@headlessui/react'
import {
    CalendarIcon,
    ClockIcon,
    InformationCircleIcon,
    TagIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment, ReactNode, useMemo } from 'react'

import { MOMENT_DATE_FORMAT, MOMENT_TIME_FORMAT } from '../../../constants'
import { useAppContext } from '../../../hooks/useAppContext'
import { Template } from '../../../services/models/Template.model'
import { Visit as VisitModel } from '../../../services/models/Visit.model'
import { Button } from '../../UI/Button'
import { DateLabel } from '../../UI/DateLabel'
import { DurationLabel } from '../../UI/DurationLabel'

export const VisitTitle = ({
    className,
    title,
}: {
    className?: string
    title: string
}) => {
    return (
        <div
            className={classNames('font-medium text-lg sm:text-xl', className)}
        >
            {title}
        </div>
    )
}

const VisitInfoRow = (
    iconNode: ReactNode,
    value: string | ReactNode,
    className?: string
) => (
    <div
        className={classNames(
            'pr-8 flex items-center text-gray-500',
            className
        )}
    >
        {iconNode}
        <div className="text-sm font-medium">{value}</div>
    </div>
)

export const VisitInfo = ({
    className,
    visit,
}: {
    className?: string
    visit: VisitModel
}) => {
    const { getTemplate } = useAppContext().templates

    const template = useMemo((): Template | undefined => {
        return visit.templateId ? getTemplate(visit.templateId) : undefined
    }, [visit, getTemplate])

    const endedAt = useMemo(() => VisitModel.getEndedAt(visit), [visit])

    return (
        <div className={classNames('flex', className)}>
            {VisitInfoRow(
                <CalendarIcon className="h-5 w-5 mr-1 text-gray-400" />,
                <DateLabel date={visit.createdAt} format={MOMENT_DATE_FORMAT} />
            )}
            {VisitInfoRow(
                <ClockIcon className="h-5 w-5 mr-1 text-gray-400" />,
                <span>
                    <DateLabel
                        date={visit.createdAt}
                        format={MOMENT_TIME_FORMAT}
                    />
                    <span>&nbsp;-&nbsp;</span>
                    <DateLabel date={endedAt} format={MOMENT_TIME_FORMAT} />
                    <span>
                        &nbsp;(
                        <DurationLabel seconds={visit.duration} />)
                    </span>
                </span>
            )}
            {template &&
                VisitInfoRow(
                    <TagIcon className="h-5 w-5 mr-1 text-gray-400" />,
                    template.name
                )}
        </div>
    )
}

export const VisitInfoMenu = ({
    className,
    visit,
}: {
    className?: string
    visit: VisitModel
}) => {
    const { getTemplate } = useAppContext().templates

    const template = useMemo((): Template | undefined => {
        return visit.templateId ? getTemplate(visit.templateId) : undefined
    }, [visit, getTemplate])

    return (
        <Menu as="div" className="relative ml-3">
            <Menu.Button>
                <Button
                    className={classNames(className)}
                    intent="text"
                    label=""
                    leftIconNode={
                        <InformationCircleIcon className="h-5 w-5 text-gray-400" />
                    }
                />
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="w-screen max-w-xs menu-wrapper absolute right-0 z-20 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-2 px-3">
                        <div className="font-medium mb-3">{visit.title}</div>
                        {VisitInfoRow(
                            <CalendarIcon className="h-5 w-5 mr-1 text-gray-400" />,
                            <DateLabel
                                date={visit.createdAt}
                                format={MOMENT_DATE_FORMAT}
                            />,
                            'mb-2'
                        )}
                        {VisitInfoRow(
                            <ClockIcon className="h-5 w-5 mr-1 text-gray-400" />,
                            <span>
                                <DateLabel
                                    date={visit.createdAt}
                                    format={MOMENT_TIME_FORMAT}
                                />
                                <span>&nbsp;-&nbsp;</span>
                                <DateLabel
                                    date={visit.endedAt}
                                    format={MOMENT_TIME_FORMAT}
                                />
                                <span>
                                    &nbsp;(
                                    <DurationLabel seconds={visit.duration} />)
                                </span>
                            </span>,
                            'mb-2'
                        )}
                        {template &&
                            VisitInfoRow(
                                <TagIcon className="h-5 w-5 mr-1 text-gray-400" />,
                                template.name,
                                'mb-2'
                            )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
