/* eslint-disable indent */
export const cannyio = (() => {
    const init = () => {
        // eslint-disable-next-line no-extra-semi
        ;(function (w: any, d, i, s) {
            function l() {
                if (!d.getElementById(i)) {
                    const f: any = d.getElementsByTagName(s)[0],
                        e: any = d.createElement(s)
                    ;(e.type = 'text/javascript'),
                        (e.async = !0),
                        (e.src = 'https://canny.io/sdk.js'),
                        f.parentNode.insertBefore(e, f)
                }
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if ('function' != typeof w.Canny) {
                const c: any = function () {
                    // eslint-disable-next-line prefer-rest-params
                    c.q.push(arguments)
                }
                ;(c.q = []),
                    (w.Canny = c),
                    'complete' === d.readyState
                        ? l()
                        : w.attachEvent
                        ? w.attachEvent('onload', l)
                        : w.addEventListener('load', l, !1)
            }
        })(window, document, 'canny-jssdk', 'script')
    }

    return {
        init,
    }
})()
