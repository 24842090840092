import { useCallback, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'

import { PASSWORD_MIN_LENGTH } from '../../../constants'
import { useHttp } from '../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../lib/field'
import { authService } from '../../../services/http/auth.service'
import { User } from '../../../services/models/User.model'
import { validationService } from '../../../services/validation.service'
import { PasswordStrength } from '../../UI/PasswordStrengthRating'

class FormFields {
    email!: TextField
    password!: TextField
    code!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    email: initialTextField,
    password: initialTextField,
    code: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    switch (fieldKey) {
        case 'email':
        case 'password':
        case 'code': {
            return {
                ...form,
                [fieldKey]: payload as TextField,
            }
        }

        default: {
            return form
        }
    }
}

export interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    checkPasswordStrength(password: string): PasswordStrength
    resetPassword(successFuncRefs: any): void
}

export const useForm = (code?: string): FormHookState => {
    const { resetPasswordReq, isLoading } = useHttpReq()
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const checkPasswordStrength = useCallback(
        (password: string): PasswordStrength => {
            const hasLength = validationService.isMinLength(
                password,
                PASSWORD_MIN_LENGTH
            )
            const hasUpperLetter = validationService.containsUpperCase(password)
            const hasNumber = validationService.containsNumber(password)

            if (!hasLength) {
                return 'fail-min-length'
            } else if (!hasUpperLetter) {
                return 'no-uppercase'
            } else if (!hasNumber) {
                return 'no-number'
            }
            return 'secure'
        },
        []
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'email': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (!validationService.isEmail(value as string)) {
                        updateFieldError(
                            fieldKey,
                            'Must be a valid email address'
                        )
                        return false
                    }
                    return true
                }

                case 'password': {
                    const passwordStrength = checkPasswordStrength(
                        value as string
                    )

                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (passwordStrength === 'fail-min-length') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 8 characters'
                        )
                        return false
                    } else if (passwordStrength === 'no-uppercase') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 uppercase letter'
                        )
                        return false
                    } else if (passwordStrength === 'no-number') {
                        updateFieldError(
                            fieldKey,
                            'Must contain at least 1 number'
                        )
                        return false
                    }
                    return passwordStrength === 'secure'
                }

                case 'code': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    }
                    return true
                }

                default: {
                    return false
                }
            }
        },
        [form, updateFieldError, checkPasswordStrength]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('email'))
        validations.push(validateField('password'))
        validations.push(validateField('code'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const resetPassword = useCallback(
        (successFuncRefs: any) => {
            if (!validateForm()) {
                return
            }

            resetPasswordReq(
                form.email.value as string,
                form.password.value as string,
                form.code.value as string,
                successFuncRefs
            ).catch((error: any) => {
                if (
                    error.code &&
                    (
                        [
                            'CodeMismatchException',
                            'ExpiredCodeException',
                            'LimitExceededException',
                        ] as string[]
                    ).indexOf(error.code) !== -1
                ) {
                    updateFieldError('password', error.message)
                } else {
                    updateFieldError('password', 'Unable to reset password')
                }
            })
        },
        [form, validateForm, resetPasswordReq, updateFieldError]
    )

    useEffect(() => {
        if (!code) {
            return
        }
        updateField('code', code)
    }, [code, updateField])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        checkPasswordStrength,
        resetPassword,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const resetPasswordReq = useCallback(
        async (
            email: string,
            password: string,
            code: string,
            successFuncRefs: any
        ): Promise<User> => {
            return sendRequest(
                authService.resetPassword.bind({}, email, password, code)
            ).then((user: User) => {
                toast.success(
                    'Your password has been reset successfully. You can now log in with your new password',
                    {
                        position: 'bottom-center',
                    }
                )
                successFuncRefs().navSignIn()
                return user
            })
        },
        [sendRequest]
    )

    return {
        isLoading,
        resetPasswordReq,
    }
}
