import { useState } from 'react'

export interface ViewHookState {
    isListView: boolean
    isPreview: boolean
    showListView(): void
    showPreview(): void
}

enum VIEW {
    LIST,
    PREVIEW,
}

type ViewType = VIEW.LIST | VIEW.PREVIEW

export const useView = (): ViewHookState => {
    const [viewMode, setViewMode] = useState<ViewType>(VIEW.PREVIEW)

    const isListView = viewMode === VIEW.LIST
    const isPreview = viewMode === VIEW.PREVIEW

    const showListView = () => setViewMode(VIEW.LIST)
    const showPreview = () => setViewMode(VIEW.PREVIEW)

    return {
        isListView,
        isPreview,
        showListView,
        showPreview,
    }
}
