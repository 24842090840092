import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
    titleNode: ReactNode
    subTitleNode?: ReactNode
    bodyNode: ReactNode
}

const Styles = styled.div`
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .body {
        &-node {
            margin-top: 40px;

            &-wrapper {
                flex: 1 1 100%;
                height: 100%;
                min-height: 500px;
                background: white;
                padding: 24px;
                overflow-y: auto;
            }
        }
    }
`

export const Card = ({ titleNode, subTitleNode, bodyNode }: Props) => {
    return (
        <Styles className="w-full h-full md:w-5/12 md:max-w-md md:h-auto">
            <div className="body-node-wrapper">
                <div className="font-medium text-xl">{titleNode}</div>
                <div className="text-gray-500 text-sm mt-2">{subTitleNode}</div>
                <div className="body-node">{bodyNode}</div>
            </div>
        </Styles>
    )
}
