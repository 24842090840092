import { useCallback, useEffect } from 'react'

import { useRoute } from '../../../hooks/useRoute'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { storage } from '../../../lib/storage'
import { AppPlanId } from '../../../services/models/Subscription.model'
import { Button } from '../../UI/Button'
import { DividerLine } from '../../UI/DividerLine'
import { GoogleAuthButton } from '../../UI/GoogleAuthButton'
import { Input } from '../../UI/Input'
import { PasswordField } from './PasswordField'
import { useForm } from './useForm'

export const Form = () => {
    const { navSignUpCompletion } = useRoute()
    const formHookState = useForm()
    const { form, isLoading, updateField, validateField, signUp } =
        formHookState

    // Store the user chosen plan in local storage
    useEffect(() => {
        const plan = new URLSearchParams(window.location.search)?.get('plan')
        if (
            plan &&
            ((plan as AppPlanId) === 'starter' ||
                (plan as AppPlanId) === 'standard')
        ) {
            storage.storePlan(plan)
        }
    }, [])

    const onSubmit = useCallback(
        () =>
            signUp(() => {
                return {
                    navSignUpCompletion,
                }
            }),
        [signUp, navSignUpCompletion]
    )
    useSubmitOnEnter(onSubmit)

    return (
        <>
            <GoogleAuthButton className="w-full" label="Sign up with Google" />
            <DividerLine className="my-5">OR</DividerLine>

            <Input
                name="email"
                initialValue={form.email.value as string}
                onChange={(value: string) => updateField('email', value)}
                onBlur={() => validateField('email')}
                labelNode="Email"
                error={form.email.error}
            />
            <PasswordField className="mt-5" formHookState={formHookState} />

            <Button
                className="w-full mt-6"
                label="Sign up"
                onClick={onSubmit}
                isLoading={isLoading}
            />
        </>
    )
}
