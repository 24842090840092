import classNames from 'classnames'
import DetectRTC from 'detectrtc'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import Colors from '../../colors'
import { micVad, MicVADEventType } from '../../lib/audio'

interface Props {
    className?: string
    deviceId?: string
}

const Styles = styled.div`
    height: 32px;
    padding: 3px 12px;
    border-radius: 5px;

    .shape {
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        border-radius: 100px;
        display: inline-block;
        background: ${Colors.TEXT_SECONDARY};
        position: relative;
        bottom: 3px;

        &:not(:last-child) {
            margin-right: 3px;
        }
    }
`

export const TestMicrophoneButton = ({ className, deviceId }: Props) => {
    const [isTesting, setIsTesting] = useState<boolean>(false)
    const [isSpeaking, setIsSpeaking] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const audioStreamRef = useRef<MediaStream | null>(null)

    const onStart = useCallback(() => {
        DetectRTC.load(async () => {
            if (!DetectRTC.hasMicrophone || !deviceId) {
                return
            }

            const microphones = DetectRTC.audioInputDevices
            const selectedMicrophone = microphones.find(
                (i) => i.deviceId === deviceId
            )

            if (!selectedMicrophone) {
                return
            }

            setIsLoading(true)
            audioStreamRef.current = await micVad.getAudioStream(
                selectedMicrophone.deviceId,
                true
            )
            setIsLoading(false)
            setIsTesting(true)
        })
    }, [deviceId])

    const onStop = useCallback(() => {
        audioStreamRef.current?.getTracks().forEach((track) => track.stop())
        micVad.pause()
        setIsTesting(false)
    }, [])

    useEffect(() => {
        const subscription = micVad
            .getObservable()
            .subscribe((event: { type: MicVADEventType }) => {
                switch (event.type) {
                    case 'speech-started': {
                        setIsSpeaking(true)
                        break
                    }

                    case 'speech-ended': {
                        setIsSpeaking(false)
                        break
                    }

                    case 'no-audio-picked-up': {
                        setIsSpeaking(false)
                        toast.error('No microphone input detected')
                        break
                    }
                }
            })

        return () => {
            subscription.unsubscribe()
            audioStreamRef.current?.getTracks().forEach((track) => track.stop())
            micVad.pause()
        }
    }, [])

    if (!deviceId) {
        return null
    }

    return (
        <Styles
            className={classNames(
                'border border-gray-300 flex justify-center w-16 hover:cursor-pointer',
                className
            )}
            onClick={() => (isTesting ? onStop() : onStart())}
        >
            {isTesting ? (
                <div>
                    {[1, 2, 3].map((index) => (
                        <div
                            key={index}
                            className={classNames('shape', {
                                'animate-[pulse_400ms_ease-in-out_infinite]':
                                    isSpeaking,
                            })}
                        ></div>
                    ))}
                </div>
            ) : (
                <div
                    className={classNames(
                        'text-center text-sm font-medium relative top-0.5',
                        {
                            'opacity-0': isLoading,
                        }
                    )}
                >
                    Test
                </div>
            )}
        </Styles>
    )
}
