import classNames from 'classnames'
import { useCallback, useState } from 'react'

import { useVisitsContext } from '../Visits/hooks/useVisitsContext'
import { SearchField } from './SearchField'

interface Props {
    className?: string
}

export const SearchVisits = ({ className }: Props) => {
    const { searchVisits } = useVisitsContext().visits
    const [searchTerm, setSearchTerm] = useState<string>('')

    const onChange = useCallback(
        (_searchTerm: string) => {
            setSearchTerm(_searchTerm)
            searchVisits(_searchTerm)
        },
        [searchVisits]
    )

    return (
        <SearchField
            className={classNames('flex-1 mr-3', className)}
            name="search"
            value={searchTerm}
            onChange={onChange}
            placeholder="Search"
        />
    )
}
