import { useCallback, useEffect, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { paymentsService } from '../../../services/http/payments.service'
import {
    AppPlanId,
    Plan,
    Price,
} from '../../../services/models/Subscription.model'
import { User } from '../../../services/models/User.model'

export type PlansHookState = {
    plans: Plan[]
    isLoading: boolean
    getPlan(id: string): Plan | undefined
    getPlanByAppPlanId(appPlanId: AppPlanId): Plan | undefined
    getPrice(plan?: Plan): Price | undefined
}

export const usePlans = (user: User | null): PlansHookState => {
    const { isLoading, getReq } = useHttpReq()
    const [plans, setPlans] = useState<Plan[]>([])
    const hasFetchedRef = useRef<boolean>(false)

    const getPlan = useCallback(
        (id: string) => plans.find((plan) => plan.id === id),
        [plans]
    )

    const getPlanByAppPlanId = useCallback(
        (appPlanId: AppPlanId) =>
            plans.find((plan) => plan.metadata.app_plan_id === appPlanId),
        [plans]
    )

    const getPrice = useCallback((plan?: Plan): Price | undefined => {
        if (!plan) {
            return undefined
        }
        return plan.price?.length ? plan.price[0] : undefined
    }, [])

    useEffect(() => {
        if (hasFetchedRef.current || !user) {
            return
        }
        getReq(user.emrUsername)
            .then(setPlans)
            .catch((error) => console.error(error))
        hasFetchedRef.current = true
    }, [user, getReq])

    return {
        plans,
        isLoading,
        getPlan,
        getPlanByAppPlanId,
        getPrice,
    }
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getReq = useCallback(
        (emrUsername?: string): Promise<Plan[]> =>
            sendRequest(paymentsService.getPlans.bind({}, emrUsername)),
        [sendRequest]
    )

    return {
        isLoading,
        getReq,
    }
}
