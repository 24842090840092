/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/ban-ts-comment */
export const googlead = (() => {
    const init = () => {
        // If google ad is not enabled, do nothing
        const src = process.env.REACT_APP_GOOGLE_AD_SRC
        if (!src) {
            return
        }

        const scriptEl = document.createElement('script')
        scriptEl.type = 'text/javascript'
        scriptEl.defer = true
        scriptEl.src = src
        document.getElementsByTagName('head')[0].appendChild(scriptEl)

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.dataLayer = window.dataLayer || []
        function gtag() {
            // @ts-expect-error
            window.dataLayer.push(arguments)
        }

        // @ts-expect-error
        gtag('js', new Date())

        // @ts-expect-error
        gtag('config', 'G-MY3GY6JW40')
    }

    return {
        init,
    }
})()
