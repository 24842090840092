import { useCallback, useEffect, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { paymentsService } from '../../../services/http/payments.service'
import { Invoice } from '../../../services/models/Subscription.model'

export type InvoicesHookState = {
    invoices: Invoice[]
    isLoading: boolean
    getInvoices(): Promise<void>
}

export const useInvoices = (
    stripeCustomerId: string | undefined
): InvoicesHookState => {
    const { isLoading, getInvoicesReq } = useHttpReq()
    const [invoices, setInvoices] = useState<Invoice[]>([])
    const hasFetchedRef = useRef<boolean>(false)

    const getInvoices = useCallback(() => {
        if (!stripeCustomerId) {
            return Promise.reject('stripeCustomerId is missing')
        }
        return getInvoicesReq(stripeCustomerId)
            .then(setInvoices)
            .catch((error) => console.error(error))
    }, [stripeCustomerId, getInvoicesReq])

    useEffect(() => {
        if (hasFetchedRef.current || !stripeCustomerId) {
            return
        }
        getInvoices()
        hasFetchedRef.current = true
    }, [getInvoices, stripeCustomerId])

    return {
        invoices,
        isLoading,
        getInvoices,
    }
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getInvoicesReq = useCallback(
        (stripeCustomerId: string): Promise<Invoice[]> =>
            sendRequest(paymentsService.getInvoices.bind({}, stripeCustomerId)),
        [sendRequest]
    )

    return {
        isLoading,
        getInvoicesReq,
    }
}
