import { useCallback, useEffect, useReducer, useState } from 'react'
import { toast } from 'react-toastify'

import { Language } from '../../../../../constants'
import { useAppContext } from '../../../../../hooks/useAppContext'
import { audio } from '../../../../../lib/audio'
import { AwsTranscriber, Credentials } from '../../../../../lib/AwsTranscriber'
import { Whisper } from '../../../../../lib/Whisper'
import {
    eventBus,
    EventBusData,
} from '../../../../../services/event-bus/eventBus.service'
import { UpdateMediaDeviceState } from '../../../../../services/event-bus/events'
import { transcriptionsService } from '../../../../../services/http/transcriptions.service'
import { useDeveloperContext } from '../../../../Settings/Developer/hooks/useDeveloperContext'

export interface RecorderHookState {
    isRecording: boolean
    isPaused: boolean
    isLoading: boolean
    onRecord(): void
    onPause(): void
}

interface State {
    visitId: string | null
    status: Status
    transcriber: AwsTranscriber | Whisper | null
    credentials: Credentials | null
}

const makeInitialState = (visitId: string | null): State => {
    return {
        visitId,
        status: 'paused',
        transcriber: null,
        credentials: null,
    }
}

type Status = 'paused' | 'recording'

enum ACTION {
    INIT,
    RECORD,
    PAUSE,
}

interface Action {
    type: ACTION
    payload?: AwsTranscriber | Whisper | Credentials
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION.INIT: {
            const credentials = action.payload as Credentials

            return {
                ...state,
                credentials,
            }
        }

        case ACTION.RECORD: {
            const transcriber = action.payload as AwsTranscriber
            if (state.credentials) {
                transcriber.setCredentials(state.credentials)
            }
            transcriber.start()

            return {
                ...state,
                transcriber,
                status: 'recording',
            }
        }

        case ACTION.PAUSE: {
            state.transcriber?.stop()

            return {
                ...state,
                status: 'paused',
                transcriber: null,
            }
        }

        default: {
            return state
        }
    }
}

export const useRecorder = (
    visitId: string,
    languageSource?: Language,
    languageTarget?: Language
): RecorderHookState => {
    const { preferredMicrophoneDeviceId } = useAppContext().appSettings
    const { isAwsTranscriber, downloadAudioFile, logAudioChunks } =
        useDeveloperContext().developer
    const [state, dispatch] = useReducer(reducer, makeInitialState(visitId))
    const { status, transcriber } = state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const isRecording = status === 'recording'
    const isPaused = status === 'paused'

    const onRecord = useCallback(async () => {
        const isMicrophoneAvailable = await audio.isMicrophoneAvailable()
        if (!isMicrophoneAvailable) {
            toast.error('Unable to detect microphone')
            return
        }

        let _transcriber
        if (isAwsTranscriber) {
            _transcriber = new AwsTranscriber(
                visitId!,
                preferredMicrophoneDeviceId ?? undefined
            )
        } else {
            _transcriber = new Whisper(
                visitId!,
                languageSource ?? null,
                languageTarget ?? null,
                preferredMicrophoneDeviceId ?? undefined,
                downloadAudioFile,
                logAudioChunks
            )
        }

        dispatch({
            type: ACTION.RECORD,
            payload: _transcriber,
        })
    }, [
        visitId,
        languageSource,
        languageTarget,
        preferredMicrophoneDeviceId,
        isAwsTranscriber,
        downloadAudioFile,
        logAudioChunks,
    ])

    const onPause = useCallback(() => {
        dispatch({
            type: ACTION.PAUSE,
        })
    }, [])

    // Initialize transcriber on load and set credentials
    useEffect(() => {
        if (!isAwsTranscriber) {
            return
        }

        // eslint-disable-next-line no-extra-semi
        ;(async (visitId) => {
            try {
                const credentials = await transcriptionsService.getCredentials(
                    visitId
                )
                dispatch({
                    type: ACTION.INIT,
                    payload: credentials,
                })
            } catch (error) {
                console.error(error)
            }
        })(visitId)
    }, [visitId, isAwsTranscriber])

    useEffect(() => {
        const subscription = eventBus
            .getObservable()
            .subscribe((event: EventBusData) => {
                switch (event.action) {
                    case UpdateMediaDeviceState.action: {
                        const { isAvailable } = event.payload
                        setIsLoading(isAvailable ? false : true)
                        break
                    }
                }
            })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    // Stop transcriber on unmount
    useEffect(() => {
        return () => {
            transcriber?.stop()
        }
    }, [transcriber])

    return {
        isRecording,
        isPaused,
        isLoading,
        onRecord,
        onPause,
    }
}
