import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import useNotepadContext from '../../hooks/useNotepadContext'
import { useAuthContext } from '../Authentication/hooks/useAuthContext'
import { Input } from './Input'

const Styles = styled.div`
    .input-wrapper {
        height: 100%;

        textarea {
            height: 100%;
            white-space: pre-line;
            resize: none;
        }
    }
`

interface Props {
    className?: string
    inputClassName?: string
    visitId: string
    rightNode?: ReactNode
}

export const NotepadEditor = ({
    className,
    inputClassName,
    visitId,
    rightNode,
}: Props) => {
    const { user } = useAuthContext().user

    const { notepad, handleNotepadUpdate } = useNotepadContext().notepad

    const onNoteUpdate = useCallback(
        (newNote: string) => {
            if (user?._id === undefined) return
            handleNotepadUpdate(user?._id, visitId, newNote).catch(
                (error: any) => {
                    toast.error('Error updating notepad')
                    console.error(error)
                }
            )
        },
        [handleNotepadUpdate, user, visitId]
    )

    return (
        <Styles className={classNames(className)}>
            <Input
                name="summary"
                className="h-full"
                placeholder="Enter any information you would like to have included in your generated note..."
                inputClassName={inputClassName}
                initialValue={notepad?.content || ''}
                onBlur={(event) => onNoteUpdate(event.target.value)}
                rightNode={rightNode}
                multiline
            />
        </Styles>
    )
}
