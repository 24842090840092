import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useRoute } from '../../../hooks/useRoute'
import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { DividerLine } from '../DividerLine'
import { Loader } from '../Loader'
import { SignOut } from '../SignOut'
import { Avatar } from './Avatar'

const Styles = styled.div`
    .menu-wrapper {
        max-width: 300px;
    }
`

const isDeveloperPageEnabled =
    process.env.REACT_APP_DEVELOPER_SETTINGS === 'true'

export const AvatarMenu = () => {
    const { accountRoute, billingRoute, developerRoute, requestFeatureRoute } =
        useRoute()
    const { user } = useAuthContext().user
    const email = user?.email

    return (
        <Menu as="div" className="relative ml-3">
            <div>
                <Menu.Button className="relative flex rounded-full bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-1.5" />
                    <Avatar />
                </Menu.Button>
            </div>
            <Styles>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="menu-wrapper absolute right-0 z-20 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {email && (
                            <>
                                <div className="px-4 py-2 bg-gray-100 font-medium truncate">
                                    {email}
                                </div>
                                <DividerLine />
                            </>
                        )}
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={accountRoute}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex px-4 py-2 text-gray-700'
                                    )}
                                >
                                    Settings
                                </Link>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={billingRoute}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex px-4 py-2 text-gray-700'
                                    )}
                                >
                                    Billing
                                </Link>
                            )}
                        </Menu.Item>
                        {isDeveloperPageEnabled && (
                            <Menu.Item>
                                {({ active }) => (
                                    <Link
                                        to={developerRoute}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'flex px-4 py-2 text-gray-700'
                                        )}
                                    >
                                        Developer
                                    </Link>
                                )}
                            </Menu.Item>
                        )}
                        <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={requestFeatureRoute}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex px-4 py-2 text-gray-700'
                                    )}
                                >
                                    Request a feature
                                </Link>
                            )}
                        </Menu.Item>
                        <DividerLine />
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'flex text-gray-700 cursor-pointer'
                                    )}
                                >
                                    <SignOut
                                        className="flex px-4 py-2 w-full"
                                        childNode={(isLoading) =>
                                            isLoading ? (
                                                <Loader />
                                            ) : (
                                                <span>Sign out</span>
                                            )
                                        }
                                    />
                                </a>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Styles>
        </Menu>
    )
}
