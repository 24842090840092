import { MicrophoneIcon, PauseIcon } from '@heroicons/react/24/solid'
import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { IconButton } from '../../../../UI/IconButton'
import { Loader } from '../../../../UI/Loader'
import { useLocalContext } from '../hooks/useLocalContext'
import { ButtonLabel, ButtonWrapper } from '../styles'

const iconClassNames = 'h-5 w-5 text-white'

export const RecordButton = () => {
    const { isRecording, isPaused, isLoading, onRecord, onPause } =
        useLocalContext().recorder
    const startRecordingOnLoad = !!useLocation().state?.startRecordingOnLoad
    const hasStartedRecordingRef = useRef<boolean>(false)

    // Start recording on load if requested
    useEffect(() => {
        if (!startRecordingOnLoad || hasStartedRecordingRef.current) {
            return
        }
        onRecord()
        hasStartedRecordingRef.current = true
    }, [startRecordingOnLoad, onRecord])

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            if (isRecording) {
                onPause()
            } else if (isPaused) {
                onRecord()
            }
        },
        [isRecording, isPaused, onRecord, onPause]
    )

    return (
        <ButtonWrapper>
            <div onClick={onClick}>
                <IconButton
                    intent="danger"
                    iconNode={
                        isLoading ? (
                            <Loader className="text-white" />
                        ) : isRecording ? (
                            <PauseIcon className={iconClassNames} />
                        ) : isPaused ? (
                            <MicrophoneIcon className={iconClassNames} />
                        ) : null
                    }
                />
            </div>
            <ButtonLabel>
                {isRecording ? 'Pause' : isPaused ? 'Record' : null}
            </ButtonLabel>
        </ButtonWrapper>
    )
}
