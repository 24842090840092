import { AxiosResponse } from 'axios'

import {
    BillingUsage,
    Invoice,
    PaymentMethod,
    Plan,
    PromotionCode,
    Subscription,
} from '../models/Subscription.model'
import authAxios from './auth.axios'

const baseURL = process.env.REACT_APP_API

const getPlans = async (emrUsername?: string): Promise<Plan[]> => {
    let url = `${baseURL}/payments/plans`
    if (emrUsername) {
        url = `${url}?emrUsername=${emrUsername}`
    }
    return authAxios.get(url).then((response: AxiosResponse) => {
        let plans = response.data?.plans as Plan[]
        plans = Plan.sort(plans)
        return plans
    })
}

const getSubscription = async (
    stripeCustomerId: string
): Promise<Subscription | null> => {
    return authAxios
        .get(
            `${baseURL}/payments/subscriptions?stripeCustomerId=${stripeCustomerId}`
        )
        .then((response: AxiosResponse) => {
            return response.data.subscription
                ? Subscription.deseralize(response.data.subscription)
                : null
        })
}

const createSubscription = async (
    stripeCustomerId: string,
    currentSubscriptionId: string,
    priceId: string,
    promotionCodeId?: string
): Promise<Subscription | null> => {
    const payload = {
        stripeCustomerId,
        currentSubscriptionId,
        priceId,
        promotionCodeId,
    }
    return new Promise((resolve, reject) => {
        authAxios
            .post(`${baseURL}/payments/subscriptions`, payload)
            .then((response: AxiosResponse) => {
                resolve(
                    response.data.subscription
                        ? Subscription.deseralize(response.data.subscription)
                        : null
                )
            })
            .catch((error) => reject(error?.response?.data))
    })
}

const updateSubscription = async (
    stripeCustomerId: string,
    priceId: string
): Promise<Subscription | null> => {
    const payload = {
        stripeCustomerId,
        priceId,
    }
    return new Promise((resolve, reject) => {
        authAxios
            .put(`${baseURL}/payments/subscriptions`, payload)
            .then((response: AxiosResponse) => {
                resolve(
                    response.data.subscription
                        ? Subscription.deseralize(response.data.subscription)
                        : null
                )
            })
            .catch((error) => reject(error?.response?.data))
    })
}

const toggleSubscription = async (
    subscriptionId: string,
    cancelAtPeriodEnd: boolean
): Promise<Subscription | null> => {
    const payload = {
        subscriptionId,
        cancelAtPeriodEnd,
    }
    return authAxios
        .post(`${baseURL}/payments/subscriptions/toggle`, payload)
        .then((response: AxiosResponse) => {
            return response.data.subscription
                ? Subscription.deseralize(response.data.subscription)
                : null
        })
}

const getPaymentMethod = async (
    stripeCustomerId: string
): Promise<PaymentMethod | null> => {
    return authAxios
        .get(`${baseURL}/payments/methods?stripeCustomerId=${stripeCustomerId}`)
        .then((response: AxiosResponse) => {
            return response.data.paymentMethod
                ? PaymentMethod.deseralize(response.data.paymentMethod)
                : null
        })
}

const attachPaymentMethod = async (
    stripeCustomerId: string,
    paymentMethodId: string
): Promise<void> => {
    const payload = {
        stripeCustomerId,
        paymentMethodId,
    }
    return authAxios.post(`${baseURL}/payments/methods/attach`, payload)
}

const deattachPaymentMethod = async (
    paymentMethodId: string
): Promise<void> => {
    const payload = {
        paymentMethodId,
    }
    return authAxios.post(`${baseURL}/payments/methods/deattach`, payload)
}

const getInvoices = async (stripeCustomerId: string): Promise<Invoice[]> => {
    return authAxios
        .get(
            `${baseURL}/payments/invoices?stripeCustomerId=${stripeCustomerId}`
        )
        .then((response: AxiosResponse) => {
            return (
                response.data?.invoices?.map((obj: any) =>
                    Invoice.deseralize(obj)
                ) || []
            )
        })
}

const searchPromotionCode = async (
    code: string
): Promise<PromotionCode | null> => {
    return authAxios
        .get(`${baseURL}/payments/promotion-codes/search?code=${code}`)
        .then((response: AxiosResponse) => {
            return response.data.promotionCode
                ? PromotionCode.deseralize(response.data.promotionCode)
                : null
        })
}

const getBillingUsages = async (
    userId: string
): Promise<BillingUsage | null> => {
    return authAxios
        .get(`${baseURL}/payments/usages?userId=${userId}`)
        .then((response: AxiosResponse) => {
            return response.data.billingUsage
                ? BillingUsage.deseralize(response.data.billingUsage)
                : null
        })
}

export const paymentsService = {
    getPlans,
    getPaymentMethod,
    attachPaymentMethod,
    deattachPaymentMethod,
    getSubscription,
    createSubscription,
    updateSubscription,
    toggleSubscription,
    getInvoices,
    searchPromotionCode,
    getBillingUsages,
}
