import classNames from 'classnames'

interface Props {
    className?: string
    seconds: number
}

export const DurationLabel = ({ className, seconds }: Props) => {
    const unit = seconds >= 60 ? 'm' : 's'
    const duration = seconds >= 60 ? Math.floor(seconds / 60) : seconds

    return (
        <div className={classNames(className, 'inline-block')}>
            {duration}
            {unit}
        </div>
    )
}
