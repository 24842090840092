import classNames from 'classnames'
import { forwardRef, ReactNode } from 'react'
import { Virtuoso } from 'react-virtuoso'

interface Props {
    className?: string
    data: any[]
    overscan?: number
    followOutput?: boolean
    alignToBottom?: boolean
    footerNode?: any
    itemNode(item: any, index: number): ReactNode
}

// eslint-disable-next-line react/display-name
export const VirtualList = forwardRef(
    (
        {
            className,
            data,
            overscan,
            followOutput,
            alignToBottom,
            itemNode,
            footerNode,
        }: Props,
        ref: any
    ) => {
        return (
            <Virtuoso
                ref={ref}
                className={classNames(className)}
                data={data}
                initialTopMostItemIndex={data.length - 1}
                overscan={overscan}
                followOutput={followOutput ? 'smooth' : undefined}
                alignToBottom={alignToBottom}
                itemContent={(index: number) => {
                    const item = data[index]
                    return itemNode(item, index)
                }}
                components={{
                    Footer: () => footerNode,
                }}
            />
        )
    }
)
