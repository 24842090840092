import { useParams } from 'react-router'
import styled from 'styled-components'

import { ActionBar } from './ActionBar/ActionBar'
import { NotesList } from './NotesList'

const Styles = styled.div``

export const VisitNotes = () => {
    const { id: visitId } = useParams()

    return (
        <Styles className="py-3 px-3 sm:px-10 bg-gray-50 h-full">
            <div key={visitId} className="h-full flex flex-col">
                <NotesList
                    key={visitId as string}
                    visitId={visitId as string}
                />
                <ActionBar />
            </div>
        </Styles>
    )
}
