import DetectRTC from 'detectrtc'
import { useCallback, useEffect, useState } from 'react'

import { storage } from '../lib/storage'

export interface AppSettingsHookState {
    preferredMicrophoneDeviceId: string | null
    setPreferredMicrophoneDeviceId(deviceId: string): void
}

export const useAppSettings = () => {
    const [preferredMicrophoneDeviceId, setPreferredMicrophoneDeviceId] =
        useState<string | null>(null)

    const onSetPreferredMicrophoneDeviceId = useCallback((deviceId: string) => {
        setPreferredMicrophoneDeviceId(deviceId)
        storage.storeMicrophoneDeviceId(deviceId)
    }, [])

    useEffect(() => {
        const deviceId = storage.getMicrophoneDeviceId()
        if (!deviceId) {
            return
        }
        // Check if deviceId exists
        DetectRTC.load(() => {
            const microphones = DetectRTC.audioInputDevices
            const hasMicrophone = microphones.some(
                (i) => i.deviceId === deviceId
            )
            if (hasMicrophone) {
                setPreferredMicrophoneDeviceId(deviceId)
            } else if (microphones?.length === 1) {
                setPreferredMicrophoneDeviceId(microphones[0].deviceId)
                storage.storeMicrophoneDeviceId(microphones[0].deviceId)
            } else {
                setPreferredMicrophoneDeviceId(null)
                storage.clearMicrophoneDeviceId()
            }
        })
    }, [])

    return {
        preferredMicrophoneDeviceId,
        setPreferredMicrophoneDeviceId: onSetPreferredMicrophoneDeviceId,
    }
}
