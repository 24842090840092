export class VisitNote {
    _id!: string
    visitId!: string
    eventId!: string
    state!: VisitNoteStateType
    intent!: VisitNoteIntent
    name!: string
    content!: string
    templateId?: string
    isSystemGenerated!: boolean
    createdAt!: Date
    updatedAt!: Date
    deletedAt?: Date

    static deserialize(obj: any): VisitNote {
        const model: VisitNote = Object.assign({}, obj)
        model.createdAt = new Date(obj.createdAt)
        model.updatedAt = new Date(obj.updatedAt)
        if (obj.deletedAt) {
            model.deletedAt = new Date(obj.deletedAt)
        }
        return model
    }

    static groupByEventId(visitNotes: VisitNote[]): Map<string, VisitNote[]> {
        const visitNotesMap = new Map<string, VisitNote[]>()
        visitNotes.forEach((visitNote) => {
            const visitNotes = visitNotesMap.get(visitNote.eventId) || []
            visitNotesMap.set(visitNote.eventId, [...visitNotes, visitNote])
        })
        return visitNotesMap
    }

    static isProcessing = (visitNotes: VisitNote): boolean => {
        return visitNotes.state === 'processing'
    }
}

export type VisitNoteStateType = 'processing' | 'completed'
export type VisitNoteIntent =
    | 'note'
    | 'change-template'
    | 'regenerate-note'
    | 'user-command'
