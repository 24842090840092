import { DividerLine } from '../../../UI/DividerLine'
import { SectionWrapper } from '../../styles'
import { UpdatePasswordForm } from './Form'

export const CreatePassword = () => {
    return (
        <>
            <DividerLine className="my-8" />
            <SectionWrapper
                title="Create password"
                subTitle="Create a password for signing in with your email"
            >
                <UpdatePasswordForm />
            </SectionWrapper>
        </>
    )
}
