import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { BillingCheckout } from '../components/Settings/Billing/BillingCheckout/BillingCheckout'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!)

export const BillingCheckoutPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <BillingCheckout />
        </Elements>
    )
}
