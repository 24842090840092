import { useCallback, useEffect, useReducer } from 'react'
import { toast } from 'react-toastify'

import {
    preferencesService,
    UpdatePreferencesPayload,
} from '../services/http/preferences.service'
import { Preferences } from '../services/models/Preference.model'
import { useHttp } from './useHttp'

export interface PreferencesHookState {
    preferences: Preferences | undefined
    isPreferenceLoading: boolean
    isPreferencesLoaded: boolean
    isUpdatePreferenceLoading: boolean
    getPreferences(id: string): Promise<Preferences>
    updatePreferences(
        id: string,
        updates: UpdatePreferencesPayload
    ): Promise<void>
}

enum ACTION {
    SET,
    UPDATE,
}

interface Action {
    type: ACTION
    payload: Preferences | Partial<Preferences>
}

interface State {
    preferences: Preferences | undefined
}

const initialState: State = {
    preferences: undefined,
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.SET: {
            return { ...state, preferences: payload as Preferences }
        }
        case ACTION.UPDATE: {
            if (!state.preferences) return state
            const updatedPreference = { ...state.preferences, ...payload }
            return { ...state, preferences: updatedPreference }
        }
        default:
            return state
    }
}

export const usePreferences = (userId?: string): PreferencesHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { preferences } = state
    const {
        isPreferenceLoading,
        isPreferencesLoaded,
        isUpdatePreferenceLoading,
        getPreferencesReq,
        updatePreferencesReq,
    } = useHttpReq()

    const getPreferences = useCallback(
        async (userId: string) => {
            try {
                const preference = await getPreferencesReq(userId)
                dispatch({ type: ACTION.SET, payload: preference })
                return preference
            } catch (error: any) {
                console.error('Error fetching preference', error)
                throw error
            }
        },
        [getPreferencesReq]
    )

    const updatePreferences = useCallback(
        async (id: string, updates: UpdatePreferencesPayload) => {
            try {
                await updatePreferencesReq(id, updates)

                if (state.preferences) {
                    const updatedPreference = {
                        ...state.preferences,
                        ...updates,
                    }
                    dispatch({
                        type: ACTION.UPDATE,
                        payload: updatedPreference,
                    })
                }
            } catch (error) {
                toast.error('Error updating preference')
                throw error
            }
        },
        [updatePreferencesReq, state.preferences]
    )

    useEffect(() => {
        if (userId) {
            const fetchData = async () => {
                try {
                    await getPreferences(userId)
                } catch (error) {
                    console.error('Error fetching preference')
                }
            }
            fetchData()
        }
    }, [userId, getPreferences])

    return {
        preferences,
        isPreferenceLoading,
        isPreferencesLoaded,
        isUpdatePreferenceLoading,
        getPreferences,
        updatePreferences,
    }
}

enum REQUEST {
    GET,
    UPDATE,
}

const useHttpReq = () => {
    const { sendRequest, requestId, isLoading, hasLoaded } = useHttp()

    const getPreferencesReq = useCallback(
        async (userId: string): Promise<Preferences> =>
            sendRequest(
                preferencesService.getPreference.bind({}, userId),
                REQUEST.GET
            ),
        [sendRequest]
    )

    const updatePreferencesReq = useCallback(
        async (id: string, payload: UpdatePreferencesPayload): Promise<void> =>
            sendRequest(
                preferencesService.updatePreference.bind({}, id, payload),
                REQUEST.UPDATE
            ),
        [sendRequest]
    )

    const isPreferenceLoading = isLoading && requestId === REQUEST.GET
    const isPreferencesLoaded = hasLoaded && requestId === REQUEST.GET
    const isUpdatePreferenceLoading = isLoading && requestId === REQUEST.UPDATE

    return {
        isPreferenceLoading,
        isPreferencesLoaded,
        isUpdatePreferenceLoading,
        getPreferencesReq,
        updatePreferencesReq,
    }
}
