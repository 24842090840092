import { Dialog, Transition } from '@headlessui/react'
import { MicrophoneIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { Fragment } from 'react'
import styled from 'styled-components'

import { useAppContext } from '../../hooks/useAppContext'
import { Button } from './Button'
import { MicrophonesListBox } from './MicrophonesListBox'
import { TestMicrophoneButton } from './TestMicrophoneButton'

export interface MicrophonesModalProps {
    isOpen?: boolean
    onClose?(): void
}

const Styles = styled.div`
    button {
        min-height: unset;
        padding: 6px 10px;
    }

    div {
        .relative {
            width: 100% !important;
            ul {
                width: 100% !important;
            }
        }
    }
`

export const MicrophonesModal = ({
    isOpen,
    onClose,
}: MicrophonesModalProps) => {
    const { preferredMicrophoneDeviceId, setPreferredMicrophoneDeviceId } =
        useAppContext().appSettings

    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Styles className="fixed inset-0 z-10 w-screen overflow-y-visible">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                <div className="flex">
                                    <MicrophoneIcon className="h-10 w-10 text-primary mr-4" />

                                    <div className="flex-1">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900 mb-2"
                                        >
                                            Select Microphone
                                        </Dialog.Title>

                                        <MicrophonesListBox
                                            deviceId={
                                                preferredMicrophoneDeviceId ??
                                                undefined
                                            }
                                            onChange={
                                                setPreferredMicrophoneDeviceId
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-end mt-8">
                                    <TestMicrophoneButton
                                        key={preferredMicrophoneDeviceId}
                                        deviceId={
                                            preferredMicrophoneDeviceId ??
                                            undefined
                                        }
                                    />
                                    <Button
                                        intent="text"
                                        className={classNames('text-sm ml-3')}
                                        onClick={onClose}
                                        label="Close"
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Styles>
            </Dialog>
        </Transition.Root>
    )
}
