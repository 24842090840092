import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { Language } from '../constants'
import { AppPlanId } from '../services/models/Subscription.model'
import { Visit } from '../services/models/Visit.model'

const BASE_HREF = ''

enum ROUTE {
    MAIN = '/',
    AUTHCHECK = '/auth',
    SIGNIN = '/auth/signin',
    SIGNUP = '/auth/signup',
    SIGNUP_COMPLETION = '/auth/signup/complete/:code?',
    FORGOT_PASSWORD = '/auth/forgot-password',
    RESET_PASSWORD = '/auth/reset-password/:code',
    VISITS = '/visits',
    VISIT = '/visits/:id',
    VISIT_START = '/visits/start',
    VISIT_RECORD = '/visits/:id/record',
    SETTINGS = '/settings',
    BILLING = '/settings/billing',
    BILLING_UPDATE = '/settings/billing/update',
    BILLING_CHECKOUT = '/settings/billing/checkout/:appPlanId',
    ACCOUNT = '/settings/account',
    DEVELOPER = '/settings/developer',
    REQUEST_FEATURE = '/settings/request-feature',
    UNAUTHORIZED = '/unauthorized',
}

const authCheckRoute = `${BASE_HREF}${ROUTE.AUTHCHECK}`
const signInRoute = `${BASE_HREF}${ROUTE.SIGNIN}`
const signUpRoute = `${BASE_HREF}${ROUTE.SIGNUP}`
const signUpCompletionRoute = `${BASE_HREF}${ROUTE.SIGNUP_COMPLETION}`
const forgotPasswordRoute = `${BASE_HREF}${ROUTE.FORGOT_PASSWORD}`
const resetPasswordRoute = `${BASE_HREF}${ROUTE.RESET_PASSWORD}`
const mainRoute = `${BASE_HREF}${ROUTE.MAIN}`
const visitsRoute = `${BASE_HREF}${ROUTE.VISITS}`
const visitRoute = `${BASE_HREF}${ROUTE.VISIT}`
const visitStartRoute = `${BASE_HREF}${ROUTE.VISIT_START}`
const visitRecordRoute = `${BASE_HREF}${ROUTE.VISIT_RECORD}`
const settingsRoute = `${BASE_HREF}${ROUTE.SETTINGS}`
const billingRoute = `${BASE_HREF}${ROUTE.BILLING}`
const billingUpdateRoute = `${BASE_HREF}${ROUTE.BILLING_UPDATE}`
const billingCheckoutRoute = `${BASE_HREF}${ROUTE.BILLING_CHECKOUT}`
const accountRoute = `${BASE_HREF}${ROUTE.ACCOUNT}`
const developerRoute = `${BASE_HREF}${ROUTE.DEVELOPER}`
const requestFeatureRoute = `${BASE_HREF}${ROUTE.REQUEST_FEATURE}`
const unauthorizedRoute = `${BASE_HREF}${ROUTE.UNAUTHORIZED}`

const publicRoutes = [
    ROUTE.SIGNIN,
    ROUTE.SIGNUP,
    (() => ROUTE.SIGNUP_COMPLETION.replace(':code?', ''))(),
    ROUTE.FORGOT_PASSWORD,
    (() => ROUTE.RESET_PASSWORD.replace(':code', ''))(),
]

export const useRoute = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isProtectedRoute = useMemo(
        () =>
            publicRoutes.find((route) => pathname.startsWith(route))
                ? false
                : true,
        [pathname]
    )

    const navAuthCheck = useCallback(() => navigate(authCheckRoute), [navigate])
    const navSignIn = useCallback(
        (state: any) => navigate(signInRoute, { state }),
        [navigate]
    )
    const navSignUp = useCallback(() => navigate(signUpRoute), [navigate])
    const navSignUpCompletion = useCallback(
        (code: string = '', state: any) => {
            const route = signUpCompletionRoute.replace(':code?', code)
            navigate(route, { state })
        },
        [navigate]
    )
    const navForgotPassword = useCallback(
        () => navigate(forgotPasswordRoute),
        [navigate]
    )
    const navResetPassword = useCallback(
        (code: string) => {
            const route = resetPasswordRoute.replace(':code', code)
            navigate(route)
        },
        [navigate]
    )
    const navMain = useCallback(() => navigate(mainRoute), [navigate])
    const navVisits = useCallback(() => navigate(visitsRoute), [navigate])
    const navVisit = useCallback(
        (id: string, visit?: Visit) => {
            const route = visitRoute.replace(':id', id)
            navigate(route, {
                state: {
                    visit,
                },
            })
        },
        [navigate]
    )
    const navVisitStart = useCallback(
        () => navigate(visitStartRoute),
        [navigate]
    )
    const navVisitRecord = useCallback(
        (
            id: string,
            visit?: Visit,
            startRecordingOnLoad?: boolean,
            templateId?: string,
            languageSource?: Language,
            languageTarget?: Language
        ) => {
            const route = visitRecordRoute.replace(':id', id)
            navigate(route, {
                state: {
                    visit,
                    startRecordingOnLoad,
                    templateId,
                    languageSource,
                    languageTarget,
                },
            })
        },
        [navigate]
    )
    const navSettings = useCallback(() => navigate(settingsRoute), [navigate])
    const navBilling = useCallback(() => navigate(billingRoute), [navigate])
    const navBillingUpdate = useCallback(
        () => navigate(billingUpdateRoute),
        [navigate]
    )
    const navBillingCheckout = useCallback(
        (appPlanId: AppPlanId) =>
            navigate(billingCheckoutRoute.replace(':appPlanId', appPlanId)),
        [navigate]
    )
    const navAccount = useCallback(() => navigate(accountRoute), [navigate])
    const navDeveloper = useCallback(() => navigate(developerRoute), [navigate])
    const navRequestFeature = useCallback(
        () => navigate(requestFeatureRoute),
        [navigate]
    )
    const navUnauthorized = useCallback(
        () => navigate(unauthorizedRoute),
        [navigate]
    )

    return {
        authCheckRoute,
        signInRoute,
        signUpRoute,
        signUpCompletionRoute,
        forgotPasswordRoute,
        resetPasswordRoute,
        mainRoute,
        visitsRoute,
        visitRoute,
        visitStartRoute,
        visitRecordRoute,
        settingsRoute,
        billingRoute,
        billingUpdateRoute,
        billingCheckoutRoute,
        accountRoute,
        developerRoute,
        requestFeatureRoute,
        unauthorizedRoute,
        isProtectedRoute,
        navAuthCheck,
        navSignIn,
        navSignUp,
        navSignUpCompletion,
        navForgotPassword,
        navResetPassword,
        navMain,
        navVisits,
        navVisit,
        navVisitStart,
        navVisitRecord,
        navSettings,
        navAccount,
        navDeveloper,
        navRequestFeature,
        navBilling,
        navBillingUpdate,
        navBillingCheckout,
        navUnauthorized,
    }
}
