import { Outlet } from 'react-router'

import { Navigation } from './components/Navigation'
import { LayoutStyles, OutletWrapper } from './styles'

export const Settings = () => {
    return (
        <LayoutStyles>
            <Navigation />
            <OutletWrapper>
                <Outlet />
            </OutletWrapper>
        </LayoutStyles>
    )
}
