import classNames from 'classnames'
import { ReactNode, useCallback } from 'react'

import { useHttp } from '../../hooks/useHttp'
import { useRoute } from '../../hooks/useRoute'
import { storage } from '../../lib/storage'
import { userService } from '../../services/http/user.service'

interface Props {
    className?: string
    childNode(isLoading: boolean): ReactNode
}

export const SignOut = ({ className, childNode }: Props) => {
    const { navSignIn } = useRoute()
    const { isLoading, signOutReq } = useHttpReq()

    const onClick = useCallback(() => {
        signOutReq(() => {
            return {
                navSignIn,
            }
        })
    }, [signOutReq, navSignIn])

    return (
        <div
            className={classNames(className, 'inline-block')}
            onClick={onClick}
        >
            {childNode(isLoading)}
        </div>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const signOutReq = useCallback(
        async (funcRefs: any): Promise<void> =>
            sendRequest(userService.signOut)
                .then(() => {
                    funcRefs().navSignIn()
                    storage.clearAccessToken()
                    storage.clearEmrToken()
                })
                .catch(() => {
                    funcRefs().navSignIn()
                }),
        [sendRequest]
    )

    return {
        isLoading,
        signOutReq,
    }
}
