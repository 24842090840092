import classNames from 'classnames'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useAppContext } from '../../../../hooks/useAppContext'
import { TemplatesListBox } from '../../../UI/TemplatesListBox'

interface Props {
    className?: string
}

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;

    ul {
        width: 280px;
    }
`

const SelectDefaultTemplate = ({ className }: Props) => {
    const { areTemplatesLoading } = useAppContext().templates
    const { preferences, isPreferenceLoading, updatePreferences } =
        useAppContext().preferences
    const [templateId, setTemplateId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (templateId) {
            return
        }
        if (preferences?.defaultTemplateId) {
            setTemplateId(preferences.defaultTemplateId)
        }
    }, [templateId, preferences?.defaultTemplateId])

    const onChange = useCallback(
        (_templateId: string) => {
            if (!preferences?._id) {
                return
            }
            setTemplateId(_templateId)
            updatePreferences(preferences._id, {
                defaultTemplateId: _templateId,
            }).catch((error) => {
                console.error(error)
                toast.error('Unable to update preferences')
            })
        },
        [preferences?._id, updatePreferences]
    )

    if (areTemplatesLoading || isPreferenceLoading || !templateId) {
        return <Styles className="text-sm">Loading preferences...</Styles>
    }

    return (
        <Styles className={classNames(className)}>
            <div className="block text-sm font-medium mb-1 leading-6 text-gray-900">
                Default Template for new recordings
            </div>
            <TemplatesListBox templateId={templateId} onChange={onChange} />
        </Styles>
    )
}

export default SelectDefaultTemplate
