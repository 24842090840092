import { useCallback, useEffect, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { paymentsService } from '../../../services/http/payments.service'
import { BillingUsage } from '../../../services/models/Subscription.model'

export type BillingUsagesHookState = {
    billingUsages: BillingUsage | null
    isLoading: boolean
    getBillingUsages(): Promise<void>
    increaseVisitsCreated(): void
}

export const useBillingUsages = (
    userId: string | undefined
): BillingUsagesHookState => {
    const { isLoading, getReq } = useHttpReq()
    const [billingUsages, setBillingUsages] = useState<BillingUsage | null>(
        null
    )
    const hasFetchedRef = useRef<boolean>(false)

    const getBillingUsages = useCallback(() => {
        if (!userId) {
            return Promise.resolve()
        }
        return getReq(userId)
            .then(setBillingUsages)
            .catch((error) => console.error(error))
    }, [userId, getReq])

    useEffect(() => {
        if (hasFetchedRef.current || !userId) {
            return
        }
        getBillingUsages()
        hasFetchedRef.current = true
    }, [userId, getBillingUsages])

    const increaseVisitsCreated = useCallback(() => {
        setBillingUsages((prev) => {
            if (!prev) {
                return null
            }
            return {
                ...prev,
                visitsCreated: prev.visitsCreated + 1,
            }
        })
    }, [])

    return {
        billingUsages,
        isLoading,
        getBillingUsages,
        increaseVisitsCreated,
    }
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getReq = useCallback(
        (userId: string): Promise<BillingUsage | null> =>
            sendRequest(paymentsService.getBillingUsages.bind({}, userId)),
        [sendRequest]
    )

    return {
        isLoading,
        getReq,
    }
}
