import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Fragment, ReactNode } from 'react'
import styled from 'styled-components'

import { Button, ButtonIntent } from './Button'

export interface ConfirmationModalProps {
    isOpen?: boolean
    iconNode?: ReactNode
    titleNode: string | ReactNode
    bodyNode: string | ReactNode
    confirmLabel?: string
    confirmClassName?: string
    confirmIntent?: ButtonIntent
    cancelLabel?: string
    cancelClassName?: string
    cancelIntent?: ButtonIntent
    onClose?(): void
    onConfirm?(): void
    onCancel?(): void
}

const Styles = styled.div`
    button {
        min-height: unset;
        padding: 6px 10px;
    }
`

export const ConfirmationModal = ({
    isOpen,
    iconNode,
    titleNode,
    bodyNode,
    confirmLabel,
    confirmClassName,
    confirmIntent,
    cancelLabel,
    cancelClassName,
    cancelIntent,
    onConfirm,
    onCancel,
    onClose,
}: ConfirmationModalProps) => {
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={onClose!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <Styles className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                <div className="flex">
                                    {iconNode ?? null}

                                    <div>
                                        <Dialog.Title
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            {titleNode}
                                        </Dialog.Title>

                                        <Dialog.Description className="text-gray-500 mt-2">
                                            {bodyNode}
                                        </Dialog.Description>
                                    </div>
                                </div>

                                <div className="flex justify-end mt-8">
                                    <Button
                                        intent={confirmIntent ?? 'text'}
                                        className={classNames(
                                            cancelClassName,
                                            'text-sm'
                                        )}
                                        onClick={() => {
                                            onClose!()
                                            if (onCancel) {
                                                onCancel()
                                            }
                                        }}
                                        label={cancelLabel || 'Cancel'}
                                    />
                                    <Button
                                        intent={cancelIntent || 'primary'}
                                        className={classNames(
                                            confirmClassName,
                                            'text-sm ml-3'
                                        )}
                                        onClick={() => {
                                            onClose!()
                                            if (onConfirm) {
                                                onConfirm()
                                            }
                                        }}
                                        label={confirmLabel || 'Confirm'}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Styles>
            </Dialog>
        </Transition.Root>
    )
}
