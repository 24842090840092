import { useCallback } from 'react'
import styled from 'styled-components'

import { Button } from '../../../UI/Button'
import { Input } from '../../../UI/Input'
import { useForm } from './useForm'

const UpdateEmailFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;

    .update-email-input {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 0.5rem;
        }
    }
`

const VerificationCodeWrapper = styled.div`
    display: flex;
    flex-direction: column;

    input {
        max-width: 210px;
    }
`

const ResendCodeMessage = styled.p`
    margin-top: 0.5rem;
    font-size: 0.7rem;
    color: #6b7280;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
    margin-top: 1.5rem;
`

export const UpdateEmailForm = () => {
    const {
        form,
        isLoading,
        isVerificationStage,
        updateField,
        validateField,
        updateEmail,
        cancelUpdateEmail,
        resendCode,
        verifyEmail,
        emailToVerify,
    } = useForm()

    const onSubmit = useCallback(async () => {
        if (isVerificationStage) {
            verifyEmail()
        } else {
            updateEmail()
        }
    }, [updateEmail, verifyEmail, isVerificationStage])

    const onCancel = useCallback(async () => {
        cancelUpdateEmail()
    }, [cancelUpdateEmail])

    const onResend = useCallback(async () => {
        resendCode()
    }, [resendCode])

    const isButtonDisabled = isVerificationStage
        ? !form.verificationCode.value || Boolean(form.verificationCode.error)
        : !form.email.value || Boolean(form.email.error)

    return (
        <UpdateEmailFormWrapper>
            {!isVerificationStage ? (
                // Email input stage
                <Input
                    className="update-email-input"
                    name="email"
                    initialValue={''}
                    onChange={(value: string) => updateField('email', value)}
                    onBlur={() => validateField('email')}
                    labelNode="Email"
                    placeholder="Enter your new email address"
                    error={form.email.error}
                />
            ) : (
                // Verification code input stage
                <VerificationCodeWrapper>
                    <Input
                        type="number"
                        className="update-email-input mt-3"
                        labelNode={
                            <p>
                                A verification code has been sent to{' '}
                                <strong>{emailToVerify}</strong>.
                            </p>
                        }
                        name="verificationCode"
                        initialValue={form.verificationCode.value as string}
                        onChange={(value: string) =>
                            updateField('verificationCode', value)
                        }
                        onBlur={() => validateField('verificationCode')}
                        placeholder="Enter Code"
                        error={form.verificationCode.error}
                    />

                    <ResendCodeMessage>
                        Didn&apos;t receive the code?{' '}
                        <a
                            href="#"
                            onClick={onResend}
                            style={{ color: 'blue' }}
                        >
                            Resend it
                        </a>
                        .
                    </ResendCodeMessage>
                </VerificationCodeWrapper>
            )}
            <ActionWrapper>
                {isVerificationStage && (
                    <Button
                        intent="danger"
                        label="Cancel"
                        onClick={onCancel}
                        isLoading={isLoading}
                    />
                )}
                <Button
                    intent="text"
                    label={isVerificationStage ? 'Confirm' : 'Update'}
                    onClick={onSubmit}
                    isLoading={isLoading}
                    disabled={isButtonDisabled}
                />
            </ActionWrapper>
        </UpdateEmailFormWrapper>
    )
}
