import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useHttp } from '../../../hooks/useHttp'
import { paymentsService } from '../../../services/http/payments.service'
import {
    Plan,
    Price,
    Subscription,
} from '../../../services/models/Subscription.model'
import { User } from '../../../services/models/User.model'

export type SubscriptionHookState = {
    subscription: Subscription | undefined | null
    currentPlan: Plan | undefined
    currentPrice: Price | undefined
    isSubscribed: boolean | undefined
    isLoading: boolean
    createSubscription(
        currentSubscriptionId: string,
        priceId: string,
        promotionCodeId?: string
    ): Promise<void>
    updateSubscription(priceId: string): Promise<void>
    toggleSubscription(
        subscriptionId: string,
        cancelAtPeriodEnd: boolean
    ): Promise<void>
}

export const useSubscription = (
    user: User | undefined | null,
    getPlan: (id: string) => Plan | undefined,
    getPrice: (plan?: Plan) => Price | undefined,
    getBillingUsages: () => Promise<void>
): SubscriptionHookState => {
    const {
        isLoading,
        getSubscriptionReq,
        createSubscriptionReq,
        updateSubscriptionReq,
        toggleSubscriptionReq,
    } = useHttpReq()
    const [subscription, setSubscription] = useState<
        Subscription | undefined | null
    >(undefined)
    const hasFetchedRef = useRef<boolean>(false)

    const isSubscribed = useMemo(
        () => Subscription.isSubscribed(subscription),
        [subscription]
    )

    const currentPlan = useMemo((): Plan | undefined => {
        if (!isSubscribed || !subscription) {
            return undefined
        }
        const planId = Subscription.getPlanId(subscription)
        const plan = planId ? getPlan(planId) : undefined
        return plan
    }, [isSubscribed, subscription, getPlan])

    const currentPrice = useMemo((): Price | undefined => {
        if (!currentPlan) {
            return undefined
        }
        return getPrice(currentPlan)
    }, [currentPlan, getPrice])

    const createSubscription = useCallback(
        async (
            currentSubscriptionId: string,
            priceId: string,
            promotionCodeId?: string
        ) => {
            if (!user?.stripeCustomerId) {
                return Promise.reject('stripeCustomerId is missing')
            }

            return createSubscriptionReq(
                user.stripeCustomerId,
                currentSubscriptionId,
                priceId,
                promotionCodeId
            )
                .then((_subscrition) => {
                    setSubscription(_subscrition)

                    // Wait for the subscription to be updated in backend by stripe webhook
                    setTimeout(() => {
                        getBillingUsages()
                    }, 1000)
                })
                .catch((error) => console.error(error))
        },
        [user?.stripeCustomerId, createSubscriptionReq, getBillingUsages]
    )

    const updateSubscription = useCallback(
        async (priceId: string) => {
            if (!user?.stripeCustomerId) {
                return Promise.reject('stripeCustomerId is missing')
            }

            return updateSubscriptionReq(user.stripeCustomerId, priceId)
                .then((_subscrition) => {
                    setSubscription(_subscrition)

                    // Wait for the subscription to be updated in backend by stripe webhook
                    setTimeout(() => {
                        getBillingUsages()
                    }, 1000)
                })
                .catch((error) => console.error(error))
        },
        [user?.stripeCustomerId, updateSubscriptionReq, getBillingUsages]
    )

    const toggleSubscription = useCallback(
        (subscriptionId: string, cancelAtPeriodEnd: boolean) => {
            return toggleSubscriptionReq(subscriptionId, cancelAtPeriodEnd)
                .then(setSubscription)
                .catch((error) => console.error(error))
        },
        [toggleSubscriptionReq]
    )

    useEffect(() => {
        if (hasFetchedRef.current || !user?.stripeCustomerId) {
            return
        }
        getSubscriptionReq(user.stripeCustomerId)
            .then(setSubscription)
            .catch((error) => console.error(error))
        hasFetchedRef.current = true
    }, [getSubscriptionReq, user?.stripeCustomerId])

    return {
        subscription,
        currentPlan,
        currentPrice,
        isSubscribed,
        isLoading,
        createSubscription,
        updateSubscription,
        toggleSubscription,
    }
}

const useHttpReq = () => {
    const { isLoading, sendRequest } = useHttp()

    const getSubscriptionReq = useCallback(
        (stripeCustomerId: string): Promise<Subscription> =>
            sendRequest(
                paymentsService.getSubscription.bind({}, stripeCustomerId)
            ),
        [sendRequest]
    )

    const createSubscriptionReq = useCallback(
        (
            stripeCustomerId: string,
            currentSubscriptionId: string,
            priceId: string,
            promotionCodeId?: string
        ): Promise<Subscription | null> =>
            sendRequest(
                paymentsService.createSubscription.bind(
                    {},
                    stripeCustomerId,
                    currentSubscriptionId,
                    priceId,
                    promotionCodeId
                )
            ),
        [sendRequest]
    )

    const updateSubscriptionReq = useCallback(
        (
            stripeCustomerId: string,
            priceId: string
        ): Promise<Subscription | null> =>
            sendRequest(
                paymentsService.updateSubscription.bind(
                    {},
                    stripeCustomerId,
                    priceId
                )
            ),
        [sendRequest]
    )

    const toggleSubscriptionReq = useCallback(
        (
            subscriptionId: string,
            cancelAtPeriodEnd: boolean
        ): Promise<Subscription | null> =>
            sendRequest(
                paymentsService.toggleSubscription.bind(
                    {},
                    subscriptionId,
                    cancelAtPeriodEnd
                )
            ),
        [sendRequest]
    )

    return {
        isLoading,
        getSubscriptionReq,
        createSubscriptionReq,
        updateSubscriptionReq,
        toggleSubscriptionReq,
    }
}
