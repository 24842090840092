interface Navigator {
    wakeLock: WakeLock
}

interface WakeLock {
    request(type: WakeLockType): Promise<WakeLockSentinel>
}

type WakeLockType = 'screen'

interface WakeLockSentinel {
    released: boolean
    release(): Promise<void>
}

export const screenLock = (() => {
    let wakeLock: WakeLockSentinel | null = null

    const lock = async () => {
        if (!('wakeLock' in navigator)) {
            console.error('Wake Lock API not supported.')
            return
        }

        try {
            wakeLock = await (navigator as Navigator).wakeLock.request('screen')
        } catch (e) {
            console.error('Failed to request screen lock:', e)
        }
    }

    const unlock = async () => {
        if (!wakeLock) {
            return
        }

        try {
            await wakeLock?.release()
            wakeLock = null
        } catch (e) {
            console.error('Failed to release screen lock:', e)
        }
    }

    return {
        lock,
        unlock,
    }
})()
