import moment from 'moment'

export class Notepad {
    _id!: string
    owner!: string
    visitId!: string
    content!: string
    createdAt!: Date
    updatedAt!: Date

    static deserialize(obj: any): Notepad {
        const model: Notepad = Object.assign({}, obj)
        model.createdAt = new Date(obj.createdAt)
        model.updatedAt = new Date(obj.updatedAt)
        return model
    }

    static getFormattedDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
}
