import { AxiosResponse } from 'axios'

import { Notepad } from '../models/Notepad.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getNotepad = async (visitId: string): Promise<Notepad | null> => {
    const url = `${baseUrl}/notepads?visitId=${visitId}`
    return authAxios.get(url).then((response: AxiosResponse) => {
        const notepad = response.data?.notepad
            ? Notepad.deserialize(response.data?.notepad)
            : null
        return notepad
    })
}

const createNotepad = async (
    userId: string,
    visitId: string,
    content: string
): Promise<Notepad | null> => {
    const url = `${baseUrl}/notepads`
    const payload = {
        userId,
        visitId,
        content,
    }
    return authAxios.post(url, payload).then((response) => {
        const notepad = response.data?.notepad
            ? Notepad.deserialize(response.data?.notepad)
            : null
        return notepad
    })
}

const updateNotepad = (
    id: string,
    updates: Partial<Notepad>
): Promise<void> => {
    const url = `${baseUrl}/notepads/${id}`
    const payload = {
        ...updates,
    }
    return authAxios.put(url, payload)
}

export const notepadsService = {
    getNotepad,
    createNotepad,
    updateNotepad,
}
