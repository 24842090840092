import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { useState } from 'react'
import styled from 'styled-components'

import { Input } from '../../../UI/Input'
import { PasswordStrengthRating } from '../../../UI/PasswordStrengthRating'
import { FormHookState } from './useForm'

interface Props {
    className?: string
    formHookState: FormHookState
    field: string
    label: string
    showPasswordStrength?: boolean
}

const Styles = styled.div`
    .show-password-trigger {
        position: relative;
        z-index: 1;
    }

    .password-input {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 0.5rem;
        }
    }
`

export const PasswordField = ({
    className,
    formHookState,
    field,
    label,
    showPasswordStrength = false,
}: Props) => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { form, updateField, validateField, checkPasswordStrength } =
        formHookState
    const passwordField = form[field]

    if (!passwordField) {
        return null
    }

    return (
        <Styles>
            <Input
                name={field}
                className={'password-input ' + classNames(className)}
                type={showPassword ? 'text' : 'password'}
                initialValue={passwordField.value as string}
                onChange={(value: string) => updateField(field, value)}
                onBlur={() => validateField(field)}
                labelNode={label}
                rightNode={
                    showPassword ? (
                        <EyeIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(false)}
                        />
                    ) : (
                        <EyeSlashIcon
                            className="h-5 w-5 cursor-pointer text-gray-400"
                            onClick={() => setShowPassword(true)}
                        />
                    )
                }
                error={passwordField.error}
            />
            {showPasswordStrength && (
                <PasswordStrengthRating
                    className="mt-2"
                    rating={checkPasswordStrength(
                        passwordField.value as string
                    )}
                />
            )}
        </Styles>
    )
}
