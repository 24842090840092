import { createContext, useContext } from 'react'

import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { BillingUsagesHookState, useBillingUsages } from './_useBillingUsages'
import { InvoicesHookState, useInvoices } from './_useInvoices'
import { PaymentMethodHookState, usePaymentMethod } from './_usePaymentMethod'
import type { PlansHookState } from './_usePlans'
import { usePlans } from './_usePlans'
import { SubscriptionHookState, useSubscription } from './_useSubscription'

interface ContextState {
    plans: PlansHookState
    subscription: SubscriptionHookState
    paymentMethod: PaymentMethodHookState
    invoices: InvoicesHookState
    billingUsages: BillingUsagesHookState
}

const BillingContext = createContext<ContextState>(null!)

// Provider
export function BillingContextProvider({ children }: any) {
    const { user } = useAuthContext().user
    const userId = user?._id
    const stripeCustomerId = user?.stripeCustomerId

    const planHookState = usePlans(user)
    const billingUsagesHookState = useBillingUsages(userId)
    const subscriptionHookState = useSubscription(
        user,
        planHookState.getPlan,
        planHookState.getPrice,
        billingUsagesHookState.getBillingUsages
    )
    const paymentMethodHookState = usePaymentMethod(stripeCustomerId)
    const invoicesHookState = useInvoices(stripeCustomerId)

    return (
        <BillingContext.Provider
            value={{
                plans: planHookState,
                subscription: subscriptionHookState,
                paymentMethod: paymentMethodHookState,
                invoices: invoicesHookState,
                billingUsages: billingUsagesHookState,
            }}
        >
            {children}
        </BillingContext.Provider>
    )
}

// Consumer
export function useBillingContext() {
    return useContext(BillingContext)
}
