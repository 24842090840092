import { AxiosResponse } from 'axios'

import { Visit } from '../models/Visit.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getVisits = (userId: string): Promise<Visit[]> => {
    const url = `${baseUrl}/visits?userId=${userId}`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                const visits =
                    response.data?.visits?.map((obj: any) =>
                        Visit.deserialize(obj)
                    ) || []
                resolve(visits)
            })
            .catch((error) => reject(error.response?.data || {}))
    })
}

const createVisit = (userId: string): Promise<Visit> => {
    const url = `${baseUrl}/visits?userId=${userId}`
    return new Promise((resolve, reject) => {
        authAxios
            .post(url)
            .then((response: AxiosResponse) => {
                if (response.data.visit) {
                    const visit = Visit.deserialize(response.data?.visit)
                    resolve(visit)
                } else {
                    reject('Failed to create visit')
                }
            })
            .catch((error) => reject(error.response?.data || {}))
    })
}

const deleteVisit = (id: string): Promise<void> => {
    const url = `${baseUrl}/visits?id=${id}`
    return authAxios.delete(url)
}

const deleteVisits = (ids: string[]): Promise<void> => {
    const url = `${baseUrl}/visits/delete`
    const payload = { ids }
    return authAxios.post(url, payload)
}

export const visitsService = {
    getVisits,
    createVisit,
    deleteVisit,
    deleteVisits,
}
