import { AxiosResponse } from 'axios'
import { Observable } from 'rxjs'

import { Credentials } from '../../lib/AwsTranscriber'
import { storage } from '../../lib/storage'
import { Transcript } from '../models/Visit.model'
import { utilsService } from '../utils.service'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getCredentials = (visitId: string): Promise<Credentials> => {
    const url = `${baseUrl}/transcriptions/credentials?visitId=${visitId}`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                const data = response.data?.credentials
                if (data) {
                    const credentials: Credentials = {
                        accessKeyId: data.AccessKeyId,
                        secretAccessKey: data.SecretAccessKey,
                        sessionToken: data.SessionToken,
                    }
                    resolve(credentials)
                } else {
                    reject('Failed to get credentials')
                }
            })
            .catch((error) => reject(error))
    })
}

const getTranscriptions = (
    visitId: string
): {
    eventSource: EventSource
    observable: Observable<Transcript>
} => {
    const emrToken = storage.getEmrToken()
    const token = storage.getAccessToken()
    let url = `${baseUrl}/transcriptions?visitId=${visitId}`
    if (emrToken) {
        url = `${url}&emrToken=${emrToken}`
    } else if (token) {
        url = `${url}&token=${token}`
    }
    const eventSource = new EventSource(url)

    return {
        eventSource,
        observable: new Observable((observer) => {
            eventSource.onmessage = (event) => {
                const decoded = utilsService.fromBinary(event.data)
                let data = decoded

                // Check if the data is a Note by attempting to JSON parse it
                try {
                    data = JSON.parse(decoded)

                    // If the data is a Note, close the event source
                    if ((data as any).endOfStream) {
                        observer.complete()
                        eventSource.close()
                    } else {
                        if (!(data as any).ping) {
                            observer.next(data as any as Transcript)
                        }
                    }

                    // If the data is not a Note, pass it along as a partial note content
                } catch (error) {
                    observer.complete()
                    eventSource.close()
                }

                eventSource.onerror = (error) => {
                    observer.error(error)
                    eventSource.close()
                }
            }
        }),
    }
}

const createTranscript = (
    visitId: string,
    transcript: Transcript,
    duration: number
): Promise<void> => {
    const url = `${baseUrl}/transcriptions?visitId=${visitId}`
    const payload = {
        transcript,
        duration,
    }
    return authAxios.post(url, payload)
}

export const transcriptionsService = {
    getCredentials,
    getTranscriptions,
    createTranscript,
}
