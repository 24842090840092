import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useAuthContext } from '../components/Authentication/hooks/useAuthContext'
import { LayoutStyles } from '../components/Authentication/styles'
import { Loader } from '../components/UI/Loader'
import { useRoute } from '../hooks/useRoute'

export const AuthCheckPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { signInRoute } = useRoute()
    const from =
        `${(location.state as any)?.from?.pathname ?? ''}${
            (location.state as any)?.from?.search ?? ''
        }` || '/'

    const { isAuthenticated, isLoading, hasLoaded } = useAuthContext().user

    // Check user authentication on load
    useEffect(() => {
        if (isLoading) {
            return
        }

        // Else navigate to original route that is auth-protected
        else if (isAuthenticated) {
            navigate(from, { replace: true })
        }
    }, [isAuthenticated, from, isLoading, navigate])

    // If user is unable to be fetched, then navigate to signin page
    if (!isAuthenticated && hasLoaded) {
        return <Navigate to={signInRoute} replace />
    }

    return (
        <LayoutStyles>
            <Loader className="m-3" />
        </LayoutStyles>
    )
}
