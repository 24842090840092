import { ConfirmationModalProps } from '../../components/UI/ConfirmationModal'
import { MicrophonesModalProps } from '../../components/UI/MicrophonesModal'
import { Transcript } from '../models/Visit.model'
import { VisitNote } from '../models/VisitNote.model'
import { eventBus } from './eventBus.service'

export class VisitCreated {
    static action = 'VisitCreated'
    static emit = () => emit(VisitCreated.action)
}

export class VisitNoteProcessing {
    static action = 'VisitNoteProcessing'
    static emit = (visitId: string) => emit(VisitNoteProcessing.action, visitId)
}

export class VisitNoteProcessed {
    static action = 'VisitNoteProcessed'
    static emit = (visitNote: VisitNote) =>
        emit(VisitNoteProcessed.action, visitNote)
}

export class TranscribedContent {
    static action = 'TranscribedContent'
    static emit = (payload: { visitId: string; transcript: Transcript }) =>
        emit(TranscribedContent.action, payload)
}

export class VolumeChanged {
    static action = 'VolumeChanged'
    static emit = (payload: { volume: number }) =>
        emit(VolumeChanged.action, payload)
}

export class UpdateMediaDeviceState {
    static action = 'UpdateMediaDeviceState'
    static emit = (payload: { isAvailable: boolean }) =>
        emit(UpdateMediaDeviceState.action, payload)
}

export class OpenConfirmationModal {
    static action = 'OpenConfirmationModal'
    static emit = (payload: ConfirmationModalProps) =>
        emit(OpenConfirmationModal.action, payload)
}

export class OpenMicrophonesModal {
    static action = 'OpenMicrophonesModal'
    static emit = (payload: MicrophonesModalProps) =>
        emit(OpenMicrophonesModal.action, payload)
}

const emit = (action: string, payload?: any) => {
    eventBus.emit({
        action,
        payload,
    })
}
