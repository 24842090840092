import { Menu } from '@headlessui/react'
import { ArrowUpCircleIcon, SparklesIcon } from '@heroicons/react/24/solid'
import { useCallback, useMemo, useReducer } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

import useVisitNotesContext from '../../../../../hooks/useVisitNotesContext'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../../../lib/field'
import { Button } from '../../../../UI/Button'
import { Input } from '../../../../UI/Input'
import { useVisitsContext } from '../../../../Visits/hooks/useVisitsContext'
import { SuggestedUserCommandsMenu } from './SuggestedUserCommandsMenu'

const Styles = styled.div`
    .input-wrapper {
        input {
            padding: 16px 100px 16px 16px;
        }

        & > div {
            padding-right: 10px;
        }
    }

    button {
        min-width: unset;
        padding: 6px 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
            padding: 0;
            position: inherit;
        }
    }
`

export const UserCommand = () => {
    const { id: visitId } = useParams()
    const { getVisit } = useVisitsContext().visits

    const visit = useMemo(
        () => (visitId ? getVisit(visitId) : undefined),
        [visitId, getVisit]
    )

    const { generateVisitNote, isProcessing } =
        useVisitNotesContext().visitNotes
    const { form, updateField, validateForm } = useForm()

    const onSubmit = useCallback(
        (event?: any) => {
            event?.stopPropagation()

            if (isProcessing || !validateForm()) {
                return
            }
            updateField('command', '')
            generateVisitNote(
                'user-command',
                visit?.templateId,
                form.command.value as string
            )
        },
        [
            visit?.templateId,
            isProcessing,
            form.command.value,
            updateField,
            validateForm,
            generateVisitNote,
        ]
    )

    if (!visit) {
        return null
    }

    return (
        <Styles className="relative">
            <Input
                name="user-command"
                placeholder="Enter your command..."
                initialValue={(form.command.value as string) || ''}
                error={form.command.error}
                onChange={(value) => updateField('command', value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onSubmit()
                    }
                }}
                rightNode={
                    <div className="flex items-center">
                        <SuggestedUserCommandsMenu
                            className="mr-5"
                            visitId={visit._id}
                            visitState={visit.state}
                            templateId={visit.templateId}
                        >
                            <Menu.Button
                                className="absolute right-14 bottom-2.5 flex rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800 z-40"
                                onClick={(event: any) =>
                                    event.stopPropagation()
                                }
                            >
                                <SparklesIcon className="h-6 w-6 text-gray-500" />
                            </Menu.Button>
                        </SuggestedUserCommandsMenu>
                        <Button
                            label=""
                            disabled={isProcessing}
                            onClick={onSubmit}
                            leftIconNode={
                                <ArrowUpCircleIcon className="h-6 w-6" />
                            }
                        />
                    </div>
                }
            />
        </Styles>
    )
}

class FormFields {
    command!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    command: initialTextField,
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: { fieldKey: FormFieldsKeys; payload: Field }
): FormFields => {
    return {
        ...form,
        [fieldKey]: payload as TextField,
    }
}

const useForm = () => {
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'command': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    }
                    return true
                }
                default: {
                    return true
                }
            }
        },
        [form, updateFieldError]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('command'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    return {
        form,
        updateField,
        validateField,
        validateForm,
    }
}
