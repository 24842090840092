import { ClipboardIcon } from '@heroicons/react/20/solid'
import {
    ArrowDownTrayIcon,
    ClipboardDocumentIcon,
    PencilSquareIcon,
    PrinterIcon,
    TagIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { useAppContext } from '../../../../hooks/useAppContext'
import useVisitNotesContext from '../../../../hooks/useVisitNotesContext'
import { Template } from '../../../../services/models/Template.model'
import {
    VisitNote,
    VisitNoteIntent,
} from '../../../../services/models/VisitNote.model'
import { Avatar } from '../../../UI/AppBar/Avatar'
import { Button } from '../../../UI/Button'
import { CopyNote } from '../../../UI/CopyNote'
import { DownloadNote } from '../../../UI/DownloadNote'
import { Loader } from '../../../UI/Loader'
import { NoteEditor } from '../../../UI/NoteEditor'
import { PasteNoteIntoEMR } from '../../../UI/PasteNoteIntoEMR'
import { Tooltip } from '../../../UI/Tooltip'
import { NoteMenu } from './NoteMenu'

interface Props {
    className?: string
    visitId: string
}

const Styles = styled.div``

export const NotesList = ({ className, visitId }: Props) => {
    const { visitNotesGroupedByEventId, areVisitNotesLoading, getVisitNotes } =
        useVisitNotesContext().visitNotes
    const hasFetchedRef = useRef<boolean>(false)
    const [visitNotseGroups, setVisitNotesGroups] = useState<
        Map<string, VisitNote[]>
    >(new Map())
    useEffect(
        () => setVisitNotesGroups(visitNotesGroupedByEventId),
        [visitNotesGroupedByEventId]
    )

    useEffect(() => {
        if (hasFetchedRef.current || !visitId) {
            return
        }
        setVisitNotesGroups(new Map())
        getVisitNotes(visitId)
        hasFetchedRef.current = true
    }, [visitId, getVisitNotes])

    if (areVisitNotesLoading) {
        return <Styles className={classNames('flex-1', className)} />
    }

    return (
        <Styles className={classNames('flex-1', className)}>
            {Array.from(visitNotseGroups.values() || [])?.map(
                (visitNotes: VisitNote[], index) => {
                    const isFirst = index === 0
                    const isLast = index === visitNotseGroups.size - 1

                    return (
                        <div
                            key={`group_${visitNotes[0]._id}`}
                            className={classNames(
                                'mb-10'
                                // 'animate-fade-down animate-duration-500ms animate-ease-out' // Animation
                            )}
                        >
                            {visitNotes.map((visitNote) => (
                                <>
                                    {visitNote.isSystemGenerated ? (
                                        <GeneratedNote
                                            key={visitNote._id}
                                            isLast={isLast}
                                            visitNote={visitNote}
                                        />
                                    ) : !isFirst ? (
                                        <UserNote
                                            key={visitNote._id}
                                            visitNote={visitNote}
                                        />
                                    ) : null}
                                </>
                            ))}
                        </div>
                    )
                }
            )}
        </Styles>
    )
}

const UserNoteIcon = ({ intent }: { intent: VisitNoteIntent }) => {
    if (intent === 'regenerate-note') {
        return <PencilSquareIcon className="h-5 w-5 text-gray-400 mr-1" />
    } else if (intent === 'change-template') {
        return <TagIcon className="h-5 w-5 text-gray-400 mr-1" />
    }
    return null
}

const UserNote = ({ visitNote }: { visitNote: VisitNote }) => {
    const { getTemplate } = useAppContext().templates

    const template = useMemo((): Template | undefined => {
        return visitNote.templateId
            ? getTemplate(visitNote.templateId)
            : undefined
    }, [visitNote.templateId, getTemplate])

    const content = useMemo(() => {
        if (visitNote.intent === 'change-template' && template?.name) {
            return (
                <span>
                    <span>Change template to </span>
                    <span className="font-semibold">{template?.name}</span>
                </span>
            )
        }
        return visitNote.content
    }, [visitNote.intent, visitNote.content, template?.name])

    return (
        <div className="flex items-center space-between mb-4">
            <div className="flex items-center w-full">
                <AvatarMenuWrapper className="mr-2">
                    <Avatar className="h-7 w-7" />
                </AvatarMenuWrapper>
                <div
                    className={classNames(
                        'flex items-center whitespace-pre-line bg-gray-200 text-gray-500 font-medium px-2 py-1 rounded-xl'
                    )}
                >
                    <UserNoteIcon intent={visitNote.intent} />
                    {content}
                </div>
            </div>
            <NoteMenu visitNoteId={visitNote._id} />
        </div>
    )
}

const GeneratedNote = ({
    isLast,
    visitNote,
}: {
    isLast: boolean
    visitNote: VisitNote
}) => {
    return (
        <div className="flex items-center w-full mb-3">
            <div
                className={classNames(
                    'flex-1 border whitespace-pre-line bg-white pt-3 px-3 rounded-md',
                    {
                        'animate-pulse': visitNote.state === 'processing',
                    }
                )}
            >
                <NoteEditor visitNote={visitNote} />
                {!VisitNote.isProcessing(visitNote) && (
                    <ActionBar
                        className="mt-3"
                        isLast={isLast}
                        visitNote={visitNote}
                    />
                )}
            </div>
        </div>
    )
}

const ActionBar = ({
    className,
    isLast,
    visitNote,
}: {
    className?: string
    isLast: boolean
    visitNote: VisitNote
}) => {
    return (
        <div className={classNames('flex flex-wrap items-center', className)}>
            <CopyNote
                className="mr-2 mb-3"
                visitId={visitNote.visitId}
                visitNoteId={visitNote._id}
                childNode={(isLoading) => (
                    <>
                        {isLast ? (
                            <Button
                                leftIconNode={
                                    <ClipboardIcon className="h-5 w-5" />
                                }
                                label="Copy summary"
                            />
                        ) : (
                            <ButtonWrapper tooltipContent="Copy summary">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <ClipboardIcon className="h-5 w-5 text-gray-500" />
                                )}
                            </ButtonWrapper>
                        )}
                    </>
                )}
            />
            <PasteNoteIntoEMR
                className="mr-2 mb-3"
                visitId={visitNote.visitId}
                visitNoteId={visitNote._id}
                childNode={(isLoading) => (
                    <>
                        {isLast ? (
                            <Button
                                intent="primary-fade"
                                leftIconNode={
                                    <ClipboardDocumentIcon className="h-5 w-5" />
                                }
                                label="Paste into EMR"
                            />
                        ) : (
                            <ButtonWrapper tooltipContent="Paste into EMR">
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <ClipboardDocumentIcon className="h-5 w-5 text-gray-500" />
                                )}
                            </ButtonWrapper>
                        )}
                    </>
                )}
            />
            <DownloadNote
                className="mr-2 mb-3"
                visitId={visitNote.visitId}
                visitNoteId={visitNote._id}
                childNode={(isLoading) => (
                    <ButtonWrapper tooltipContent="Print">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <PrinterIcon className="h-5 w-5 text-gray-500" />
                        )}
                    </ButtonWrapper>
                )}
                print
            />
            <DownloadNote
                className="mr-2 mb-3"
                visitId={visitNote.visitId}
                visitNoteId={visitNote._id}
                childNode={(isLoading) => (
                    <ButtonWrapper tooltipContent="Export to PDF">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <ArrowDownTrayIcon className="h-5 w-5 text-gray-500" />
                        )}
                    </ButtonWrapper>
                )}
            />
        </div>
    )
}

const AvatarMenuWrapper = ({
    className,
    children,
}: {
    className?: string
    children: ReactNode
}) => {
    return (
        <div className={classNames('flex items-center', className)}>
            {children}
        </div>
    )
}

const ButtonWrapper = ({
    tooltipContent,
    children,
}: {
    tooltipContent: string
    children: ReactNode
}) => {
    return (
        <Tooltip content={tooltipContent} placement="top">
            <div className="flex items-center hover:bg-gray-100 rounded-md cursor-pointer p-2">
                {children}
            </div>
        </Tooltip>
    )
}
