import classNames from 'classnames'
import styled from 'styled-components'

import Colors from '../../colors'

interface Props {
    className?: string
    error: string | null | undefined
}

const Styles = styled.div`
    color: ${Colors.RED};
`

export const InputErrorLabel = ({ className, error }: Props) => {
    if (!error) {
        return null
    }
    return <Styles className={classNames(className)}>{error}</Styles>
}
