import classNames from 'classnames'

export const ButtonsLayout = ({
    className,
    children,
}: {
    className?: string
    children: React.ReactNode
}) => {
    return (
        <div className={classNames('flex mx-auto', className)}>{children}</div>
    )
}

export const ButtonWrapper = ({
    className,
    children,
}: {
    className?: string
    children: React.ReactNode
}) => {
    return (
        <div
            className={classNames(
                'w-16 px-2 flex flex-col items-center',
                className
            )}
        >
            {children}
        </div>
    )
}

export const ButtonLabel = ({
    className,
    children,
}: {
    className?: string
    children: React.ReactNode
}) => {
    return (
        <div className={classNames('text-gray-500 mt-1', className)}>
            {children}
        </div>
    )
}

export const TranscriptsListWrapper = ({
    children,
}: {
    children: React.ReactNode
}) => {
    return (
        <div className="absolute top-20 left-0 right-0 bottom-20 mb-3 sm:mb-10 mt-2 sm:mt-1 border-t border-b border-gray-200 z-10">
            {children}
        </div>
    )
}

export const NotepadWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="absolute top-20 left-0 right-0 bottom-20 mb-5 sm:mb-12 mt-2 sm:mt-1 px-3 py-3 sm:py-4 sm:px-10 border-t border-gray-200 z-10">
            {children}
        </div>
    )
}
