import { Cog6ToothIcon } from '@heroicons/react/24/solid'

import { OpenMicrophonesModal } from '../../../../../services/event-bus/events'
import { IconButton } from '../../../../UI/IconButton'
import { ButtonWrapper } from '../styles'

interface Props {
    className?: string
}

export const SettingsButton = ({ className }: Props) => {
    return (
        <ButtonWrapper className={className}>
            <IconButton
                iconNode={<Cog6ToothIcon className="h-5 w-5 text-gray-500" />}
                onClick={() => OpenMicrophonesModal.emit({})}
            />
        </ButtonWrapper>
    )
}
