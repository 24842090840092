import { useCallback, useReducer } from 'react'
import { toast } from 'react-toastify'

import { useHttp } from '../../../hooks/useHttp'
import {
    Field,
    FieldValue,
    initialTextField,
    TextField,
} from '../../../lib/field'
import { authService } from '../../../services/http/auth.service'
import { validationService } from '../../../services/validation.service'

class FormFields {
    email!: TextField
}
type FormFieldsKeys = keyof FormFields

const initialFormFields: FormFields = {
    email: initialTextField,
}

interface Action {
    fieldKey: FormFieldsKeys
    payload: Field
}

const reducer = (
    form: FormFields,
    { fieldKey, payload }: Action
): FormFields => {
    switch (fieldKey) {
        case 'email': {
            return {
                ...form,
                [fieldKey]: payload as TextField,
            }
        }

        default: {
            return form
        }
    }
}

interface FormHookState {
    form: FormFields
    isLoading: boolean
    updateField(fieldKey: FormFieldsKeys, fieldValue: FieldValue): void
    validateField(fieldKey: FormFieldsKeys): boolean
    forgotPassword(): void
}

export const useForm = (): FormHookState => {
    const { forgotPasswordReq, isLoading } = useHttpReq()
    const [form, dispatch] = useReducer(reducer, initialFormFields)

    const updateField = useCallback(
        (fieldKey: FormFieldsKeys, fieldValue: FieldValue) => {
            dispatch({
                fieldKey,
                payload: { value: fieldValue, error: null },
            })
        },
        []
    )

    const updateFieldError = useCallback(
        (fieldKey: FormFieldsKeys, errorMessage: string) => {
            dispatch({
                fieldKey,
                payload: {
                    value: form[fieldKey].value,
                    error: errorMessage,
                },
            })
        },
        [form]
    )

    const validateField = useCallback(
        (fieldKey: FormFieldsKeys): boolean => {
            const value = form[fieldKey].value

            switch (fieldKey) {
                case 'email': {
                    if (!value) {
                        updateFieldError(fieldKey, 'Required')
                        return false
                    } else if (!validationService.isEmail(value as string)) {
                        updateFieldError(
                            fieldKey,
                            'Must be a valid email address'
                        )
                        return false
                    }
                    return true
                }

                default: {
                    return false
                }
            }
        },
        [form, updateFieldError]
    )

    const validateForm = useCallback((): boolean => {
        const validations: boolean[] = []
        validations.push(validateField('email'))
        return !validations.some((i: boolean) => i === false)
    }, [validateField])

    const forgotPassword = useCallback(() => {
        if (!validateForm()) {
            return
        }

        forgotPasswordReq(form.email.value as string).catch((error: any) => {
            if (error.code === 'LimitExceededException') {
                updateFieldError(
                    'email',
                    'Too many request attempts. Please try again later'
                )
            } else {
                updateFieldError('email', 'An error has occured')
            }
        })
    }, [form, validateForm, forgotPasswordReq, updateFieldError])

    return {
        form,
        isLoading,
        updateField,
        validateField,
        forgotPassword,
    }
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const forgotPasswordReq = useCallback(
        async (email: string): Promise<void> =>
            sendRequest(authService.forgotPassword.bind({}, email)).then(() => {
                toast.success(
                    'Please check your email for password reset instructions',
                    {
                        position: 'bottom-center',
                    }
                )
            }),
        [sendRequest]
    )

    return {
        isLoading,
        forgotPasswordReq,
    }
}
