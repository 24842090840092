import DetectRTC from 'detectrtc'
import MicrophoneStream from 'microphone-stream'
import { toast } from 'react-toastify'
import { Subject } from 'rxjs'
import { WaveFile } from 'wavefile'

import { Button } from '../components/UI/Button'
import {
    OpenMicrophonesModal,
    UpdateMediaDeviceState,
    VolumeChanged,
} from '../services/event-bus/events'

const AUDIO_END_SIGNAL = 'ENDAUDIOSTREAM'

export const audio = (() => {
    let audioStream: MediaStream | null
    let microphoneStream: MicrophoneStream | null

    const getStream = async (deviceId?: string): Promise<MicrophoneStream> => {
        if (microphoneStream) {
            return microphoneStream
        }

        try {
            // Emit media device state
            UpdateMediaDeviceState.emit({ isAvailable: false })

            const audioStream = await micVad.getAudioStream(deviceId)
            microphoneStream = new MicrophoneStream()
            microphoneStream.setStream(audioStream)

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            microphoneStream.on('data', (chunk: any) => {
                // Emit volume change event
                const volume = calculateVolume(chunk)
                VolumeChanged.emit({ volume })
            })

            // Emit media device state
            UpdateMediaDeviceState.emit({ isAvailable: true })

            return microphoneStream
        } catch (error) {
            console.error(error)
            throw new Error('Unable to get microphone audio stream')
        }
    }

    const stopStream = () => {
        micVad.pause()

        audioStream?.getTracks().forEach((track) => track.stop())
        audioStream = null

        microphoneStream?.stop()
        microphoneStream = null

        if (timeoutRef) {
            clearTimeout(timeoutRef)
        }
    }

    const isMicrophoneAvailable = async (): Promise<boolean> => {
        return new Promise((resolve) => {
            DetectRTC.load(function () {
                resolve(DetectRTC.hasMicrophone)
            })
        })
    }

    return {
        getStream,
        stopStream,
        isMicrophoneAvailable,
    }
})()

let timeoutRef: any
export const micVad = (() => {
    let _micVad: any
    let audioStream: MediaStream | null = null
    const subject = new Subject<{ type: MicVADEventType }>()

    const getAudioStream = async (
        deviceId?: string,
        hideToastMessages?: boolean
    ): Promise<MediaStream> => {
        let hasAudioPickedUp = false

        const options: any = {
            onSpeechStart: () => {
                hasAudioPickedUp = true
                subject.next({ type: 'speech-started' })
                if (timeoutRef) {
                    clearTimeout(timeoutRef)
                }
            },
            onSpeechEnd: () => {
                subject.next({ type: 'speech-ended' })
            },
        }
        if (deviceId) {
            options.additionalAudioConstraints = {
                deviceId: {
                    exact: deviceId,
                },
            }
        }

        // Stop the previous audio stream
        if (audioStream) {
            audioStream.getTracks()?.forEach((track) => track?.stop())
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _micVad = await vad.MicVAD.new(options)
        _micVad.start()

        // If no audio is picked up after certain seconds, show a toast message to user
        if (timeoutRef) {
            clearTimeout(timeoutRef)
        }
        timeoutRef = setTimeout(() => {
            if (hasAudioPickedUp) {
                return
            }

            if (!hideToastMessages) {
                toast.error(
                    <div>
                        <div>
                            Your microphone is on, but we can&apos;t seem to
                            hear you. Want to take a quick look at your
                            settings?
                        </div>
                        <div className="flex justify-end">
                            <Button
                                className="mt-2"
                                intent="secondary"
                                label="Go to settings"
                                onClick={() => OpenMicrophonesModal.emit({})}
                            />
                        </div>
                    </div>,
                    {
                        closeOnClick: false,
                        autoClose: false,
                        closeButton: true,
                    }
                )
            }

            subject.next({ type: 'no-audio-picked-up' })
        }, 1000 * 10)

        audioStream = _micVad.stream as MediaStream
        return audioStream
    }

    const pause = () => {
        _micVad?.pause()
    }

    const getObservable = () => {
        return subject.asObservable()
    }

    return {
        getAudioStream,
        pause,
        getObservable,
    }
})()

export type MicVADEventType =
    | 'no-audio-picked-up'
    | 'speech-started'
    | 'speech-ended'

export const encodePCMChunk = (chunk: any): any => {
    const input = MicrophoneStream.toRaw(chunk)
    let offset = 0
    const buffer = new ArrayBuffer(input.length * 2)
    const view = new DataView(buffer)
    for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]))
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true)
    }
    return Buffer.from(buffer)
}

export const microphoneStreamToReadableStream = (
    microphoneStream: MicrophoneStream,
    callback: (chunk: Int16Array) => void
) => {
    return new ReadableStream({
        start(controller) {
            const audioChunks: any[] = []

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            microphoneStream.on('data', (chunk: any) => {
                try {
                    const pcmChunk = encodePCMChunk(chunk)
                    audioChunks.push(pcmChunk)
                    controller.enqueue(pcmChunk)
                    callback(pcmChunk)
                } catch (error) {
                    console.error(error)
                }
            })

            // Enqueue end signal when the microphone stream ends
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            microphoneStream.on('end', () => {
                try {
                    // Append the AUDIO_END_SIGNAL text string to the last chunk
                    const encoder = new TextEncoder()
                    const endAudioBuffer = encoder.encode(AUDIO_END_SIGNAL)

                    // Combine the last chunk and the ENDAUDIOSTREAM string
                    const lastChunk = audioChunks[audioChunks.length - 1]
                    const combinedChunk = new Uint8Array(
                        lastChunk.length + endAudioBuffer.length
                    )
                    combinedChunk.set(lastChunk, 0)
                    combinedChunk.set(endAudioBuffer, lastChunk.length)

                    // Enqueue the modified last chunk
                    controller.enqueue(combinedChunk)
                    callback(combinedChunk as any)
                } catch (error) {
                    console.error(error)
                }
            })
        },
    })
}

export const downloadAudioChunksAsFile = (
    filename: string,
    audioChunks: Int16Array[],
    sampleRate: number,
    onDownloaded?: () => void
) => {
    const wav = new WaveFile()
    wav.fromScratch(1, sampleRate, '16', concateInt16Arrays(audioChunks)) // 1 channel (mono), 48000 Hz (default), 16-bit
    const url = wav.toDataURI()

    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = `${filename}.wav`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    URL.revokeObjectURL(url)

    if (onDownloaded) {
        onDownloaded()
    }
}

function concateInt16Arrays(chunks: Int16Array[]) {
    let totalLength = 0
    chunks.forEach((chunk) => {
        totalLength += chunk.length
    })

    let offset = 0
    const array = new Int16Array(totalLength)
    chunks.forEach((chunk) => {
        array.set(chunk, offset)
        offset += chunk.length
    })

    return array
}

const calculateVolume = function (chunk: any): number {
    const input = MicrophoneStream.toRaw(chunk)
    let sum = 0

    for (let i = 0; i < input.length; i++) {
        sum += input[i] * input[i]
    }

    const rms = Math.sqrt(sum / input.length)
    const normalizedRms = Math.min(1, rms / 1)
    return Math.floor(normalizedRms * 100)
}
