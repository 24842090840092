import { useCallback } from 'react'
import styled from 'styled-components'

import { Button } from '../../../UI/Button'
import { PasswordField } from './PasswordField'
import { useForm } from './useForm'

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;
`

export const UpdatePasswordForm = () => {
    const formHookState = useForm()
    const { form, isLoading, updatePassword } = formHookState

    const isDisabled =
        !form.newPassword.value ||
        Boolean(form.newPassword.error) ||
        !form.confirmNewPassword.value ||
        Boolean(form.confirmNewPassword.error)

    const onSubmit = useCallback(() => {
        if (!isDisabled) {
            updatePassword()
        }
    }, [updatePassword, isDisabled])

    return (
        <Styles>
            <PasswordField
                className="mt-5"
                label="Current password"
                field="previousPassword"
                formHookState={formHookState}
            />
            <PasswordField
                className="mt-5"
                label="New password"
                field="newPassword"
                formHookState={formHookState}
                showPasswordStrength={true}
            />
            <PasswordField
                className="mt-5"
                label="Confirm new password"
                field="confirmNewPassword"
                formHookState={formHookState}
            />
            <Button
                className="mt-6 self-start"
                intent="text"
                label="Update"
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={isDisabled}
            />
        </Styles>
    )
}
