import { XCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { useHttp } from '../../../hooks/useHttp'
import { useRoute } from '../../../hooks/useRoute'
import { authService } from '../../../services/http/auth.service'
import { Button } from '../../UI/Button'
import { Loader } from '../../UI/Loader'
import { Card } from '../Card/Card'
import { LayoutStyles } from '../styles'

export const SignUpCompletion = () => {
    const { navSignIn } = useRoute()
    const { code } = useParams()
    const username = getQueryParameterValue('username')
    const email = useLocation().state?.email || getQueryParameterValue('email')
    const { isLoading, verifyEmailReq } = useHttpReq()
    const [messageNode, setMessageNode] = useState<ReactNode | null>(null)
    const hasVerifiedRef = useRef<boolean>(false)

    useEffect(() => {
        if (code || !email) {
            return
        }
        setMessageNode(
            <div>
                <p>
                    An email has been sent to your email address{' '}
                    <span className="text-gray-900">{email}</span>.
                </p>
                <p className="mt-3 mx-auto w-2/3">
                    Please click on the link to verify your email address.
                </p>
            </div>
        )
    }, [code, email])

    // Verify email on load
    useEffect(() => {
        if (hasVerifiedRef.current || !username || !code) {
            return
        }
        verifyEmailReq(username, code)
            .then(() => {
                setMessageNode(
                    <div>
                        Success! Your email address has been successfully
                        verified.
                    </div>
                )
            })
            .catch((error: any) => {
                setMessageNode(
                    <ErrorAlert
                        className="mt-5"
                        title="Unable to verify email address"
                        message={error.message}
                    />
                )
            })
        hasVerifiedRef.current = true
    }, [username, code, verifyEmailReq])

    return (
        <LayoutStyles className="text-center">
            <Card
                titleNode={
                    <>
                        <Illustration />
                        <div className="mt-5">Verify your email address</div>
                    </>
                }
                subTitleNode={
                    isLoading ? (
                        <Loader className="mx-auto my-10" />
                    ) : (
                        messageNode
                    )
                }
                bodyNode={
                    code && (
                        <Button
                            className="mx-auto"
                            isLoading={isLoading}
                            label="Go back to sign in"
                            onClick={() => navSignIn({ email })}
                            disabled={isLoading}
                        />
                    )
                }
            />
        </LayoutStyles>
    )
}

const IllustrationStyles = styled.img`
    width: auto;
    height: 200px;
    margin: auto;
`

const Illustration = () => {
    return (
        <IllustrationStyles src="/assets/images/illustration-email.svg"></IllustrationStyles>
    )
}

const ErrorAlert = ({
    className,
    title,
    message,
}: {
    className?: string
    title: string
    message: string
}) => {
    return (
        <div
            className={classNames(
                'rounded-md bg-red-50 p-4 text-left',
                className
            )}
        >
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red">{title}</h3>
                    <div className="mt-2 text-sm text-red">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                            <li>{message}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useHttpReq = () => {
    const { sendRequest, isLoading } = useHttp()

    const verifyEmailReq = useCallback(
        async (username: string, code: string): Promise<void> =>
            sendRequest(authService.signUpCompletion.bind({}, username, code)),
        [sendRequest]
    )

    return {
        isLoading,
        verifyEmailReq,
    }
}

const getQueryParameterValue = (name: string): string | null => {
    return new URLSearchParams(window.location.search).get(name)
}
