import { CheckIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useRoute } from '../../../hooks/useRoute'
import {
    AppPlanId,
    Plan,
    Price,
} from '../../../services/models/Subscription.model'
import { User } from '../../../services/models/User.model'
import { utilsService } from '../../../services/utils.service'
import { useAuthContext } from '../../Authentication/hooks/useAuthContext'
import { useBillingContext } from '../../Billing/hooks/useBillingContext'
import { Button } from '../../UI/Button'
import { Loader } from '../../UI/Loader'

interface Props {
    className?: string
}

const Styles = styled.div``

export const AllPlans = ({ className }: Props) => {
    const { navBilling, navBillingCheckout } = useRoute()
    const { user } = useAuthContext().user
    const { plans, isLoading: arePlansLoading } = useBillingContext().plans
    const {
        currentPlan,
        isLoading: isSubscriptionLoading,
        updateSubscription,
    } = useBillingContext().subscription

    const isEmrUser = useMemo(
        () => (user ? User.isEmrUser(user) : false),
        [user]
    )

    const isCurrentFreePlan = currentPlan ? Plan.isFreePlan(currentPlan) : false

    const onUpdate = useCallback(
        (plan: Plan, price: Price) => {
            if (!price) {
                return
            }

            if (isCurrentFreePlan) {
                navBillingCheckout(plan.metadata.app_plan_id)
                return
            }
            updateSubscription(price.id)
                .then(() => {
                    toast.success(
                        'You have successfully updated your subscription'
                    )
                    navBilling()
                })
                .catch((error) => {
                    console.error(error)
                    toast.error(
                        error?.message ||
                            'Unable to subscribe. Please try again later.'
                    )
                })
        },
        [isCurrentFreePlan, updateSubscription, navBilling, navBillingCheckout]
    )

    if (!plans?.length) {
        return null
    }

    if (arePlansLoading || isSubscriptionLoading) {
        return (
            <Styles className={classNames(className)}>
                <Loader />
            </Styles>
        )
    }

    return (
        <Styles className={classNames('flex flex-wrap', className)}>
            {plans.map((plan) => (
                <PlanItem
                    key={plan.id}
                    plan={plan}
                    isEmrUser={isEmrUser}
                    currentPlan={currentPlan}
                    onUpdate={onUpdate}
                />
            ))}
        </Styles>
    )
}

const PlanItem = ({
    className,
    plan,
    isEmrUser,
    currentPlan,
    onUpdate,
}: {
    className?: string
    plan: Plan
    isEmrUser: boolean
    currentPlan: Plan | undefined
    onUpdate(plan: Plan, price: Price): void
}) => {
    const isFreePlan = Plan.isFreePlan(plan)
    const isStandardPlan = Plan.isStandardPlan(plan)
    const isEnterprisePlan = Plan.isEnterprisePlan(plan)
    const isCurrent = currentPlan?.id === plan.id
    const isUpgrading = currentPlan && Plan.isUpgrading(currentPlan, plan)

    const price = Plan.getPrice(plan)
    const priceAmount = price ? utilsService.formatCurrency(price.amount) : 0
    const priceInterval = isFreePlan ? undefined : price?.interval

    let buttonLabel = ''
    if (isCurrent) {
        buttonLabel = 'Current plan'
    } else {
        buttonLabel = isUpgrading ? 'Upgrade' : 'Downgrade'
    }

    return (
        <div
            className={classNames(
                'w-full sm:w-1/2 p-2',
                {
                    'lg:w-1/2': isEmrUser,
                    'lg:w-1/4': !isEmrUser,
                },
                className
            )}
        >
            <div className="h-full px-4 py-4 rounded-md border">
                <div className="font-medium mb-3">{plan.name}</div>
                {isEnterprisePlan ? (
                    <div className="font-semibold text-2xl">Custom</div>
                ) : (
                    <div className="flex items-end">
                        <div className="font-semibold text-2xl">
                            {price?.amount ? priceAmount : '$0'}
                        </div>
                        {priceInterval && (
                            <div className="text-gray-500">
                                &nbsp;/{priceInterval}
                            </div>
                        )}
                    </div>
                )}
                <div className="text-gray-500 mt-3">{plan.description}</div>
                {!isEnterprisePlan ? (
                    <Button
                        className="w-full mt-5"
                        intent={
                            isStandardPlan && !isCurrent ? 'primary' : 'text'
                        }
                        label={buttonLabel}
                        onClick={() => price && onUpdate(plan, price)}
                        disabled={isCurrent}
                    />
                ) : (
                    <Button
                        className="w-full mt-5"
                        intent="text"
                        label="Contact us"
                        onClick={() =>
                            window.open('mailto:hello@fluent.health', '_blank')
                        }
                    />
                )}

                <div className="mt-5 mb-2 text-sm">
                    {featuresTitle[plan.metadata.app_plan_id]}
                </div>
                {plan.features.map((feature, index) => (
                    <div key={index} className="flex items-center text-sm">
                        <CheckIcon className="h-5 w-5 mr-2 text-primary" />
                        <div>{feature}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const featuresTitle: Record<AppPlanId, string> = {
    free: '',
    starter: 'Everything in Free, plus:',
    standard: 'Everything in Starter, plus:',
    enterprise: 'Everything in Standard, plus:',
    emr_integration: 'Everything in Free, plus:',
}
