import classNames from 'classnames'
import styled from 'styled-components'

import { useAppContext } from '../../../../hooks/useAppContext'
import { MicrophonesListBox } from '../../../UI/MicrophonesListBox'
import { TestMicrophoneButton } from '../../../UI/TestMicrophoneButton'

interface Props {
    className?: string
}

const Styles = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    max-width: 280px;

    ul {
        width: 280px;
    }
`

export const SelectMicrophone = ({ className }: Props) => {
    const { preferredMicrophoneDeviceId, setPreferredMicrophoneDeviceId } =
        useAppContext().appSettings

    return (
        <Styles className={classNames(className)}>
            <div className="block text-sm font-medium mb-1 leading-6 text-gray-900">
                Select microphone
            </div>

            <MicrophonesListBox
                deviceId={preferredMicrophoneDeviceId ?? undefined}
                onChange={setPreferredMicrophoneDeviceId}
            />
            <TestMicrophoneButton
                className="mt-2"
                key={preferredMicrophoneDeviceId}
                deviceId={preferredMicrophoneDeviceId ?? undefined}
            />
        </Styles>
    )
}
