import classNames from 'classnames'
import styled, { css } from 'styled-components'

interface Props {
    className?: string
    type?: Type
    height?: number
}

type Type =
    | 'logo-horizontal'
    | 'logo-horizontal-green'
    | 'logo-horizontal-nav'
    | 'logo'
    | 'logo-green'

const Styles = styled.img`
    width: auto;
    ${(props) => css`
        height: ${props.theme.height ? props.theme.height : 35}px;
    `}
`

export const Logo = ({ className, type, height }: Props) => {
    return (
        <Styles
            className={classNames(className)}
            src={`/assets/images/${type ?? 'logo-horizontal'}.svg`}
            alt="Logo"
            theme={{ height }}
        ></Styles>
    )
}
