import { AxiosResponse } from 'axios'

import { storage } from '../../lib/storage'
import { User } from '../models/User.model'
import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getUser = (): Promise<User | null> => {
    const url = `${baseUrl}/user`
    return new Promise((resolve) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                const user = response.data.user as User
                resolve(user)
            })
            .catch(() => resolve(null))
    })
}

const signOut = (): Promise<void> => {
    const url = `${baseUrl}/user/signout`
    return authAxios.get(url)
}

const updateEmail = (newEmail: string): Promise<void> => {
    const url = `${baseUrl}/user/update-email`
    const payload = {
        newEmail,
    }

    return new Promise((resolve, reject) => {
        authAxios
            .post(url, payload, {
                headers: {
                    'x-fl-username': storage.getUsername(),
                },
            })
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const verifyEmail = (code: string): Promise<void> => {
    const url = `${baseUrl}/user/verify-email`
    const payload = {
        code,
    }

    return new Promise((resolve, reject) => {
        authAxios
            .post(url, payload, {
                headers: { 'x-fl-username': storage.getUsername() },
            })
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const cancelUpdateEmail = (): Promise<void> => {
    const url = `${baseUrl}/user/cancel-update-email`

    return new Promise((resolve, reject) => {
        authAxios
            .post(
                url,
                {},
                { headers: { 'x-fl-username': storage.getUsername() } }
            )
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const createPassword = (
    userId: string,
    email: string,
    newPassword: string
): Promise<void> => {
    const url = `${baseUrl}/user/password`
    const payload = {
        userId,
        email,
        newPassword,
    }

    return new Promise((resolve, reject) => {
        authAxios
            .post(url, payload)
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

const updatePassword = (
    previousPassword: string,
    newPassword: string
): Promise<void> => {
    const url = `${baseUrl}/user/password`
    const payload = {
        previousPassword,
        newPassword,
    }

    return new Promise((resolve, reject) => {
        authAxios
            .put(url, payload, {
                headers: {
                    'x-fl-username': storage.getUsername(),
                },
            })
            .then(() => resolve())
            .catch((error) => reject(error.response.data))
    })
}

export const userService = {
    getUser,
    signOut,
    updateEmail,
    verifyEmail,
    cancelUpdateEmail,
    createPassword,
    updatePassword,
}
