import { Menu, Transition } from '@headlessui/react'
import {
    ArrowDownTrayIcon,
    ClipboardIcon,
    EllipsisHorizontalIcon,
    PrinterIcon,
    TrashIcon,
} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment } from 'react'

import { VisitStateType } from '../../services/models/Visit.model'
import { CopyNote } from '../UI/CopyNote'
import { DeleteVisit } from '../UI/DeleteVisit'
import { DownloadNote } from '../UI/DownloadNote'
import { Loader } from '../UI/Loader'

interface Props {
    className?: string
    visitId: string
    visitState: VisitStateType
}

export const VisitMenu = ({ className, visitId, visitState }: Props) => {
    return (
        <Menu as="div" className={classNames('relative ml-3', className)}>
            <div>
                <Menu.Button
                    className="relative flex rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    onClick={(event) => event.stopPropagation()}
                >
                    <span className="absolute -inset-1.5" />
                    <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {visitState === 'completed' && (
                        <>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <CopyNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <ClipboardIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>
                                                            Copy summary
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        ></CopyNote>
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <DownloadNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <PrinterIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>Print</span>
                                                    </div>
                                                )
                                            }
                                            print
                                        />
                                    </a>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block text-gray-700 cursor-pointer'
                                        )}
                                    >
                                        <DownloadNote
                                            className="px-4 py-2 w-full"
                                            visitId={visitId}
                                            childNode={(isLoading) =>
                                                isLoading ? (
                                                    <Loader />
                                                ) : (
                                                    <div className="flex items-center">
                                                        <ArrowDownTrayIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                        <span>
                                                            Export to PDF
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        />
                                    </a>
                                )}
                            </Menu.Item>
                        </>
                    )}
                    <Menu.Item>
                        {({ active }) => (
                            <a
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block text-gray-700 cursor-pointer'
                                )}
                            >
                                <DeleteVisit
                                    className="px-4 py-2 w-full"
                                    visitId={visitId}
                                    childNode={(isLoading) =>
                                        isLoading ? (
                                            <Loader />
                                        ) : (
                                            <div className="flex items-center">
                                                <TrashIcon className="h-5 w-5 text-gray-500 mr-2" />
                                                <span>Delete visit</span>
                                            </div>
                                        )
                                    }
                                />
                            </a>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
