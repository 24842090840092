import { DividerLine } from '../../../UI/DividerLine'
import { SectionWrapper } from '../../styles'
import { UpdateEmailForm } from './Form'

export const UpdateEmail = () => {
    return (
        <>
            <DividerLine className="my-8" />
            <SectionWrapper title="Update Email">
                <UpdateEmailForm />
            </SectionWrapper>
        </>
    )
}
