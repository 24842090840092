import { useEffect, useState } from 'react'

import { utilsService } from '../services/utils.service'

enum WIDTH {
    MOBILE = 640,
}

const checkViewPort = (width: number): boolean =>
    window.matchMedia(`only screen and (max-width: ${width}px)`).matches

export const useMediaQuery = () => {
    const [isMobile, setIsMoblie] = useState<boolean>(
        checkViewPort(WIDTH.MOBILE)
    )

    useEffect(() => {
        // Add debounce to reduce number of re-rendering
        window.addEventListener(
            'resize',
            utilsService.debounce(handleResize, 100)
        )

        function handleResize() {
            setIsMoblie(checkViewPort(WIDTH.MOBILE))
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return {
        isMobile,
    }
}
