import { XMarkIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { forwardRef, useCallback, useState } from 'react'
import styled from 'styled-components'

import { useHttp } from '../../../hooks/useHttp'
import { useSubmitOnEnter } from '../../../hooks/useSubmitOnEnter'
import { paymentsService } from '../../../services/http/payments.service'
import { PromotionCode } from '../../../services/models/Subscription.model'
import { Button } from '../../UI/Button'
import { Input } from '../../UI/Input'

interface Props {
    className?: string
    promotionCode?: PromotionCode | null
    onChange?(promotionCode: PromotionCode | null): void
}

const Styles = styled.div`
    input {
        padding: 12px;
    }
`

const PromotionCodeForm = forwardRef(
    ({ className, promotionCode, onChange }: Props, ref: any) => {
        const { code, error, isLoading, setCode, searchPromotionCode } =
            useForm()

        const onApply = useCallback(() => {
            searchPromotionCode()
                .then((_promotionCode) => {
                    if (onChange) {
                        onChange(_promotionCode)
                    }
                })
                .catch((error) => console.error(error))
        }, [searchPromotionCode, onChange])
        useSubmitOnEnter(onApply)

        return (
            <Styles ref={ref} className={classNames(className)}>
                <div className="font-medium">Promo Code</div>
                {promotionCode ? (
                    <div className="py-1 px-2 my-2 rounded-md bg-gray-200 inline-flex items-center">
                        <div>{promotionCode.code}</div>
                        <XMarkIcon
                            className="h-5 w-5 ml-2 cursor-pointer"
                            onClick={() => {
                                if (onChange) {
                                    onChange(null)
                                }
                                setCode('')
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <Input
                            className="flex-1"
                            name="promo-code"
                            placeholder="Enter code..."
                            initialValue={code}
                            onChange={setCode}
                            error={error}
                            rightNode={
                                <Button
                                    intent="text"
                                    label="Apply"
                                    isLoading={isLoading}
                                    disabled={!code}
                                    onClick={onApply}
                                />
                            }
                        />
                    </>
                )}
            </Styles>
        )
    }
)

PromotionCodeForm.displayName = 'PromotionCodeForm'
export { PromotionCodeForm }

const useForm = () => {
    const [code, setCode] = useState<string>('')
    const [error, setError] = useState<string>('')
    const { isLoading, sendRequest } = useHttp()

    const handleSetCode = useCallback((value: string) => {
        setCode(value)
        setError('')
    }, [])

    const searchPromotionCode =
        useCallback(async (): Promise<PromotionCode | null> => {
            return sendRequest(
                paymentsService.searchPromotionCode.bind({}, code)
            )
                .then((_promotionCode) => {
                    setError(_promotionCode ? '' : 'Invalid code')
                    return _promotionCode
                })
                .catch((error) => {
                    setError(error.message || 'Invalid code')
                    return null
                })
        }, [code, sendRequest])

    return {
        code,
        error,
        isLoading,
        setCode: handleSetCode,
        searchPromotionCode,
    }
}
