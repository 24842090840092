import { Disclosure } from '@headlessui/react'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { useRoute } from '../../../hooks/useRoute'
import { Logo } from '../Logo'
import { AvatarMenu } from './AvatarMenu'

const Styles = styled.div``

export const AppBar = () => {
    const { isMobile } = useMediaQuery()

    return (
        <Disclosure as="nav" className="bg-nav">
            {() => (
                <Styles className="pr-2 pl-4 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">
                        <LogoWrapper>
                            <Logo
                                height={isMobile ? 32 : 22}
                                type={
                                    isMobile
                                        ? 'logo-green'
                                        : 'logo-horizontal-green'
                                }
                            />
                        </LogoWrapper>
                        <AvatarMenuWrapper>
                            <AvatarMenu />
                        </AvatarMenuWrapper>
                    </div>
                </Styles>
            )}
        </Disclosure>
    )
}

const LogoWrapper = ({ children }: { children: ReactNode }) => {
    const { navMain } = useRoute()

    return (
        <div className="flex flex-1">
            <div
                className="flex flex-shrink-0 items-center cursor-pointer"
                onClick={navMain}
            >
                {children}
            </div>
        </div>
    )
}

const AvatarMenuWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {children}
        </div>
    )
}
