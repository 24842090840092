enum KEY {
    EMR_TOKEN = 'fl_emr_token',
    ACCESS_TOKEN = 'fl_token',
    REFRESH_TOKEN = 'fl_refresh',
    USERNAME = 'fl_username',
    PLAN = 'fl_plan',
    MICROPHONE_DEVICE_ID = 'fl_mic_device_id',
}

type Session = {
    accessToken: string
    refreshToken: string
    username: string
}

export const storage = (() => {
    const storeAccessToken = (session: Session) => {
        localStorage.setItem(KEY.ACCESS_TOKEN, session.accessToken)
        localStorage.setItem(KEY.REFRESH_TOKEN, session.refreshToken)
        localStorage.setItem(KEY.USERNAME, session.username)
    }

    const storeEmrToken = (token: string) => {
        localStorage.setItem(KEY.EMR_TOKEN, token)
    }

    const storePlan = (plan: string) => {
        localStorage.setItem(KEY.PLAN, plan)
    }

    const storeMicrophoneDeviceId = (deviceId: string) => {
        localStorage.setItem(KEY.MICROPHONE_DEVICE_ID, deviceId)
    }

    const getAccessToken = (): string | null =>
        localStorage.getItem(KEY.ACCESS_TOKEN)

    const getEmrToken = (): string | null => localStorage.getItem(KEY.EMR_TOKEN)

    const getRefreshToken = (): string | null =>
        localStorage.getItem(KEY.REFRESH_TOKEN)

    const getUsername = (): string | null => localStorage.getItem(KEY.USERNAME)

    const getPlan = (): string | null => localStorage.getItem(KEY.PLAN)

    const getMicrophoneDeviceId = (): string | null =>
        localStorage.getItem(KEY.MICROPHONE_DEVICE_ID)

    const clearAccessToken = () => {
        localStorage.removeItem(KEY.ACCESS_TOKEN)
        localStorage.removeItem(KEY.REFRESH_TOKEN)
        localStorage.removeItem(KEY.USERNAME)
    }

    const clearEmrToken = () => localStorage.removeItem(KEY.EMR_TOKEN)

    const clearPlan = () => localStorage.removeItem(KEY.PLAN)

    const clearMicrophoneDeviceId = () =>
        localStorage.removeItem(KEY.MICROPHONE_DEVICE_ID)

    return {
        storeAccessToken,
        storeEmrToken,
        storePlan,
        storeMicrophoneDeviceId,
        getAccessToken,
        getRefreshToken,
        getUsername,
        getPlan,
        getMicrophoneDeviceId,
        getEmrToken,
        clearAccessToken,
        clearEmrToken,
        clearPlan,
        clearMicrophoneDeviceId,
    }
})()
