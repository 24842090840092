import { Subject } from 'rxjs'

const subject = new Subject<number>()

export const timer = (() => {
    let seconds: number = 0
    let interval: any

    const tick = (initialSeconds: number) => {
        seconds = initialSeconds

        interval = setInterval(() => {
            seconds++
            subject.next(seconds)
        }, 1000)
    }

    const pause = (initialDuration: number) => {
        seconds = initialDuration
        clearInterval(interval)
    }

    const reset = () => {
        seconds = 0
        clearInterval(interval)
    }

    const getSeconds = () => seconds

    const getObservable = () => subject.asObservable()

    return {
        tick,
        pause,
        reset,
        getSeconds,
        getObservable,
    }
})()
