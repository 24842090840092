import { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import { eventBus } from '../../../../../services/event-bus/eventBus.service'
import { VolumeChanged } from '../../../../../services/event-bus/events'
import { useLocalContext } from '../hooks/useLocalContext' // Adjust the import path as necessary

// Create a keyframe animation that moves the circle in a circular path
const circularMotion = keyframes`
  0% {
    transform: rotate(0deg) translateX(44px);
  }
  100% {
    transform: rotate(360deg) translateX(44px);
  }
`
interface CircleProps {
    color: string
    $delay: string
    $paused: string
    $volume: number
}

const Circle = styled.div<CircleProps>`
    position: absolute;
    border-radius: 50%;
    filter: blur(34px);
    transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease,
        background-color 0.5s ease;
    transform-origin: center;
    animation: ${circularMotion} 10s linear infinite;
    background-color: ${(props) =>
        props.$paused === 'true' ? '#E4E4E4' : props.color};
    width: ${(props) =>
        props.$paused === 'true' ? '190px' : `${props.$volume}px`};
    height: ${(props) =>
        props.$paused === 'true' ? '190px' : `${props.$volume}px`};
`

const BlurredCircle = styled.div`
    background-color: white;
    filter: blur(14px);
    position: absolute;
    z-index: 1;
    width: 244px;
    height: 244px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface VolumeIndicatorStyledProps {
    $isPaused: boolean
    $volume: number
}

const VolumeIndicatorStyled = styled.div<VolumeIndicatorStyledProps>`
    position: absolute;
    z-index: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const animationDuration = ['7s', '5s', '3s']

export const VolumeIndicator = () => {
    const { isPaused } = useLocalContext().recorder
    const [volume, setVolume] = useState(0)

    useEffect(() => {
        const subscription = eventBus.getObservable().subscribe((event) => {
            if (event.action === VolumeChanged.action) {
                setVolume(event.payload.volume)
            }
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <VolumeIndicatorStyled $isPaused={isPaused} $volume={volume}>
            <BlurredCircle />
            <Circle
                color={'#0469FF'}
                style={{ animationDuration: animationDuration[0] }}
                $delay={'0s'}
                $paused={isPaused.toString()}
                $volume={calculateSize(volume)}
            />
            <Circle
                color={'#00FFF0'}
                style={{ animationDuration: animationDuration[1] }}
                $delay={'-3.333s'}
                $paused={isPaused.toString()}
                $volume={calculateSize(volume)}
            />
            <Circle
                color={'#14C7FF'}
                style={{ animationDuration: animationDuration[2] }}
                $delay={'-6.666s'}
                $paused={isPaused.toString()}
                $volume={calculateSize(volume)}
            />
        </VolumeIndicatorStyled>
    )
}

// Scale volume range (0 to 1) to size range (210px to 340px)
const calculateSize = (volume: number) => {
    return Math.min(340, Math.max(210, 200 + volume * 300))
}
