import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { ReactNode, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import useVisitNotesContext from '../../hooks/useVisitNotesContext'
import { OpenConfirmationModal } from '../../services/event-bus/events'

interface Props {
    className?: string
    visitNoteId: string
    childNode(isLoading: boolean): ReactNode
}

const Styles = styled.div`
    display: inline-block;
`

export const DeleteNote = ({ className, visitNoteId, childNode }: Props) => {
    const { deleteVisitNote } = useVisitNotesContext().visitNotes
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onClick = useCallback(
        (event: any) => {
            event.stopPropagation()

            OpenConfirmationModal.emit({
                titleNode: 'Deleting note',
                iconNode: (
                    <ExclamationTriangleIcon className="h-10 w-10 text-red mr-4" />
                ),
                bodyNode: 'Are you sure you want to delete this note?',
                confirmLabel: 'Permanently delete',
                cancelIntent: 'danger',
                onConfirm: () => {
                    setIsLoading(true)
                    deleteVisitNote(visitNoteId)
                        .then(() => {
                            toast.success('Note deleted')
                        })
                        .catch((error) => console.error(error))
                        .finally(() => setIsLoading(false))
                },
            })
        },
        [visitNoteId, deleteVisitNote]
    )

    return (
        <Styles className={classNames(className)} onClick={onClick}>
            {childNode(isLoading)}
        </Styles>
    )
}
