import { ReactNode } from 'react'

import { Logo } from '../../UI/Logo'

export const TitleWithLogo = ({ titleNode }: { titleNode: ReactNode }) => {
    return (
        <>
            <Logo type="logo-horizontal-nav" className="mb-8" height={20} />
            {titleNode}
        </>
    )
}
