import { AxiosResponse } from 'axios'

import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getCannyIoSsoToken = (): Promise<string | null> => {
    const url = `${baseUrl}/services/cannyio`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                resolve(response.data.ssoToken || null)
            })
            .catch((error) => reject(error))
    })
}

const isWhisperOverflowed = async (): Promise<boolean> => {
    const url = `${baseUrl}/services/whisper/overflow`
    return new Promise((resolve) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) =>
                resolve(response.data.isOverflowed ?? false)
            )
            .catch(() => resolve(false))
    })
}

export const servicesService = {
    getCannyIoSsoToken,
    isWhisperOverflowed,
}
