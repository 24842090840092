import { useCallback, useEffect, useReducer, useRef } from 'react'

import { templatesService } from '../services/http/templates.service'
import { Template } from '../services/models/Template.model'
import { useHttp } from './useHttp'

export interface TemplatesHookState {
    templates: Template[]
    areTemplatesLoading: boolean
    areTemplatesLoaded: boolean
    getTemplate(id: string): Template | undefined
}

enum ACTION {
    ADD_ALL,
}

interface Action {
    type: ACTION
    payload: string | Template | Template[]
}

interface State {
    templates: Template[]
}

const initialState: State = {
    templates: [],
}

const reducer = (state: State, { type, payload }: Action): State => {
    switch (type) {
        case ACTION.ADD_ALL: {
            return { ...state, templates: payload as Template[] }
        }
        default:
            return state
    }
}

export const useTemplates = (): TemplatesHookState => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { templates } = state
    const { sendRequest, isLoading, hasLoaded } = useHttp()
    const hasFetchedRef = useRef<boolean>(false)

    const getTemplatesReq = useCallback(async (): Promise<Template[]> => {
        return sendRequest(templatesService.getTemplates.bind({}))
    }, [sendRequest])

    const getTemplates = useCallback(async () => {
        try {
            let templates = await getTemplatesReq()
            templates = templates.sort((a, b) => a.sortOrder - b.sortOrder)

            dispatch({ type: ACTION.ADD_ALL, payload: templates })
        } catch (error) {
            console.error('Error fetching templates')
        }
    }, [getTemplatesReq])

    const getTemplate = useCallback(
        (id: string) => templates.find((t) => t._id === id),
        [templates]
    )

    useEffect(() => {
        if (hasFetchedRef.current) {
            return
        }
        getTemplates()
            .then(() => {
                hasFetchedRef.current = true
            })
            .catch((error) => console.error(error))
    }, [getTemplates])

    return {
        templates,
        areTemplatesLoading: isLoading,
        areTemplatesLoaded: hasLoaded,
        getTemplate,
    }
}
